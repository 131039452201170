class WebSocketClient {
  static instance = null;

  evt_callbacks = {
    onConnect: [],
    onDisconnect: [],
  };

  msg_callbacks = {
    Created: [],
    user: [],
    Assigned: [],
    Resolved: [],
  };

  static getInstance() {
    /* Makes sure there is only one instance of this client */
    if (!WebSocketClient.instance)
      WebSocketClient.instance = new WebSocketClient();
    return WebSocketClient.instance;
  }

  constructor() {
    this.socket_url = this.getSocketUrl();
    this.socketRef = null;
  }

  print(msg) {
    console.log(`[WebSocketClient] ${msg}`);
  }

  isConnected() {
    if (!this.socketRef || this.socketRef.readyState !== WebSocket.OPEN) return false;
    return true;
  }

  getSocketUrl() {
    return "wss://" + window.location.host + "/api/socket/dashboard/";
  }

  connect = () => {
    if (this.socketRef && [WebSocket.OPEN, WebSocket.CONNECTING].includes(
      this.socketRef.readyState)
      )
    {
      /* Socket is still open, no need to reconnect */
      return;
    }
    this.socketRef = new WebSocket(this.socket_url);
    this.socketRef.onopen = () => {
      this.print("Socket opened");
      this.onWebSocketConnect();
    };

    this.socketRef.onmessage = (e) => {
      this.onWebSocketMessage(e.data);
    };

    this.socketRef.onerror = (e) => {
      this.print(JSON.stringify(e));
    };

    this.socketRef.onclose = () => {
      this.print("Socket closed, reopening");
      this.onWebSocketDisconnect();
      this.connect();
    };
  };

  disconnect = () => {
    if (this.socketRef) {
      this.socketRef.close();
    }
  };

  registerEventCallbacks(callbacks) {
    /* Can be used to register event callbacks from components */
    Object.keys(this.evt_callbacks).forEach((evt_type) => {
      this.evt_callbacks[evt_type].push(callbacks[evt_type]);
    });
  }

  deregisterEventCallbacks(register_id) {
    /* Allows to remove registered event callbacks */
    Object.keys(this.evt_callbacks).forEach((evt_type) => {
      this.evt_callbacks[evt_type] = this.evt_callbacks[evt_type].filter(
        (callback) => callback.id !== register_id
      );
    });
  }

  registerMessageCallbacks(callbacks) {
    /* Can be used to register message callbacks from components */
    Object.keys(this.msg_callbacks).forEach((msg_type) => {
      callbacks[msg_type] &&
        this.msg_callbacks[msg_type].push(callbacks[msg_type]);
    });
  }

  deregisterMessageCallbacks(register_id) {
    /* Allows to remove registered message callbacks */
    Object.keys(this.msg_callbacks).forEach((msg_type) => {
      this.msg_callbacks[msg_type] = this.msg_callbacks[msg_type].filter(
        (callback) => callback.id !== register_id
      );
    });
  }

  sendMessage(data) {
    /* Send a message to the remote server */
    try {
      this.socketRef.send(data);
    } catch (e) {
      this.print(e.message);
    }
  }

  onWebSocketConnect() {
    /* Calls any registered onConnect callbacks */
    if (this.evt_callbacks["onConnect"].length === 0) return;
    this.evt_callbacks["onConnect"].forEach((registeredCallback) =>
      registeredCallback.callback()
    );
  }

  onWebSocketDisconnect() {
    /* Calls any registered onDisconnect callbacks */
    if (this.evt_callbacks["onDisconnect"].length === 0) return;
    this.evt_callbacks["onDisconnect"].forEach((registeredCallback) =>
      registeredCallback.callback()
    );
  }

  onWebSocketMessage(data) {
    /* Calls the appropriate callback for each event_type */
    console.log(`onWebSocketMessage => ${data}`);
    const parsedData = JSON.parse(data);
    const { event_type } = parsedData;

    if (Object.keys(this.msg_callbacks).includes(event_type)) {
      const registeredCallbacks = this.msg_callbacks[event_type];
      if (registeredCallbacks.length === 0) return;
      registeredCallbacks.forEach((registeredCallback) =>
        registeredCallback.callback(parsedData)
      );
    }
  }

  waitForSocketConnection = (callback) => {
    const socket = this.socketRef;
    const recursion = this.waitForSocketConnection;
    setTimeout(() => {
      if (socket.readyState === 1) {
        this.print("Connection established");
        if (callback != null) callback();
        return;
      } else {
        this.print("Waiting for connection...");
        setTimeout(() => recursion(callback), 500);
      }
    }, 1);
  };

  state = () => this.socketRef.readyState;
}

export default WebSocketClient.getInstance();
