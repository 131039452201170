import React, { useState, useContext, useEffect } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Modal } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function DeleteAlertRuleModal({
  openDeleteAlertRuleModal,
  setOpenDeleteAlertRuleModal,
  selectedAlertRule,
  handleDeleteAlertRule,
  loading,
}) {
  const { t } = useTranslation();
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={openDeleteAlertRuleModal}
        onClose={() => setOpenDeleteAlertRuleModal(false)}
      >
        <Box sx={style}>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {t("manageAlerts.alertRules.delete.warningText")}
          </Typography>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            ID: {selectedAlertRule.id} - {selectedAlertRule.stream}
          </Typography>
          {loading ? (
            <CircularProgress color="error" />
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteAlertRule()}
            >
              {t("manageAlerts.alertRules.delete.deleteAlertRule")}
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default function ManageAlertRuleDrawer({
  openAlertRuleDrawer,
  setOpenAlertRuleDrawer,
  selectedAlertRule,
  setSelectedAlertRule,
  setAlertRules,
  handleNewAlert,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  let context = useContext(ServiceContext);

  let [expanded, setExpanded] = useState("settings");
  let [alertRuleFormChanged, setAlertRuleFormChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  let [openDeleteAlertRuleModal, setOpenDeleteAlertRuleModal] = useState(false);

  // form selects for Alert Rule form
  const [selectedAlertRuleDevice, setSelectedAlertRuleDevice] = useState(
    selectedAlertRule.device
  );
  const [selectedAlertRulePriority, setSelectedAlertRulePriority] = useState(
    selectedAlertRule.priority
  );
  const [selectedAlertRuleEventTypes, setSelectedAlertRuleEventTypes] =
    useState(selectedAlertRule.event_types);
  const [selectedAlertRuleIsActive, setSelectedAlertRuleIsActive] = useState(
    selectedAlertRule.is_active
  );

  useEffect(() => {
    // reset form selects when new AlertRule is selected
    if (selectedAlertRule) {
      setSelectedAlertRuleDevice(selectedAlertRule.device);
      setSelectedAlertRuleEventTypes(selectedAlertRule.event_types);
      setSelectedAlertRulePriority(selectedAlertRule.priority);
      setSelectedAlertRuleIsActive(selectedAlertRule.is_active);
      setAlertRuleFormChanged(false);
    }
  }, [selectedAlertRule]);

  // form errors for Alert Rule form
  let [alertRuleDeviceError, setAlertRuleDeviceError] = useState(false);
  let [alertRuleEventTypesError, setAlertRuleEventTypesError] = useState(false);
  let [alertRulePriorityError, setAlertRulePriorityError] = useState(false);
  let [alertRuleIsActiveError, setAlertRuleIsActiveError] = useState(false);

  const resetErrors = () => {
    setAlertRuleDeviceError("");
    setAlertRuleEventTypesError("");
    setAlertRulePriorityError("");
    setAlertRuleIsActiveError("");
  };
  useEffect(() => {
    // set Alert Rule Form Changed if any form element has been updated by the user
    if (
      selectedAlertRuleDevice !== selectedAlertRule.device ||
      selectedAlertRuleEventTypes !== selectedAlertRule.event_types ||
      selectedAlertRulePriority !== selectedAlertRule.priority ||
      selectedAlertRuleIsActive !== selectedAlertRule.is_active
    ) {
      setAlertRuleFormChanged(true);
      resetErrors();
    } else setAlertRuleFormChanged(false);
  }, [
    selectedAlertRuleDevice,
    selectedAlertRuleEventTypes,
    selectedAlertRulePriority,
    selectedAlertRuleIsActive,
    selectedAlertRule,
  ]);

  // form choices for Alert Rule form
  let [priorityChoices, setPriorityChoices] = useState(false);
  let [fetchedPriorityChoices, setFetchedPriorityChoices] = useState(false);
  let [eventTypeChoices, setEventTypeChoices] = useState(false);
  let [fetchedEventTypeChoices, setFetchedEventTypeChoices] = useState(false);
  let [devicesList, setDevicesList] = useState(false);

  useEffect(() => {
    if (!fetchedPriorityChoices && !priorityChoices) {
      context.alertService.retrievePriorityChoices().then((response) => {
        setPriorityChoices(response.data);
        setFetchedPriorityChoices(true);
      });
    }
  }, [fetchedPriorityChoices, priorityChoices, context.alertService]);

  useEffect(() => {
    if (!fetchedEventTypeChoices && !eventTypeChoices) {
      context.alertService.retrieveEventTypeChoices().then((response) => {
        setEventTypeChoices(response.data);
        setFetchedEventTypeChoices(true);
      });
    }
  }, [fetchedEventTypeChoices, eventTypeChoices, context.alertService]);

  useEffect(() => {
    if (selectedAlertRule) {
      let devices = [];
      context.deviceService
        .retrieveOrganizationDevices()
        .then((response) => {
          response.forEach((device) => {
            devices.push(
              <MenuItem key={device.id} value={device.id}>
                {`${device.device_type_name} - ${device.identifier}`}
              </MenuItem>
            );
          });
          setDevicesList(devices);
        })
        .catch((err) => console.log(JSON.stringify(err)));
    }
  }, [selectedAlertRule, context.deviceService]);

  // handlers
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleUpdateAlertRule = (id) => {
    resetErrors();
    setLoading(true);
    let data = {
      device: selectedAlertRuleDevice,
      organization: context.organizationService.selectedOrg,
      event_types:
        selectedAlertRuleEventTypes && selectedAlertRuleEventTypes.length
          ? selectedAlertRuleEventTypes
          : null,
      priority: selectedAlertRulePriority,
      is_active: selectedAlertRuleIsActive,
    };
    context.alertService
      .updateAlertRule(id, data)
      .then((updateResponse) => {
        setTimeout(() => {
          context.alertService
            .retrieveOrganizationAlertRules()
            .then((retrieveResponse) => {
              setAlertRules(retrieveResponse.data);
              setSelectedAlertRule(updateResponse);
            });
          handleNewAlert(`Updated Alert Rule: ${updateResponse.id}`, "success");
          setAlertRuleFormChanged(false);
          setLoading(false);
        }, 200);
      })
      .catch((err) => {
        if (err.device) setAlertRuleDeviceError(err.device[0]);
        if (err.event_types) setAlertRuleEventTypesError(err.event_types[0]);
        if (err.priority) setAlertRulePriorityError(err.priority[0]);
        if (err.is_active) setAlertRuleIsActiveError(err.is_active[0]);
        setLoading(false);
        console.log(err);
        handleNewAlert(
          `Failed to add a new alert rule: ${
            err.detail ? err.detail : err.toString()
          }`,
          "error"
        );
      });
  };
  const handleDeleteAlertRule = () => {
    setLoading(true);
    context.alertService
      .deleteAlertRule(selectedAlertRule.id)
      .then(() => {
        context.alertService
          .retrieveOrganizationAlertRules()
          .then((retrieveResponse) => {
            setAlertRules(retrieveResponse.data);
          });
        handleNewAlert(
          `Successfully deleted alertRule "${selectedAlertRule.id}"`,
          "success"
        );
        setLoading(false);
        setOpenDeleteAlertRuleModal(false);
        setOpenAlertRuleDrawer(false);
      })
      .catch((err) => {
        handleNewAlert(`Error: ${err}`, "error");
        setLoading(false);
        setOpenDeleteAlertRuleModal(false);
        setOpenAlertRuleDrawer(false);
      });
  };

  const renderPriorityChoices = () => {
    let arr = [];
    if (priorityChoices && priorityChoices.length)
      priorityChoices.forEach((choice) => {
        arr.push(
          <MenuItem key={choice[0]} value={choice[0]}>
            {choice[1]}
          </MenuItem>
        );
      });
    return arr;
  };

  const renderEventTypeChoices = () => {
    let arr = [];
    if (eventTypeChoices && eventTypeChoices.length)
      eventTypeChoices.forEach((choice) => {
        arr.push(
          <MenuItem key={choice} value={choice + ""}>
            {choice}
          </MenuItem>
        );
      });
    return arr;
  };

  const renderUpdateSettingsButton = () => {
    if (alertRuleFormChanged) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              color="warning"
              variant="contained"
              onClick={() => handleUpdateAlertRule(selectedAlertRule.id)}
            >
              {t("manageAlerts.alertRules.detail.updateSettings")}
            </Button>
          )}
        </Box>
      );
    } else return <></>;
  };

  const renderDrawer = () => (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openAlertRuleDrawer}
      onClose={() => setOpenAlertRuleDrawer(false)}
      transitionDuration={300}
      sx={{
        width: openAlertRuleDrawer ? "500px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: openAlertRuleDrawer ? "500px" : 0,
          px: 0,
          py: 1,
          maxWidth: "90vw",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        ></Box>
        <Box
          sx={{
            "& .MuiPaper-root": {
              py: 0,
            },
            "& .Mui-expanded": {
              my: 0,
            },
            "& .MuiAccordionDetails-root": {
              py: 0,
            },
            "& .MuiListItem-root .highlight": {
              color: "white",
            },
            " & .MuiAccordionSummary-root .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiListItemText-root": {
              minWidth: "30%",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "rgb(76, 76, 76) !important",
              color: theme.palette.primary.main,
            },
            "& .MuiListItem-root:hover .highlight": {
              color: theme.palette.primary.main,
            },
            "& .MuiInputLabel-formControl": { color: "white" },
            "& .MuiInput-input": {
              backgroundColor: "transparent",
              color: "white",
              textAlign: "end",
              px: 0,
            },
            "& .MuiSelect-select": { px: 0, pr: "20px !important" },
            "& .MuiFormControl-root": { m: 0 },
            "& .MuiAutocomplete-root": { width: "calc(100% - 20px)" },
            "& .MuiFormHelperText-root": { color: "red" },
          }}
        >
          <Typography variant="h5" m={3} textAlign="center">
            Alert Rule ID {selectedAlertRule.id}
          </Typography>

          <Accordion
            expanded={expanded === "settings"}
            onChange={handleAccordionChange("settings")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ py: 0, my: 0 }}
            >
              <Typography variant="h6">
                {t("manageAlerts.alertRules.detail.settings")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("manageAlerts.alertRules.detail.device")}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <Select
                      required
                      id="form-input-device"
                      value={
                        selectedAlertRuleDevice && devicesList
                          ? selectedAlertRuleDevice
                          : ""
                      }
                      onChange={(event) =>
                        setSelectedAlertRuleDevice(event.target.value)
                      }
                      IconComponent={() => (
                        <EditIcon
                          sx={{ width: 20, height: 20, mx: 2 }}
                          className="highlight"
                        />
                      )}
                    >
                      {devicesList}
                    </Select>
                    <FormHelperText>
                      {alertRuleDeviceError ? alertRuleDeviceError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageAlerts.alertRules.detail.priority")}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <Select
                      required
                      id="form-input-priority"
                      value={
                        selectedAlertRulePriority && priorityChoices
                          ? selectedAlertRulePriority + ""
                          : ""
                      }
                      onChange={(event) => {
                        setSelectedAlertRulePriority(event.target.value + "");
                      }}
                      IconComponent={() => (
                        <EditIcon
                          sx={{ width: 20, height: 20, mx: 2 }}
                          className="highlight"
                        />
                      )}
                    >
                      {renderPriorityChoices()}
                    </Select>
                    <FormHelperText>
                      {alertRulePriorityError ? alertRulePriorityError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageAlerts.alertRules.detail.eventTypes")}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <Select
                      multiple
                      id="form-input-eventTypes"
                      value={
                        selectedAlertRuleEventTypes
                          ? selectedAlertRuleEventTypes
                          : []
                      }
                      onChange={(event) => {
                        setSelectedAlertRuleEventTypes(event.target.value);
                      }}
                      IconComponent={() => (
                        <EditIcon
                          sx={{ width: 20, height: 20, mx: 2 }}
                          className="highlight"
                        />
                      )}
                    >
                      {renderEventTypeChoices()}
                    </Select>
                    <FormHelperText>
                      {alertRuleEventTypesError
                        ? alertRuleEventTypesError
                        : false}
                    </FormHelperText>
                    <FormHelperText
                      sx={{
                        color: "white !important",
                        textAlign: "end",
                        pr: "40px",
                      }}
                    ></FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageAlerts.alertRules.detail.isActive")}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <Select
                      required
                      id="form-input-is_active"
                      defaultValue={false}
                      value={
                        selectedAlertRuleIsActive
                          ? selectedAlertRuleIsActive
                          : false
                      }
                      onChange={(event) => {
                        setSelectedAlertRuleIsActive(event.target.value);
                      }}
                      IconComponent={() => (
                        <EditIcon
                          sx={{ width: 20, height: 20, mx: 2 }}
                          className="highlight"
                        />
                      )}
                    >
                      <MenuItem
                        key={1}
                        value={true}
                        sx={{ justifyContent: "flex-end" }}
                      >
                        <CheckCircleOutlineIcon color="primary" />
                      </MenuItem>
                      <MenuItem
                        key={0}
                        value={false}
                        sx={{ justifyContent: "flex-end" }}
                      >
                        <CancelIcon color="error" />
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {alertRuleIsActiveError ? alertRuleIsActiveError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                {renderUpdateSettingsButton()}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Button
          variation="outlined"
          color="error"
          onClick={() => setOpenDeleteAlertRuleModal(true)}
          sx={{ my: 1 }}
        >
          {t("manageAlerts.alertRules.detail.deleteAlertRule")}
        </Button>
      </Box>
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: openAlertRuleDrawer ? "-20px" : "",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          openAlertRuleDrawer
            ? setOpenAlertRuleDrawer(false)
            : setOpenAlertRuleDrawer(true);
        }}
      >
        {openAlertRuleDrawer ? (
          <Tooltip title="Hide Alert Rule Detail">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : null}
      </Button>
      <DeleteAlertRuleModal
        openDeleteAlertRuleModal={openDeleteAlertRuleModal}
        setOpenDeleteAlertRuleModal={setOpenDeleteAlertRuleModal}
        selectedAlertRule={selectedAlertRule}
        handleDeleteAlertRule={handleDeleteAlertRule}
        loading={loading}
      />
    </Drawer>
  );
  return selectedAlertRule ? renderDrawer() : null;
}
