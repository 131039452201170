import React, { useContext, useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { calcScrollbarWidth } from "../../util/util";
import "./popup.css";
import { ServiceContext } from "../../context/ServiceContext";
import LocationDetailAlertsDataGrid from "../data_grids/LocationDetailAlertsDataGrid";

const calcPrios = (alerts) => {
  let none, low, medium, high;
  none = low = medium = high = 0;
  alerts.forEach((alert) => {
    switch (alert.priority) {
      case 0:
        none++;
        break;
      case 1:
        low++;
        break;
      case 2:
        medium++;
        break;
      case 3:
        high++;
        break;
      default:
        break;
    }
  });
  return [none, low, medium, high];
};

function LocationDetails({ locationId }) {
  const { alertService, locationService } = useContext(ServiceContext);
  const scrollList = useRef(null);
  const legendBar = useRef(null);
  const windowRef = useRef(null);
  const [alerts, setAlerts] = useState([]);
  const [location, setLocation] = useState({});
  const options = { plugins: { legend: { display: false } } };
  const data = {
    labels: [
      "Ter informatie",
      "Lage prioriteit",
      "Medium prioriteit",
      "Hoge prioriteit",
    ],
    datasets: [
      {
        label: "Prioriteit meldingen",
        data: alerts.length ? calcPrios(alerts) : [],
        backgroundColor: [
          "rgba(237,237,237,0.7)",
          "rgba(222,219,10,0.7)",
          "rgba(222,155,10,0.7)",
          "rgba(222,10,10,0.7)",
        ],
        borderColor: [
          "rgba(237,237,237,0.9)",
          "rgba(222,219,10,0.9)",
          "rgba(222,155,10,0.9)",
          "rgba(222,10,10,0.9)",
        ],
        hoverBackgroundColor: [
          "rgba(237,237,237,1)",
          "rgba(222,219,10,1)",
          "rgba(222,155,10,1)",
          "rgba(222,10,10,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    alertService
      .retrieveLocationAlerts(locationId, 25, 0)
      .then((locationAlerts) => {
        setAlerts(locationAlerts);
      })
      .catch((e) =>
        console.log(`Could not retrieve alerts for location: ${e}`)
      );
  }, [alertService, locationId]);

  useEffect(() => {
    locationService
      .retrieveLocation(locationId)
      .then((selectedLocation) => setLocation(selectedLocation))
      .catch((e) => console.log(`Could not retrieve location: ${e}`));
  }, [locationService, locationId]);

  setTimeout(() => {
    if (scrollList && scrollList.current) {
      const scrollBarWidth = calcScrollbarWidth(scrollList.current);
      if (legendBar && legendBar.current) {
        legendBar.current.style.width = `calc(100% - ${20 + scrollBarWidth}px)`;
      }
    }
  }, 1);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
      }}
    >
      <div className="middle-top" ref={windowRef}>
        <div className="room-info">
          <div className="room-header">
            {location.name ? (
              `${location.type_name} - ${location.name}`
            ) : (
              <div className="room-ph" />
            )}
          </div>
          <div className="client-info">
            {location.client_name ? (
              <>
                <div className="icon" />
                <div className="name">{location.client_name}</div>
              </>
            ) : (
              <div className="name-ph" />
            )}
          </div>
          <div className="building-info">
            {location.parent_name ? (
              <>
                <div className="icon" />
                <div className="name">{location.parent_name}</div>
              </>
            ) : (
              <div className="name-ph" />
            )}
          </div>
        </div>
        <div className="room-stats">
          {location ? <Doughnut data={data} options={options} /> : null}
        </div>
      </div>

      <LocationDetailAlertsDataGrid locationAlerts={alerts} />
    </div>
  );
}

export default LocationDetails;
