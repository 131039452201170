import * as React from "react";
import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE11 support
import { useTranslation } from "react-i18next";

import SearchLocationGroup from "../../input/SearchLocationGroup";
import { ServiceContext } from "../../../context/ServiceContext";
import { padSingleDigit } from "../../../util/util.js";

function ClosedIcon(props) {
  // icon when not yet expanded
  return (
    <ChevronRightIcon
      fontSize="inherit"
      style={{ width: 30, height: 30 }}
      {...props}
    />
  );
}

function OpenedIcon(props) {
  // icon when already opened
  return (
    <ExpandMoreIcon
      fontSize="inherit"
      style={{ width: 30, height: 30 }}
      {...props}
    />
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

function msToTime(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return (
    padSingleDigit(hrs) +
    ":" +
    padSingleDigit(mins) +
    ":" +
    padSingleDigit(secs)
  );
}

const calcInitials = (full_name) => {
  if (full_name) {
    var names = full_name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    } else {
      initials = "?";
    }
  }
  return initials;
};

const calcDisplayStyle = (statusStyle, location) => {
  if (statusStyle === "high" && !location.alert.assignee) return "flashing";
  return "";
};

TransitionComponent.propTypes = {
  //Show the component; triggers the enter or exit states
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 15,
    marginBottom: 5,
    borderLeft: `1px dashed white}`,
    backgroundColor: "rgba(39, 40, 41, 1.0)",
    borderRadius: "5px",
  },
  [`& .${treeItemClasses.label}`]: {
    "&:hover": {
      backgroundColor: "rgb(76, 76, 76) !important",
      borderRadius: "5px",
    },
    "&:hover .open_arrow": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${treeItemClasses.root}`]: {},
  [`& .${treeItemClasses.content}`]: {
    width: "calc(100% - 16px) !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    "&:hover": {
      backgroundColor: "inherit !important",
      borderRadius: "5px",
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
    // margin: 2,
    // width: 0,
  },
  [`& .${treeItemClasses.selected}`]: {
    backgroundColor: "inherit !important",
  },
}));

function LocationTreeItem({ location, onClick, filteredLocations }) {
  const context = useContext(ServiceContext);
  let [locationChildren, setLocationChildren] = useState([]);
  let [fetchingChildren, setFetchingChildren] = useState(false);

  let statusStyle = "ok";
  const hasCreatedAt = location.alert && location.alert.created;
  const hasAcceptedAt = location.alert && location.alert.assigned;
  const [createdAt, updateCreatedAt] = useState(
    hasCreatedAt ? new Date(location.alert.created) : null
  );
  const [acceptedAt, updateAcceptedAt] = useState(
    hasAcceptedAt ? new Date(location.alert.assigned) : null
  );
  let [deltaCreated, setDeltaCreated] = useState(0);
  let [deltaAccepted, setDeltaAccepted] = useState(0);

  if (
    location.children_count > 0 &&
    locationChildren.length === 0 &&
    !fetchingChildren
  ) {
    // fetch children if state is empty
    const locationService = context.locationService;
    locationService.retrieveLocation(location.id).then((response) => {
      setLocationChildren(response.children);
      setFetchingChildren(false);
    });
    setFetchingChildren(true);
  }
  let childList = locationChildren.map((child) => {
    if (
      filteredLocations &&
      filteredLocations.some((el) => el.id === child.id)
    ) {
      return (
        <LocationTreeItem
          key={`key-${child.id}`}
          location={child}
          onClick={onClick}
          filteredLocations={filteredLocations}
        ></LocationTreeItem>
      );
    } else {
      return null;
    }
  });

  if (location.alert) {
    switch (location.alert.priority) {
      case 3:
        statusStyle = "high";
        break;
      case 2:
        statusStyle = "medium";
        break;
      case 1:
        statusStyle = "low";
        break;
      case 0:
        statusStyle = "ok";
        break;
      default:
        statusStyle = "ok";
        break;
    }
  }

  useEffect(() => {
    if (location.alert) {
      if (hasCreatedAt) updateCreatedAt(() => new Date(location.alert.created));
      if (hasAcceptedAt)
        updateAcceptedAt(() => new Date(location.alert.assigned));
    }
  }, [hasCreatedAt, hasAcceptedAt, location.alert]);

  useEffect(() => {
    const updateSeconds = setInterval(() => {
      if (hasCreatedAt) setDeltaCreated(new Date() - createdAt);
      if (hasAcceptedAt) setDeltaAccepted(new Date() - acceptedAt);
    }, 500);
    return () => clearInterval(updateSeconds);
  }, [createdAt, acceptedAt, hasCreatedAt, hasAcceptedAt]);

  const label = (
    <Box
      className={calcDisplayStyle(statusStyle, location)}
      onClick={() => onClick(location.id)}
      sx={{ width: "fit-content" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          wordBreak: "break-all",
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box sx={{ px: 1 }}>
            <div className="status">
              <div className={statusStyle}></div>
            </div>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontWeight: "inherit", flexGrow: 1, px: 1, minWidth: 200 }}
          >
            {location.type_name} - {location.name}
          </Typography>
        </Box>

        <Box sx={{ minWidth: 120 }} className="info last-event">
          <Typography className="name">
            {location.alert ? location.alert.message : ""}
          </Typography>
        </Box>

        <Box sx={{ minWidth: 75, mx: 1 }}>
          {location.alert && hasCreatedAt ? msToTime(deltaCreated) : ""}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            mx: 1,
            minWidth: 160,
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: 40,
              height: 40,
              mx: 1,
              bgcolor: "transparent",
              borderRadius: "10px",
            }}
            src={
              location.alert &&
              location.alert.assignee &&
              location.alert.assignee.photo
                ? `${location.alert.assignee.photo}`
                : null
            }
          >
            {location.alert &&
            location.alert.assignee &&
            location.alert.assignee.photo ? (
              <div
                className="photo"
                style={{
                  backgroundImage: `url(${location.alert.assignee.photo})`,
                }}
              />
            ) : (
              <div
                className="initials"
                style={{
                  backgroundColor:
                    location.alert && location.alert.assignee
                      ? "#181818"
                      : "transparent",
                }}
              >
                {" "}
                {location.alert &&
                location.alert.assignee &&
                location.alert.assignee.full_name
                  ? calcInitials(location.alert.assignee.full_name)
                  : ""}
              </div>
            )}
          </Avatar>
          <Typography>
            {location.alert &&
            location.alert.assignee &&
            location.alert.assignee.full_name
              ? location.alert.assignee.full_name
              : null}
          </Typography>
        </Box>

        <Box sx={{ minWidth: 75, mx: 1 }}>
          {location.alert && location.alert.assignee && acceptedAt
            ? msToTime(deltaAccepted)
            : ""}
        </Box>

        <Box
          className="open_arrow"
          sx={{
            width: 30,
            mx: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChevronRightIcon sx={{ width: "20px" }} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <StyledTreeItem
      key={`key-${location.id}`}
      nodeId={`${location.id}`}
      label={label}
    >
      {childList}
    </StyledTreeItem>
  );
}

export default function ListLocationGroupedMUI({
  locations,
  topLevelLocations,
  onClick,
}) {
  const { t } = useTranslation();
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [filterON, setFilterON] = useState(false);

  console.log(topLevelLocations);

  useEffect(() => {
    if (locations) setFilteredLocations(locations);
  }, [locations]);

  let treeItems = [];
  if (filterON && topLevelLocations) {
    treeItems = topLevelLocations.map((location) =>
      filteredLocations &&
      filteredLocations.some((el) => el.id === location.id) ? (
        <LocationTreeItem
          key={`key-${location.id}`}
          location={location}
          onClick={onClick}
          filteredLocations={filteredLocations}
        />
      ) : null
    );
  } else if (topLevelLocations) {
    treeItems = topLevelLocations.map((location) => (
      <LocationTreeItem
        key={`key-${location.id}`}
        location={location}
        onClick={onClick}
        filteredLocations={filteredLocations}
      />
    ));
  }

  return (
    <TreeView
      aria-label="customized"
      defaultExpanded={["0"]}
      defaultCollapseIcon={<OpenedIcon />}
      defaultExpandIcon={<ClosedIcon />}
      defaultEndIcon={null}
      sx={{
        overflow: "scroll",
      }}
    >
      <SearchLocationGroup
        locations={locations}
        setFilteredLocations={setFilteredLocations}
        filterON={filterON}
        setFilterON={setFilterON}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          width: "calc(100% - 26px)",
          padding: "0px 8px 10px 8px",
          fontSize: "0.8em",
          opacity: 0.7,
          pr: 3,
        }}
      >
        <Box sx={{ width: 60 }}></Box>
        <Box
          className="unselectable"
          sx={{ width: 150, mx: 2, display: "flex", flexGrow: 1 }}
        >
          {t("dashboard.groupView.Location")}Locatie
        </Box>
        <Box className="unselectable" sx={{ width: 120, mx: 1 }}>
          {t("dashboard.groupView.Highest Priority")}
        </Box>
        <Box className="unselectable" sx={{ width: 75, mx: 1 }}>
          {t("dashboard.groupView.Time since Alert")}
        </Box>
        <Box className="unselectable" sx={{ width: 160, mx: 1 }}>
          {t("dashboard.groupView.Assigned To")}
        </Box>
        <Box className="unselectable" sx={{ width: 75, mx: 1 }}>
          {t("dashboard.groupView.Time since Assignment")}
        </Box>
        <Box sx={{ width: 40 }}></Box>
      </Box>

      {treeItems}
    </TreeView>
  );
}
