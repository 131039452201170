import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Box, Icon, Link, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import { ServiceContext } from "../../context/ServiceContext";
import WebSocketClient from "../../websocket/WebSocketClient";
import "./shoutbox.css";
import TimeSince from "../other/Time";
import { useTranslation } from "react-i18next";
import AlertSVG from "../../assets/svgs/warning.svg";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";

function Shoutbox({ selectLocation, alertDrawer, setAlertDrawer }) {
  const { t } = useTranslation();
  const { alertService, organizationService } = useContext(ServiceContext);
  const [messages, setMessages] = useState([]);

  // fetch initial alert logs
  useEffect(() => {
    if (!messages.length) {
      alertService
        .retrieveOrganizationAlertLogs()
        .then((alerts) => {
          let alert_list = [];
          alerts.forEach((alert) => {
            alert_list.push(alert);
          });
          setMessages(alert_list);
        })
        .catch((e) => console.log(`Could not retrieve alerts: ${e}`));
    }
  }, [alertService, messages.length]);

  // logic for new websocket messages ->
  useEffect(() => {
    const onNewAlert = (alert) => {
      if (
        alert.alert.organization === organizationService.selectedOrg
      ) {
        setMessages((prevArray) => [alert, ...prevArray]);
      } else console.log("received websocket message, but not processing it.");
    };
    WebSocketClient.registerMessageCallbacks({
      Created: { id: "Shoutbox", callback: onNewAlert },
      Assigned: { id: "Dashboard", callback: onNewAlert },
      Resolved: { id: "Dashboard", callback: onNewAlert },
    });
    /* Deregister callbacks on cleanup */
    return () => WebSocketClient.deregisterMessageCallbacks("Shoutbox");
  }, [organizationService.selectedOrg]);

  const getAlertColor = (alert) => {
    let color = "transparent";
    if (alert.priority === 3) color = "#DE0A0A";
    if (alert.priority === 2) color = "#DE9B0A";
    if (alert.priority === 1) color = "#DEDB0A";
    return color;
  };

  const renderAlertMessage = (message) => {
    switch (message.status) {
      case "Created":
        /* new, unassigned and unresolved alert */
        return (
          <>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 15,
                  width: 15,
                  mr: 1,
                }}
              >
                <img
                  style={{
                    display: "flex",
                    height: "inherit",
                    width: "inherit",
                    textAlign: "center",
                    borderRadius: "50%",
                    backgroundColor: getAlertColor(message.alert),
                  }}
                  alt="alert icon"
                  src={AlertSVG}
                />
              </Icon>
              {`${message.alert.message}`}
            </Typography>
          </>
        );
      case "Assigned":
        return (
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <PendingIcon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 15,
                width: 15,
                mr: 1,
              }}
            ></PendingIcon>
            {`"${message.alert.message}" ${t("shoutbox.assignedTo")} ${
              message.alert.assignee.full_name
            }`}
          </Typography>
        );
      case "Resolved":
        return (
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <CheckIcon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 15,
                width: 15,
                mr: 1,
                color: "rgb(10, 222, 10)",
              }}
            ></CheckIcon>
            {`"${message.alert.message}" ${t("shoutbox.resolvedBy")} ${
              message.alert.assignee ? message.alert.assignee.full_name : "null"
            }`}
          </Typography>
        );
      default:
        return;
    }
  };

  const renderWebsocketMessage = (message) => {
    switch (message.event_type) {
      case "Created":
        /* new, unassigned and unresolved alert */
        return (
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 15,
                width: 15,
                mr: 1,
              }}
            >
              <img
                style={{
                  display: "flex",
                  height: "inherit",
                  width: "inherit",
                  textAlign: "center",
                  borderRadius: "50%",
                  backgroundColor: getAlertColor(message.alert),
                }}
                alt="alert icon"
                src={AlertSVG}
              />
            </Icon>
            {`${message.alert.message}`}
          </Typography>
        );
      case "Assigned":
        return (
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <PendingIcon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 15,
                width: 15,
                mr: 1,
              }}
            ></PendingIcon>
            {`"${message.alert.message}" ${t("shoutbox.assignedTo")} ${
              message.alert.assignee.full_name
            }`}
          </Typography>
        );
      case "Resolved":
        return (
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <CheckIcon
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 15,
                width: 15,
                mr: 1,
                color: "rgb(10, 222, 10)",
              }}
            >
              =
            </CheckIcon>
            {`"${message.alert.message}" ${t("shoutbox.resolvedBy")} ${
              message.alert.assignee.full_name
            }`}
          </Typography>
        );
      default:
        return;
    }
  };

  const renderWebsocketTime = (message) => {
    if (message.event_type === "Created")
      return <TimeSince date={message.alert.created} />;
    if (message.event_type === "Assigned")
      return <TimeSince date={message.alert.assigned} />;
    if (message.event_type === "Resolved")
      return <TimeSince date={message.alert.resolved} />;
    return <Typography variant="subtitle1">error</Typography>;
  };

  const renderMessage = (message) => {
    if (!message.event_type) {
      // Regular alert log object
      return (
        <Box
          sx={{ display: "flex", p: 1, borderBottom: "1px solid black" }}
          key={`msg${message.id}`}
        >
          <Box sx={{ flex: 1 }}>
            <Typography display="inline">
              {message.alert.location.type_name
                ? `${message.alert.location.type_name} `
                : "Location "}
            </Typography>
            <Link
              display="inline"
              variant="body1"
              onClick={() => selectLocation(message.alert.location.id)}
              style={{ cursor: "pointer" }}
            >
              {message.alert.location.name}
            </Link>
            {renderAlertMessage(message)}
          </Box>
          <Box sx={{ maxWidth: "30%", textAlign: "end" }}>
            <TimeSince date={message.created} />
          </Box>
        </Box>
      );
    } else {
      // these are websocket messages
      return (
        <Box
          sx={{ display: "flex", p: 1, borderBottom: "1px solid black" }}
          // a regular alert has an id attribute, but a visit has a visit_id
          key={`${message.event_type}_${message.id}`}
        >
          <Box sx={{ flex: 1 }}>
            <Typography display="inline">
              {message.alert.location.type_name
                ? `${message.alert.location.type_name} `
                : "Location "}
            </Typography>
            <Link
              display="inline"
              variant="body1"
              onClick={() => selectLocation(message.alert.location.id)}
              style={{ cursor: "pointer" }}
            >
              {message.alert.location.name}
            </Link>
            {renderWebsocketMessage(message)}
          </Box>
          <Box sx={{ maxWidth: "30%", textAlign: "end" }} variant="subtitle1">
            {renderWebsocketTime(message)}
          </Box>
          <Divider />
        </Box>
      );
    }
  };

  let messageList =
    messages && messages.length > 0 ? messages.map(renderMessage) : [];

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={alertDrawer}
      onClose={() => setAlertDrawer(false)}
      transitionDuration={700}
      sx={{
        width: alertDrawer ? "400px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: alertDrawer ? "400px" : 0,
          maxWidth: "100%",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: alertDrawer ? "-20px" : "-40px",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          alertDrawer ? setAlertDrawer(false) : setAlertDrawer(true);
        }}
      >
        {alertDrawer ? (
          <Tooltip title="Hide Real-Time Alerts">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Show Real-Time Alerts">
            <KeyboardArrowLeftIcon
              sx={{ height: "40px", width: "40px", color: "primary" }}
            />
          </Tooltip>
        )}
      </Button>
      <Box pt={1} className="shoutbox" minHeight={"100%"}>
        {messageList.length ? (
          messageList
        ) : (
          <Typography
            sx={{
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
          >
            Couldn't fetch any alerts.
          </Typography>
        )}
      </Box>
    </Drawer>
  );
}

export default Shoutbox;
