import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ManageAlertRulesDataGrid = ({
  setSelectedAlertRule,
  setOpenAlertRuleDrawer,
  alertRules,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let columns = [
    {
      field: "id",
      headerName: t("id"),
      maxWidth: 50,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row.id}
        </Box>
      ),
    },
    {
      field: "priority",
      headerName: t("manageAlerts.alertRules.dataGrid.priority"),
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <Typography>
            {params.row ? params.row.get_priority_display : ""}
          </Typography>
        </Box>
      ),
    },
    {
      field: "event_types",
      headerName: t("manageAlerts.alertRules.dataGrid.eventTypes"),
      minWidth: 150,
      valueGetter: (params) =>
        params.row.event_types ? params.row.event_types.toString() : null,
      renderCell: (params) => (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <Typography fontSize={10}>
            {params.row && params.row.event_types
              ? params.row.event_types.toString()
              : "All"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "device_name",
      headerName: t("manageAlerts.alertRules.dataGrid.deviceName"),
      minWidth: 200,
    },
    {
      field: "location_name",
      headerName: t("manageAlerts.alertRules.dataGrid.locationName"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "is_active",
      headerName: t("manageAlerts.alertRules.dataGrid.isActive"),
      maxWidth: 75,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row && params.row.is_active ? (
            <CheckCircleOutlineIcon size="small" color="primary" />
          ) : (
            <HighlightOffIcon size="small" color="error" />
          )}
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: t("Edit"),
      maxWidth: 50,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <EditIcon
            className="MuiSvgIcon-edit"
            sx={{ width: "20px", height: "20px" }}
          />
        </Box>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  let rows = [];
  if (alertRules) rows = alertRules;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      onRowClick={(params, event) => {
        setSelectedAlertRule(params.row);
        setOpenAlertRuleDrawer(true);
      }}
      sx={{
        width: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        "& .MuiSvgIcon-edit": {
          color: "white",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(76, 76, 76) !important",
          cursor: "pointer",
        },
        " & .MuiDataGrid-row:hover .MuiSvgIcon-edit": {
          color: theme.palette.primary.main,
        },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default ManageAlertRulesDataGrid;
