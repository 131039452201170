/* 
    Defines common utility functions.
*/

import normalizeWheel from './facebook_normalize_wheel';

export function calcScrollbarWidth(div) {
    return div.offsetWidth - div.clientWidth;
}

export function getMonthName(month) {
    /* Returns Dutch month name by index
       Expects input to be an number ([0,..11])
    */
    const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"]
    return months[month];
}

export function getCurrentDate(separator = '') {
    /* Returns date in DD:MM:YYYY format */
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
}

export function getHTMLDate() {
    /* Parses a date to the y/m/d format for use with HTML inputs */
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    return `${year}-${padSingleDigit(month + 1)}-${padSingleDigit(day)}`;
}

export function htmlDateToApiDate(date) {
    /* Converts an HTML input date to a date which the API understands */
    const date_parts = date.split("-");
    return `${date_parts[2]}-${date_parts[1]}-${date_parts[0]}`;
}

export function dtToApiDt(dt) {
    /* Converts a datetime object to a string which the API can interpret as datetime obj */
    return `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}T${dt.getUTCHours()}:${dt.getUTCMinutes()}:${dt.getUTCSeconds()}.${dt.getUTCMilliseconds()}`;
}

export function dtToApiDate(dt) {
    /* Converts a datetime object to a string which the API can interpret as date obj */
    return `${dt.getUTCFullYear()}-${padSingleDigit(dt.getUTCMonth() + 1)}-${padSingleDigit(dt.getUTCDate())}`;
}

export function dtToMinutes(dt_str) {
    /* Returns the expired minutes since 00:00 */
    let dt = new Date(dt_str);
    let hours = dt.getHours();
    return dt.getMinutes() + (hours * 60);
}

export function dtToLogDt(dt) {
    /* Converts a datetime object to a string to be displayed in a LogEntry */
    return `${padSingleDigit(dt.getHours())}:${padSingleDigit(dt.getMinutes())}:${padSingleDigit(dt.getSeconds())}\n${dt.getFullYear()}-${padSingleDigit(dt.getMonth() + 1)}-${padSingleDigit(dt.getDate())}`;
}

export function getUTCDayStart(date) {
    /* Get the start of the local date in UTC */
    var dt = new Date(date);
    dt.setHours(0, 0, 0, 0);
    return `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}T${dt.getUTCHours()}:${dt.getUTCMinutes()}:${dt.getUTCSeconds()}.${dt.getUTCMilliseconds()}`;
}

export function getUTCDayEnd(date) {
    /* Get the end of the local date in UTC */
    var dt = new Date(date);
    dt.setHours(24, 0, 0, 0);
    return `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}T${dt.getUTCHours()}:${dt.getUTCMinutes()}:${dt.getUTCSeconds()}.${dt.getUTCMilliseconds()}`;
}

export function isToday(someDate) {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export function getCurrentTime() {
    /* Returns current time in minutes since 00:00 */
    let newDate = new Date();
    return `${padSingleDigit(newDate.getHours())}:${padSingleDigit(newDate.getMinutes())}`;
}

export function getCurrentTimeInMinutes() {
    /* Returns current time in minutes since 00:00 */
    let newDate = new Date();
    let hours = newDate.getHours();
    return newDate.getMinutes() + (hours * 60);
}

export function isTimeLaterThan(now, then) {
    /* Compares two time strings in the format of hh:mm (by minutes only)*/
    const now_min = parseInt(now.substring(now.indexOf(":")));
    const then_min = parseInt(then.substring(then.indexOf(":")));
    return (now_min > then_min);

}

export function minutesToPixels(minutes, total_pixels, total_minutes) {
    /* Converts the amount of expired minutes to pixels */
    return (minutes * (total_pixels / total_minutes));
}

export function pixelsToTime(pixels, total_pixels, total_minutes) {
    /* Converts pixels to expired time (00:00) */
    const minutes = (pixels / total_pixels * total_minutes);
    return `${padSingleDigit(parseInt(minutes / 60))}:${padSingleDigit(parseInt(minutes % 60))}`;
}

export function pixelsToMinutes(pixels, total_pixels, total_minutes) {
    /* Converts pixels to expired minutes */
    return parseInt((pixels / total_pixels * total_minutes));
}

export function padSingleDigit(n) {
    /* Prepends a zero to a number if it only has a single digit */
    return (n < 10 ? '0' : '') + n;
}

/* Scrolling helpers */

export function normalizeMouseWheel(event) {
    /* Wraps Facebook's wheel normalization function */
    return normalizeWheel(event);
}

export function getWheelEventType() {
    return normalizeWheel.getEventType();
}

export function removeHypen(input) {
    return input.replace("-", "");
}

export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function readCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
