import React, { useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { primaryTheme } from "./themes/primaryTheme";
import { HashRouter as Router } from "react-router-dom";
import XSAlertAppBar from "./components/nav/XSAlertAppBar";
import Routes from "./routes";
import ServiceContextProvider from "./context/ServiceContext";
import ApiService from "./services/ApiService";
import AuthService from "./services/AuthService";
import OrganizationService from "./services/OrganizationService";
import SignIn from "./pages/sign_in/SignIn";
import LoadingScreen from "./pages/loading/LoadingScreen";
import SelectOrganization from "./pages/sign_in/SelectOrganization";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "aa6eb04ef715cd9c3c086fde96b0316fT1JERVI6MzkwNTgsRVhQSVJZPTE2NzgwMzUyODYwMDAsS0VZVkVSU0lPTj0x"
);

const apiService = new ApiService();

function App() {
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [chosenOrganization, setChosenOrganization] = useState(-1);
  const [authService] = useState(
    new AuthService(apiService, setAuthenticated, setLoading)
  );
  const [organizationService] = useState(
    new OrganizationService(
      apiService,
      (new_org) => setChosenOrganization(new_org),
      setLoading
    )
  );
  const [view, setView] = useState("list");

  useEffect(() => {
    /* Checks if the user is authenticated */
    authService.isAuthenticated().then().catch();
  }, [authService]);

  useEffect(() => {
    /* Retrieves a list of organizations */
    if (authenticated && !organizationService.orgs) {
      organizationService.retrieveOrgs().then((orgs) => {
        if (orgs && orgs.length === 1) {
          organizationService.setChosenOrganization(orgs[0].id);
        } else if (orgs && orgs.length > 1) {
          organizationService.getChosenOrganization();
        }
      });
    }
  }, [authenticated, organizationService]);

  if (loading) return LoadingScreen();

  if (!authenticated) {
    /* Not authenticated, return to login screen */
    return (
      <div className="App">
        <SignIn authService={authService} loading={loading} />
      </div>
    );
  } else if (
    chosenOrganization !== -1 &&
    organizationService.selectedOrg !== -1
  ) {
    /* The user is logged in and has an organization selected */
    return (
      <div className="App">
        <ThemeProvider theme={primaryTheme}>
          <ServiceContextProvider
            apiService={apiService}
            authService={authService}
            organizationService={organizationService}
            view={view}
            setView={setView}
          >
            <Router>
              <XSAlertAppBar />
              <Routes view={view} />
            </Router>
          </ServiceContextProvider>
        </ThemeProvider>
      </div>
    );
  } else if (chosenOrganization === -1) {
    /* No organization has been chosen */
    return (
      <div className="App">
        <SelectOrganization
          orgs={organizationService.orgs}
          choice={organizationService.setChosenOrganization}
          loading={loading}
          authService={authService}
        />
      </div>
    );
  }
}

export default App;
