import React, { useEffect, useState } from 'react';
import KeplerSVG from '../../assets/svgs/videocam.svg';
import EvresysSVG from '../../assets/svgs/room.svg';
import NextXSVG from '../../assets/svgs/door-white-flat.svg';
import XSAlertSVG from '../../assets/svgs/notifcation-white.svg';
import DaySVG from '../../assets/svgs/sun.svg';
import NightSVG from '../../assets/svgs/moon.svg';
import ReactTooltip from 'react-tooltip';
import WebSocketClient from '../../websocket/WebSocketClient';
import './statusdock.css';

const renderTechnology = (technology) => {
    return (<div className="tech-state" data-tip={`${technology.name} status`} key={`tech${technology.id}`}>
        <div className="icon" style={{ backgroundImage: `url(${technology.icon})` }}></div>
        <div className={`active ${technology.active}`}></div>
    </div>);
}

function StatusDock() {
    const [socketConnection, setSocketConnection] = useState(WebSocketClient.isConnected());
    const technologies1 = [
        { "id": 1, "name": "Kepler", "icon": KeplerSVG, "active": true },
        { "id": 2, "name": "Evresys", "icon": EvresysSVG, "active": true },
    ];

    const technologies2 = [
        { "id": 3, "name": "NextXS", "icon": NextXSVG, "active": true },
        { "id": 4, "name": "XSAlert", "icon": XSAlertSVG, "active": socketConnection },
    ];

    const onWebSocketConnect = () => setSocketConnection(true);
    const onWebSocketDisconnect = () => setSocketConnection(false);

    useEffect(()=>{
        WebSocketClient.connect();
        WebSocketClient.registerEventCallbacks({
            "onConnect": {"id": "StatusDock", "callback": onWebSocketConnect },
            "onDisconnect": {"id": "StatusDock", "callback": onWebSocketDisconnect },
        });
        /* Deregister callbacks on cleanup */
        return () => WebSocketClient.deregisterEventCallbacks("StatusDock")
    },[])

    const hours = new Date().getHours()
    const isDayTime = hours > 6 && hours < 20

    let techList1 = technologies1.map(renderTechnology)
    let techList2 = technologies2.map(renderTechnology)
    return (
        <div className="status-dock">
            <div className="container">
                {techList1}
                <div className="mode" data-tip={`${isDayTime ? "Dag" : "Nacht"} modus is actief`}>
                    <div className="icon" style={{ backgroundImage: isDayTime ? `url(${DaySVG})` : `url(${NightSVG})` }} />
                </div>
                {techList2}
            </div>
            <ReactTooltip place="bottom" type="dark" effect="solid" />
        </div>
    );
}

export default StatusDock;