import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, Avatar } from "@mui/material";

const ManageUserGroupsDataGrid = ({
  userGroups,
  setSelectedUserGroup,
  setOpenUserGroupDrawer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let columns = [
    {
      field: "photo_url",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Avatar></Avatar>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: t("manageUsers.userGroups.dataGrid.name"),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "members_count",
      headerName: t("manageUsers.userGroups.dataGrid.memberCount"),
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row.members_count}
        </Box>
      ),
    },
    {
      field: "permissions_count",
      headerName: t("manageUsers.userGroups.dataGrid.permissionCount"),
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row.permissions_count}
        </Box>
      ),
    },
  ];
  let rows = [];
  if (userGroups) rows = userGroups;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      onRowClick={(params, event) => {
        setSelectedUserGroup(params.row);
        setOpenUserGroupDrawer(true);
      }}
      sx={{
        flexGrow: 1,
        width: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        // "& .MuiSvgIcon-root": {
        //   color: "white",
        // },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(76, 76, 76) !important",
        },
        // " & .MuiDataGrid-row:hover .MuiSvgIcon-root": {
        //   color: theme.palette.primary.main,
        // },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default ManageUserGroupsDataGrid;
