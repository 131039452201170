import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";

const ManageDevicesDataGrid = ({
  devices,
  setSelectedDevice,
  setOpenDeviceDrawer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let columns = [
    {
      field: "id",
      headerName: t("id"),
      minWidth: 50,
    },
    {
      field: "identifier",
      headerName: t("manageDevices.devices.dataGrid.identifier"),
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>{params.row.identifier}</Box>
      ),
    },
    {
      field: "device_type",
      headerName: t("manageDevices.devices.dataGrid.device"),
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>{params.row.device_type_name}</Box>
      ),
      valueGetter: (params) => {
        return `${params.row.device_type_name}`;
      },
    },
    {
      field: "brand",
      headerName: t("manageDevices.devices.dataGrid.brand"),
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>{params.row.device_type_brand}</Box>
      ),
      valueGetter: (params) => {
        return `${params.row.device_type_brand}`;
      },
    },
    {
      field: "location_name",
      headerName: t("manageDevices.devices.dataGrid.location"),
      flex: 1,
    },
    {
      field: "edit",
      headerName: t("Edit"),
      minWidth: 75,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <EditIcon sx={{ width: "20px", height: "20px" }} />
        </Box>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  let rows = [];
  if (devices) rows = devices;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      onRowClick={(params, event) => {
        setSelectedDevice(params.row);
        setOpenDeviceDrawer(true);
      }}
      sx={{
        flexGrow: 1,
        width: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(76, 76, 76) !important",
          cursor: "pointer",
        },
        " & .MuiDataGrid-row:hover .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default ManageDevicesDataGrid;
