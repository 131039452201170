import photo_placeholder from "../assets/images/photo_placeholder.jpg";

class OrganizationService {
  constructor(
    apiService,
    updateGlobalOrganization,
    setGlobalLoading
  ) {
    this.apiService = apiService;
    this.orgs = null;
    this.selectedOrg = -1;
    this.selectedOrgDetails = {
      id: this.selectedOrg,
      name: "No Organization",
      logo: photo_placeholder,
    };
    this.updateGlobalOrganization = updateGlobalOrganization;
    this.setGlobalLoading = setGlobalLoading;

    this.setChosenOrganization = this.setChosenOrganization.bind(this);
    this.getChosenOrganization = this.getChosenOrganization.bind(this);
  }

  async retrieveOrgs() {
    this.setGlobalLoading(true);
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizations();
      if (response.success && response.data.count) this.orgs = response.data.results;
    }
    this.setGlobalLoading(false);
    return this.orgs;
  }

  async setChosenOrganization(id) {
    this.setGlobalLoading(true);
    console.log(`Setting chosen Organization to ${id}`);
    this.apiService.setOrganizationId(id);
    this.selectedOrg = id;
    localStorage.setItem("org_id", String(id));
    if (this.apiService && id !== -1) {
      const response = await this.apiService.retrieveOrganization(id);
      if (response.success) {
        this.selectedOrgDetails = response.data;
        this.selectedOrg = response.data.id;
      }
    }
    this.updateGlobalOrganization(id);
    this.setGlobalLoading(false);
    return this.selectedOrg;
  }

  getChosenOrganization() {
    // returns selected organization id, or returns -1 if no organization
    if (this.selectedOrg === -1) {
      if (localStorage.hasOwnProperty("org_id")) {
        const localOrg = localStorage.getItem("org_id");
        if (localOrg) {
          this.setChosenOrganization(parseInt(localOrg));
          return parseInt(localOrg);
        }
      }
    }
    return this.selectedOrg;
  }

  getChosenOrganizationDetails() {
    return this.selectedOrgDetails;
  }

  getOrgs() {
    return this.orgs;
  }

  getOrg(pk) {
    for (var i = 0; i < this.orgs.length; i++) {
      if (this.orgs[i].id === pk) {
        return this.orgs[i];
      }
    }
    return null;
  }

  getOrgLogo(pk) {
    const org = this.getOrg(pk);
    if (org) return org.logo;
    return null;
  }

  getSelOrgIdx() {
    try {
      if (this.apiService && this.orgs) {
        const org_id = this.apiService.getOrgId();
        for (var i = 0; i < this.orgs.length; i++) {
          if (org_id === this.orgs[i].id) {
            return i;
          }
        }
      }
    } catch {
      console.log("Failure getting selected org index");
    }
    return 0;
  }

  // async retrieveUserGroups() {
  //     if(this.apiService){
  //         const response = await this.apiService.getUserGroups();
  //         if(response.success)
  //         {
  //             this.userGroups = response.data;
  //             if(this.apiService.debug === true) console.log(response.data)
  //             return this.userGroups;
  //         }
  //     }
  //     return this.userGroups;
  // }

  // async removeGroup(group_id){
  //     /* Removes a user from the org */
  //     const response = await this.apiService.removeGroup(group_id);
  //     if(response.success){
  //         const i = this.userGroups.findIndex((group) => group.id === group_id);
  //         this.userGroups.splice(i, 1);
  //         return true;
  //     }
  //     return false;
  // }

  // async addGroup(group_name) {
  //     /* Sends a request to the API for adding a group */
  //     const response = await this.apiService.addGroup(group_name);
  //     if(!response.success){
  //         return {success: response.success, reason: await response.data.text()};
  //     }else{
  //         return {success: response.success, reason: response.data};
  //     }
  // }
}
export default OrganizationService;
