import React from "react";
import { useRouteMatch } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ManagementScreen = () => {
  let match = useRouteMatch();
  console.log(match);
  return (
    <Box
      className="page-inner"
      sx={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h2">Management Screen</Typography>
    </Box>
  );
};
export default ManagementScreen;
