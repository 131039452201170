import React, { useState, useContext, useEffect } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from "@mui/material/Modal";

export function DeleteLocationTypeModal({
  openDeleteLocationTypeModal,
  setOpenDeleteLocationTypeModal,
  selectedLocationType,
  handleDeleteLocationType,
}) {
  const { t } = useTranslation();
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={openDeleteLocationTypeModal}
        onClose={() => setOpenDeleteLocationTypeModal(false)}
      >
        <Box sx={style}>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {t("manageLocations.locationTypes.delete.warningText")}
          </Typography>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {selectedLocationType.name}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteLocationType()}
          >
            {t("manageLocations.locationTypes.detail.deleteLocationType")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function ManageLocationTypeDrawer({
  selectedLocationType,
  setSelectedLocationType,
  openLocationTypeDrawer,
  setOpenLocationTypeDrawer,
  handleNewAlert,
  setLocationTypes,
  setSelectedLocation,
  setOpenLocationDrawer,
  setView,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  let context = useContext(ServiceContext);
  let [selectedLocationTypeLocations, setSelectedLocationTypeLocations] =
    useState(false);

  let [expanded, setExpanded] = useState("locationTypeDetails");
  let [openDeleteLocationTypeModal, setOpenDeleteLocationTypeModal] =
    useState(false);

  useEffect(() => {
    // update selectedLocationTypeLocations
    if (selectedLocationType) {
      let locations = [];
      selectedLocationType.locations.forEach((location) => {
        locations.push(
          <ListItem 
            key={location.id}
            onClick={() => {
              setView('locations');
              setSelectedLocation(location);
              setOpenLocationDrawer(true);
            }}
          >
            <ListItemText primary={location.name} />
            <VisibilityIcon sx={{ width: 20, height: 20, mx: 2 }} />
          </ListItem>
        );
      });
      setSelectedLocationTypeLocations(locations);
    }
  }, [selectedLocationType, setOpenLocationDrawer, setSelectedLocation, setView]);

  // form selects
  let [selectedLocationTypeName, setselectedLocationTypeName] = useState(
    selectedLocationType.name
  );

  useEffect(() => {
    // reset form selects when new location is selected
    if (selectedLocationType) {
      setselectedLocationTypeName(selectedLocationType.name);
      setLocationFormChanged(false);
    }
  }, [selectedLocationType]);

  // form errors
  let [locationNameError, setLocationTypeNameError] = useState(false);

  const resetErrors = () => {
    setLocationTypeNameError("");
  };

  // form choices
  let [locationFormChanged, setLocationFormChanged] = useState(false);

  useEffect(() => {
    // set Location Form Changed if any form element has been updated by the user
    if (selectedLocationTypeName !== selectedLocationType.name) {
      setLocationFormChanged(true);
      resetErrors();
    } else setLocationFormChanged(false);
  }, [selectedLocationTypeName, selectedLocationType.name]);

  // handlers
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDeleteLocationType = () => {
    context.locationService
      .deleteLocationType(selectedLocationType.id)
      .then((response) => {
        context.locationService
          .retrieveLocationTypes()
          .then((locationTypes) => {
            setLocationTypes(locationTypes);
          });
        handleNewAlert(
          `Successfully deleted Location Group "${selectedLocationType.name}"`,
          "success"
        );
        setOpenDeleteLocationTypeModal(false);
        setOpenLocationTypeDrawer(false);
      })
      .catch((err) => {
        handleNewAlert(`Error: ${err}`, "error");
        setOpenDeleteLocationTypeModal(false);
        setOpenLocationTypeDrawer(false);
      });
  };

  const handleUpdateLocationType = (id) => {
    let data = {
      name: selectedLocationTypeName,
    };
    context.locationService
      .updateLocationType(id, data)
      .then((response) => {
        context.locationService
          .retrieveLocationTypes()
          .then((locationTypes) => {
            setLocationTypes(locationTypes);
          });
        handleNewAlert(
          `Successfully updated Location Group "${selectedLocationTypeName}"`,
          "success"
        );
        setSelectedLocationType(response);
      })
      .catch((err) => {
        console.log(err);
        if (err.name) {
          setLocationTypeNameError(err.name[0]);
        }
        err = JSON.stringify(err);
        handleNewAlert(`Error: ${err}`, "error");
      });
  };

  const renderUpdateButton = () => {
    if (locationFormChanged) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <Button
            color="warning"
            variant="contained"
            onClick={() => handleUpdateLocationType(selectedLocationType.id)}
          >
            {t("manageLocations.locationTypes.detail.updateLocationType")}
          </Button>
        </Box>
      );
    } else return <></>;
  };

  const renderDrawer = () => (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openLocationTypeDrawer}
      onClose={() => setOpenLocationTypeDrawer(false)}
      transitionDuration={300}
      sx={{
        width: openLocationTypeDrawer ? "500px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: openLocationTypeDrawer ? "500px" : 0,
          px: 0,
          py: 1,
          maxWidth: "90vw",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        ></Box>
        <Box
          sx={{
            "& .MuiPaper-root": {
              py: 0,
            },
            "& .Mui-expanded": {
              my: 0,
            },
            "& .MuiAccordionDetails-root": {
              py: 0,
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiListItemText-root": {
              minWidth: "30%",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "rgb(76, 76, 76) !important",
              color: theme.palette.primary.main,
            },
            "& .MuiListItem-root:hover .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiInputLabel-formControl": { color: "white" },
            "& .MuiInput-input": {
              backgroundColor: "transparent",
              color: "white",
              textAlign: "end",
              px: 0,
            },
            "& .MuiSelect-select": { px: 0, pr: "8px !important" },
            "& .MuiFormControl-root": { m: 0 },
            "& .MuiAutocomplete-root": { width: "calc(100% - 20px)" },
            "& .MuiFormHelperText-root": { color: "red" },
          }}
        >
          <Accordion
            expanded={expanded === "locationTypeDetails"}
            onChange={handleAccordionChange("locationTypeDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h6">
                {t("manageLocations.locationTypes.detail.locationType")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("manageLocations.locationTypes.detail.name")}
                  />
                  <FormControl sx={{flexGrow: 1}}>
                    <TextField
                      variant="standard"
                      value={
                        selectedLocationTypeName ? selectedLocationTypeName : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon sx={{ width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) =>
                        setselectedLocationTypeName(event.target.value)
                      }
                    />
                    <FormHelperText>
                      {locationNameError ? locationNameError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>
                <Divider sx={{ borderColor: "black" }} />
              </List>
              {renderUpdateButton()}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "locations"}
            onChange={handleAccordionChange("locations")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ py: 0, my: 0 }}
            >
              <Typography variant="h6">
                {t("manageLocations.locationTypes.detail.locations")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {selectedLocationTypeLocations}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Button
          variation="outlined"
          color="error"
          onClick={() => setOpenDeleteLocationTypeModal(true)}
          sx={{ my: 1 }}
        >
          {t("manageLocations.locationTypes.detail.deleteLocationType")}
        </Button>
      </Box>
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: openLocationTypeDrawer ? "-20px" : "",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          openLocationTypeDrawer
            ? setOpenLocationTypeDrawer(false)
            : setOpenLocationTypeDrawer(true);
        }}
      >
        {openLocationTypeDrawer ? (
          <Tooltip title="Hide Location Detail">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : null}
      </Button>
      <DeleteLocationTypeModal
        openDeleteLocationTypeModal={openDeleteLocationTypeModal}
        setOpenDeleteLocationTypeModal={setOpenDeleteLocationTypeModal}
        selectedLocationType={selectedLocationType}
        handleDeleteLocationType={handleDeleteLocationType}
      />
    </Drawer>
  );
  return selectedLocationType ? renderDrawer() : null;
}
