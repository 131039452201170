import React, { useState, useContext, useEffect, useCallback } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { List, ListItem, ListItemText, ListItemButton } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";

export function RemoveUserOrganizationModal({
  openRemoveUserOrganizationModal,
  setOpenRemoveUserOrganizationModal,
  handleRemoveUserFromOrganization,
}) {
  const { t } = useTranslation();
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={openRemoveUserOrganizationModal}
        onClose={() => setOpenRemoveUserOrganizationModal(false)}
      >
        <Box sx={style}>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {t("manageUsers.users.removeUserModal.warningText")}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveUserFromOrganization()}
          >
            {t("manageUsers.users.removeUserModal.removeUserFromOrganization")}
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default function ManageUserDrawer({
  openUserDrawer,
  setOpenUserDrawer,
  selectedUser,
  setSelectedUser,
  setSelectedUserGroup,
  setView,
  handleNewAlert,
  refreshUsersList,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  let context = useContext(ServiceContext);
  let [userDetail, setUserDetail] = useState(false);
  let [expanded, setExpanded] = useState("userGroups");
  let [openRemoveUserOrganizationModal, setOpenRemoveUserOrganizationModal] =
    useState(false);

  useEffect(() => {
    // fetch user details
    if (selectedUser) {
      context.userService
        .retrieveUser(selectedUser.id)
        .then((user) => {
          setUserDetail(user);
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    }
  }, [selectedUser, context.userService]);

  // form states
  // let [deviceLocation, setDeviceLocation] = useState(userDetail.location);

  const resetFormValues = useCallback(() => {
    // setDeviceLocation(userDetail.location);
  }, [userDetail]);

  useEffect(() => {
    // reset form selects when new device is selected
    if (userDetail) {
      resetFormValues();
    }
  }, [userDetail, resetFormValues]);

  // form errors
  // const [deviceLocationError, setDeviceLocationError] = useState(false);

  const resetErrors = () => {
    // setDeviceLocationError(false);
  };

  // form changes
  let [userFormChanged, setUserFormChanged] = useState(false);

  // // handlers
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRemoveUserFromOrganization = () => {
    context.userService
      .removeUserFromOrganization(userDetail.id)
      .then((response) => {
        handleNewAlert(
          `Successfully removed user ${userDetail.email} from your organization`,
          "success"
        );
        setTimeout(() => {
          refreshUsersList();
          setOpenRemoveUserOrganizationModal(false);
          setOpenUserDrawer(false);
        }, 500);
      })
      .catch((err) => {
        handleNewAlert(`Error: ${err}`, "error");
        setOpenRemoveUserOrganizationModal(false);
        setOpenUserDrawer(false);
      });
  };

  const handleUpdateUser = (id) => {
    let data = {
      organization: context.organizationService.selectedOrg,
    };
    context.userService
      .updateUser(id, data)
      .then((response) => {
        refreshUsersList();
        handleNewAlert(`Successfully updated User`, "success");
        setSelectedUser(response);
      })
      .catch((err) => {
        // form errors
        // if (err.location) setDeviceLocationError(err.location[0]);
        handleNewAlert(`Error Updating User: ${JSON.stringify(err)}`, "error");
      });
  };

  const renderUpdateButton = () => {
    if (userFormChanged) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <Button
            color="warning"
            variant="contained"
            onClick={() => handleUpdateUser(userDetail.id)}
          >
            {t("manageDevices.devices.updateUser.updateUser")}
          </Button>
        </Box>
      );
    } else return <></>;
  };

  const renderUserGroups = () => {
    let groups = [];
    if (selectedUser.groups && selectedUser.groups.length) {
      selectedUser.groups.forEach((group) =>
        groups.push(
          <ListItem key={group} disablePadding>
            <ListItemButton
              onClick={() => {
                setView("userGroups");
              }}
            >
              <ListItemText primary={group} />
            </ListItemButton>
          </ListItem>
        )
      );
    } else {
      groups.push(
        <ListItem key={-1} disabled>
          <ListItemText primary="this user is not a member of any User Groups" />
        </ListItem>
      );
    }
    return groups;
  };

  const renderDrawer = () => (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openUserDrawer}
      onClose={() => setOpenUserDrawer(false)}
      transitionDuration={300}
      sx={{
        width: openUserDrawer ? "500px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: openUserDrawer ? "500px" : 0,
          px: 0,
          py: 1,
          maxWidth: "90vw",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        ></Box>
        <Box
          sx={{
            width: "100%",
            "& .MuiPaper-root": {
              py: 0,
            },
            "& .Mui-expanded": {
              my: 0,
            },
            "& .MuiAccordionDetails-root": {
              py: 0,
            },
            "& .MuiButtonBase-root .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiInputAdornment-root .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiSelect-icon": {
              color: "white",
            },
            "& .MuiListItemText-root": {
              minWidth: "30%",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "rgb(76, 76, 76) !important",
              color: theme.palette.primary.main,
              borderRadius: "5px",
            },
            "& .MuiListItem-root:hover .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiInputLabel-formControl": { color: "white" },
            "& .MuiInput-input": {
              backgroundColor: "transparent",
              color: "white",
              textAlign: "end",
              px: 0,
            },
            "& .MuiSelect-select": { px: 0, pr: "30px !important" },
            "& .MuiFormControl-root": { m: 0 },
            "& .MuiAutocomplete-root": { width: "100%" },
            "& .MuiFormHelperText-root": { color: "red" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              my: 2,
            }}
          >
            <Avatar
              alt={selectedUser.full_name}
              src={selectedUser.photo_url}
              sx={{
                width: "200px",
                height: "200px",
              }}
            />
            <Typography sx={{ fontSize: 24 }}>
              {selectedUser.full_name}
            </Typography>
            {selectedUser.is_org_admin ? (
              <Typography sx={{ fontSize: 14 }}>
                {t(`Is Admin of your Organization`)}
              </Typography>
            ) : null}
            {selectedUser.is_org_member ? (
              <Typography sx={{ fontSize: 14 }}>
                {t(`Is Member of your Organization`)}
              </Typography>
            ) : null}
          </Box>

          <Accordion
            expanded={expanded === "userGroups"}
            onChange={handleAccordionChange("userGroups")}
            sx={{ width: "100%" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h6">
                {t("manageUsers.users.detail.userGroups")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <List sx={{ "& .MuiListItemText-primary": { fontSize: 18 } }}>
                {selectedUser.groups && selectedUser.groups.length
                  ? renderUserGroups()
                  : null}
              </List>
              {/* <Button sx={{ alignSelf: "center", m: 1 }}>
                {t("Add another User Group")}
              </Button> */}

              {renderUpdateButton()}
            </AccordionDetails>
          </Accordion>
        </Box>

        <Button
          variation="outlined"
          color="error"
          onClick={() => setOpenRemoveUserOrganizationModal(true)}
          sx={{ my: 1 }}
        >
          {t("manageUsers.users.detail.removeUserFromOrganization")}
        </Button>
      </Box>
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: openUserDrawer ? "-20px" : "",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          openUserDrawer ? setOpenUserDrawer(false) : setOpenUserDrawer(true);
        }}
      >
        {openUserDrawer ? (
          <Tooltip title="Hide Location Detail">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : null}
      </Button>
      {userDetail ? (
        <RemoveUserOrganizationModal
          openRemoveUserOrganizationModal={openRemoveUserOrganizationModal}
          setOpenRemoveUserOrganizationModal={
            setOpenRemoveUserOrganizationModal
          }
          handleRemoveUserFromOrganization={handleRemoveUserFromOrganization}
        />
      ) : null}
    </Drawer>
  );
  return selectedUser ? renderDrawer() : null;
}
