import React, { useContext, useState } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  color: "text.main",
  boxShadow: 24,
  p: 4,
};

export default function ManageLocationsCreateNewModal({
  locations,
  locationTypes,
  setLocations,
  setSelectedLocation,
  setOpenLocationDrawer,
  handleNewAlert,
  openPopupNewLocation,
  setOpenPopupNewLocation,
}) {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // form states
  const [selectedLocationType, setSelectedLocationType] = useState("");
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);

  // form errors
  const [locationTypeError, setLocationTypeError] = useState(false);
  const [locationNameError, setLocationNameError] = useState(false);
  const [locationParentError, setLocationParentError] = useState(false);

  // form choices
  const locationTypeChoices = () => {
    if (locationTypes) {
      let elements = locationTypes.map((type) => (
        <MenuItem key={type.id} value={type.id}>
          {type.name}
        </MenuItem>
      ));
      return elements;
    }
    return null;
  };

  const resetFormValues = () => {
    setSelectedLocationType("");
    setSelectedLocationName("");
    setSelectedParent(null);
  };

  const resetErrors = () => {
    setLocationTypeError("");
    setLocationNameError("");
    setLocationParentError("");
  };

  const handleCloseModal = () => {
    setOpenPopupNewLocation(false);
    resetErrors();
    resetFormValues();
  };

  const sendRequestNewLocation = () => {
    resetErrors();
    setLoading(true);
    let data = {
      name: selectedLocationName,
      type: selectedLocationType,
      organization: context.organizationService.selectedOrg,
      parent: selectedParent,
    };
    context.locationService
      .createLocation(data)
      .then((response) => {
        setTimeout(() => {
          context.locationService.retrieveLocations().then((locations) => {
            setLocations(locations);
            let new_location = locations.filter(
              (el) => el.id === response.id
            )[0];
            setSelectedLocation(new_location);
            setOpenLocationDrawer(true);
          });
          setLoading(false);
          handleCloseModal();
          handleNewAlert(`Added a new Location: ${response.name}`, "success");
        }, 500);
      })
      .catch((err) => {
        if (err.name) setLocationNameError(err.name[0]);
        if (err.type) setLocationTypeError(err.type[0]);
        if (err.parent) setLocationParentError(err.parent[0]);
        setLoading(false);
        handleNewAlert(
          `Failed to add a new location: ${err.detail ? err.detail : null}`,
          "error"
        );
      });
  };

  return (
    <div>
      <Modal open={openPopupNewLocation} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              width: "500px",
              my: 2,
              maxWidth: "100%",
              "& .MuiInputLabel-formControl": { color: "white" },
              "& .MuiInput-input": {
                backgroundColor: "transparent",
                color: "white",
              },
              "& .MuiSelect-select": { px: 1 },
            }}
          >
            <FormControl
              error={locationNameError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                required
                error={locationNameError ? true : false}
                id="form-input-locationName"
                label={t("manageLocations.locations.create.Location Name")}
                variant="standard"
                // value={selectedLocationName}
                onChange={(event) =>
                  setSelectedLocationName(event.target.value)
                }
              />
              <FormHelperText>
                {locationNameError ? locationNameError : false}
              </FormHelperText>
            </FormControl>
            <FormControl
              error={locationTypeError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <InputLabel required id="form-label-locationType">
                {t("manageLocations.locations.create.Location Group")}
              </InputLabel>
              <Select
                required
                id="form-input-locationType"
                labelId="form-label-locationType"
                value={selectedLocationType}
                onChange={(event) =>
                  setSelectedLocationType(event.target.value)
                }
              >
                {locationTypeChoices()}
              </Select>
              <FormHelperText>
                {locationTypeError ? locationTypeError : false}
              </FormHelperText>
            </FormControl>

            <FormControl
              error={false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <Autocomplete
                id="form-input-parent"
                options={locations ? locations : []}
                // disablePortal={true}
                onChange={(event, value) => {
                  if (value) setSelectedParent(value.id);
                  else {
                    setSelectedParent(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("manageLocations.locations.create.Parent")}
                  />
                )}
                getOptionLabel={(option) => option.name}
                groupBy={(option) => option.type_name}
              ></Autocomplete>
              <FormHelperText>
                {locationParentError ? locationParentError : false}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => sendRequestNewLocation()}
              >
                Create Location
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
