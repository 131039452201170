import React from "react";
import "../../index.css";
import "./sign_in.css";
import photo_placeholder from "../../assets/images/photo_placeholder.jpg";
import { Box, Typography, Button } from "@mui/material";

const Organization = (org, choice) => {
  return (
    <button
      key={org.id}
      className="org-button"
      onClick={() => {
        choice(org.id);
      }}
    >
      <div className="org">
        {org.logo ? (
          <img
            className="org-default-img"
            alt={"image of " + org.name}
            src={org.logo}
          />
        ) : (
          <img
            className="org-default-img"
            alt="default organization"
            src={photo_placeholder}
          />
        )}
        {org.name}
      </div>
    </button>
  );
};

const SelectOrganization = ({ orgs, choice, loading, authService }) => {

  if (loading) {
    /* The JS application is still loading, don't show anything yet. */
    return null;
  }

  if (!orgs) {
    return (
      <div className="org-page">
        <Box
          className="container"
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Typography className="title" variant="h2" m={3}>
            You are not part of any organization
          </Typography>
          <Typography sx={{ color: "white", textAlign: "center", m: 3 }}>
            Please contact your administrator to add you as a member of an
            organization
          </Typography>
          <Button
            variant="contained"
            my={3}
            onClick={() => authService.logout()}
          >
            Log Out
          </Button>
        </Box>
      </div>
    );
  }

  var renderOrganizations = [];
  for (var i = 0; i < orgs.length; i++) {
    renderOrganizations.push(Organization(orgs[i], choice));
  }

  return (
    <div className="org-page">
      <div className="container">
        <h1 className="title">Choose your Organization</h1>
        <div className="orgs-container">{renderOrganizations}</div>
      </div>
    </div>
  );
};

export default SelectOrganization;
