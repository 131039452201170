import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ManageAlertRulesDataGrid from "../../components/data_grids/ManageAlertRulesDataGrid";
import ManageAlertRuleDrawer from "../../components/drawers/ManageAlertRuleDrawer";
import CreateNewAlertRuleModal from "../../components/popups/CreateNewAlertRuleModal";

const ManageAlertsScreen = () => {
  const context = useContext(ServiceContext);
  const location = useLocation();
  const { t } = useTranslation();
  const view = "alertRules";

  const [alertRules, setAlertRules] = useState(null);
  const [selectedAlertRule, setSelectedAlertRule] = useState(false);
  const [openAlertRuleDrawer, setOpenAlertRuleDrawer] = useState(false);
  const [openPopupNewAlertRule, setOpenPopupNewAlertRule] = useState(false);

  useEffect(() => {
    // load alert rules if not yet loaded.
    if (view === "alertRules" && !alertRules) {
      context.alertService.retrieveOrganizationAlertRules().then((response) => {
        if (response.success) {
          setAlertRules(response.data);
        } else {
          console.log("error fetching organization Alert Rules");
        }
      });
    }
  }, [alertRules, context.alertService, view]);

  // Check for possible states from useLocation:
  useEffect(() => {
    // Check if a "selectedDevice" was provided in the useLocation state (this is a clickthrough from another screen)
    if (location && location.state && location.state.selectedAlertRule) {
      setSelectedAlertRule(location.state.selectedAlertRule);
      setOpenAlertRuleDrawer(true);
    }
  }, [location]);

  // alert handling
  let [openSnackbar, setOpenSnackbar] = useState(false);
  let [alertMessage, setAlertMessage] = useState("");
  let [alertSeverity, setAlertSeverity] = useState("success");

  // alert handlers (the message bar shown on the screen, not an "XSAlert Alert")
  const handleCloseAlert = () => {
    setOpenSnackbar(false);
    setAlertMessage("");
  };

  const handleNewAlert = (message, severity) => {
    setOpenSnackbar(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const renderAlertRulesView = () => {
    return (
      <>
        <ManageAlertRulesDataGrid
          alertRules={alertRules}
          setAlertRules={setAlertRules}
          setSelectedAlertRule={setSelectedAlertRule}
          setOpenAlertRuleDrawer={setOpenAlertRuleDrawer}
        />
        <CreateNewAlertRuleModal
          openPopupNewAlertRule={openPopupNewAlertRule}
          setOpenPopupNewAlertRule={setOpenPopupNewAlertRule}
          handleNewAlert={handleNewAlert}
          alertRules={alertRules}
          setAlertRules={(rules) => setAlertRules(() => rules)} // needed for refetching alert rules after creating new one.
          setSelectedAlertRule={setSelectedAlertRule}
          setOpenAlertRuleDrawer={setOpenAlertRuleDrawer}
        />
      </>
    );
  };

  return (
    <Box className="container">
      <Box
        className="page-inner"
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width="calc(100% - 64px)">
          <Box
            m={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mx: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => setOpenPopupNewAlertRule(true)}
              >
                {t("manageAlerts.page.addAlertRule")}
              </Button>
            </Box>
          </Box>
        </Box>
        {renderAlertRulesView()}
      </Box>
      {
        <>
          <ManageAlertRuleDrawer
            openAlertRuleDrawer={openAlertRuleDrawer}
            setOpenAlertRuleDrawer={setOpenAlertRuleDrawer}
            setAlertRules={setAlertRules}
            selectedAlertRule={selectedAlertRule}
            setSelectedAlertRule={setSelectedAlertRule}
            handleNewAlert={handleNewAlert}
          />
        </>
      }

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => handleCloseAlert()}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => handleCloseAlert()}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ManageAlertsScreen;
