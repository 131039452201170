import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ManageDevicesDataGrid from "../../components/data_grids/ManageDevicesDataGrid";
import ManageDeviceTypesDataGrid from "../../components/data_grids/ManageDeviceTypesDataGrid";
import ManageDeviceDrawer from "../../components/drawers/ManageDeviceDrawer";
import CreateNewDeviceModal from "../../components/popups/CreateNewDevice";

const ManageDevicesScreen = () => {
  const context = useContext(ServiceContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [view, setView] = useState("devices");

  const [devices, setDevices] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState(false);
  const [openDeviceDrawer, setOpenDeviceDrawer] = useState(false);
  const [openPopupNewDevice, setOpenPopupNewDevice] = useState(false);

  // fetch initial data
  useEffect(() => {
    if (view === "devices" && !devices) {
      context.deviceService
        .retrieveOrganizationDevices()
        .then((devices) => {
          setDevices(devices);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [devices, context.deviceService, view]);

  useEffect(() => {
    if (!deviceTypes) {
      context.deviceService
        .retrieveDeviceTypes()
        .then((response) => {
          setDeviceTypes(response.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [deviceTypes, context.deviceService]);

  // Check for possible states from useLocation:
  useEffect(() => {
    // Check if a "selectedDevice" was provided in the useLocation state (this is a clickthrough from another screen)
    if (location && location.state && location.state.selectedDevice) {
      setSelectedDevice(location.state.selectedDevice);
      setOpenDeviceDrawer(true);
    }
  }, [location]);

  // alert handlers (the message bar shown on the screen, not an "XSAlert Alert")
  let [openSnackbar, setOpenSnackbar] = useState(false);
  let [alertMessage, setAlertMessage] = useState("");
  let [alertSeverity, setAlertSeverity] = useState("success");

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
    setAlertMessage("");
  };

  const handleNewAlert = (message, severity) => {
    setOpenSnackbar(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const renderDevicesView = () => {
    return (
      <>
        <ManageDevicesDataGrid
          devices={devices}
          setSelectedDevice={setSelectedDevice}
          setOpenDeviceDrawer={setOpenDeviceDrawer}
        />
      </>
    );
  };
  const renderDeviceTypesView = () => {
    return (
      <>
        <ManageDeviceTypesDataGrid deviceTypes={deviceTypes} />
      </>
    );
  };

  return (
    <Box className="container">
      <Box
        className="page-inner"
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width="calc(100% - 64px)">
          <Box
            m={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mx: 0,
            }}
          >
            <Button
              sx={{
                color: view === "devices" ? "primary" : "white",
              }}
              onClick={() => setView("devices")}
            >
              {t("manageDevices.page.devices")}
            </Button>
            <div className="choice">|</div>
            <Button
              sx={{
                color: view === "deviceTypes" ? "primary" : "white",
              }}
              onClick={() => setView("deviceTypes")}
            >
              {t("manageDevices.page.deviceTypes")}
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 1,
                ml: "auto",
              }}
            >
              {view === "devices" ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenPopupNewDevice(true)}
                >
                  {t("manageDevices.page.addDevice")}
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
        {view === "devices" ? renderDevicesView() : renderDeviceTypesView()}
      </Box>
      {view === "devices" ? (
        <>
          <ManageDeviceDrawer
            openDeviceDrawer={openDeviceDrawer}
            setOpenDeviceDrawer={setOpenDeviceDrawer}
            setDevices={setDevices}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            handleNewAlert={handleNewAlert}
          />
          <CreateNewDeviceModal
            setDevices={setDevices}
            setSelectedDevice={setSelectedDevice}
            setOpenDeviceDrawer={setOpenDeviceDrawer}
            handleNewAlert={handleNewAlert}
            openPopupNewDevice={openPopupNewDevice}
            setOpenPopupNewDevice={setOpenPopupNewDevice}
          />
        </>
      ) : (
        <></>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => handleCloseAlert()}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => handleCloseAlert()}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ManageDevicesScreen;
