import React, { useContext, useState, useEffect } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import { Button, Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ManageUsersDataGrid from "../../components/data_grids/ManageUsersDataGrid";
import ManageUserDrawer from "../../components/drawers/ManageUserDrawer";

import ManageUserGroupsDataGrid from "../../components/data_grids/ManageUserGroupsDataGrid";
import ManageUserGroupDrawer from "../../components/drawers/ManageUserGroupDrawer";

import ManageUserInvitationsDataGrid from "../../components/data_grids/ManageUserInvitationsDataGrid";
import InviteUserModal from "../../components/popups/InviteUserModal";
import CreateUserGroupModal from "../../components/popups/CreateUserGroupModal";

const ManageUsersScreen = () => {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [view, setView] = useState("users");
  const [userView, setUserView] = useState("users");

  const [users, setUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [openUserDrawer, setOpenUserDrawer] = useState(false);

  const [invitedUsers, setInvitedUsers] = useState(false);
  const [selectedInvitedUser, setSelectedInvitedUser] = useState(false);
  const [openInvitedUserDrawer, setOpenInvitedUserDrawer] = useState(false);

  const [userGroups, setUserGroups] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState(false);
  const [openUserGroupDrawer, setOpenUserGroupDrawer] = useState(false);

  const [openModalNewUser, setOpenModalNewUser] = useState(false);
  const [openModalNewUserGroup, setOpenModalNewUserGroup] = useState(false);

  // console.log(selectedUserGroup);

  // functions fetcing data
  const refreshUsersList = () => {
    context.userService
      .retrieveOrganizationUsers()
      .then((users) => setUsers(users))
      .catch((err) => console.log(err));
  };

  const refreshUserGroupsList = () => {
    context.userService
      .retrieveOrganizationUserGroups()
      .then((userGroups) => setUserGroups(userGroups))
      .catch((err) => console.log(err));
  };

  const refreshInvitedUsersList = () => {
    context.userService
      .retrieveOrganizationUserInvites()
      .then((users) => setInvitedUsers(users))
      .catch((err) => console.log(err));
  };

  // load initial
  useEffect(() => {
    // fetch users
    if (view === "users" && !users) {
      refreshUsersList();
    }
    if (view === "users" && !invitedUsers) {
      refreshInvitedUsersList();
    }
    if (view === "userGroups" && !userGroups) {
      refreshUserGroupsList();
    }
  }, [
    context.userService,
    users,
    invitedUsers,
    userGroups,
    view,
    refreshUsersList,
    refreshInvitedUsersList,
    refreshUserGroupsList,
  ]);

  // alert handlers (the message bar shown on the screen, not an "XSAlert Alert")
  let [openSnackbar, setOpenSnackbar] = useState(false);
  let [alertMessage, setAlertMessage] = useState("");
  let [alertSeverity, setAlertSeverity] = useState("success");

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
    setAlertMessage("");
  };

  const handleNewAlert = (message, severity) => {
    setOpenSnackbar(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const renderUsersView = () => {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          m={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            sx={{
              color: userView === "users" ? "primary" : "white",
            }}
            onClick={() => setUserView("users")}
          >
            {t("manageUsers.page.allUsers")}
          </Button>
          <div className="choice">|</div>
          <Button
            sx={{
              color: userView === "invitations" ? "primary" : "white",
            }}
            onClick={() => setUserView("invitations")}
          >
            {t("manageUsers.page.pendingInvitations")}
          </Button>
        </Box>
        {userView === "users" ? (
          <>
            <ManageUserDrawer
              openUserDrawer={openUserDrawer}
              setOpenUserDrawer={setOpenUserDrawer}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              setSelectedUserGroup={setSelectedUserGroup}
              setView={setView}
              handleNewAlert={handleNewAlert}
              refreshUsersList={refreshUsersList}
            />
            <ManageUsersDataGrid
              users={users}
              setSelectedUser={setSelectedUser}
              setOpenUserDrawer={setOpenUserDrawer}
            />
          </>
        ) : null}
        {userView === "invitations" ? (
          <ManageUserInvitationsDataGrid
            invitedUsers={invitedUsers}
            setSelectedInvitedUser={setSelectedInvitedUser}
            setOpenInvitedUserDrawer={setOpenInvitedUserDrawer}
          />
        ) : null}
      </Box>
    );
  };

  const renderUserGroupsView = () => {
    return (
      <>
        <ManageUserGroupsDataGrid
          userGroups={userGroups}
          setSelectedUserGroup={setSelectedUserGroup}
          setOpenUserGroupDrawer={setOpenUserGroupDrawer}
        />
        <ManageUserGroupDrawer
          openUserGroupDrawer={openUserGroupDrawer}
          setOpenUserGroupDrawer={setOpenUserGroupDrawer}
          selectedUserGroup={selectedUserGroup}
          setSelectedUserGroup={setSelectedUserGroup}
          handleNewAlert={handleNewAlert}
          refreshUserGroupsList={refreshUserGroupsList}
          refreshUsersList={refreshUsersList}
          users={users}
        />
      </>
    );
  };

  return (
    <Box className="container">
      <Box
        className="page-inner"
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width="calc(100% - 64px)">
          <Box
            m={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mx: 0,
            }}
          >
            <Button
              sx={{
                color: view === "users" ? "primary" : "white",
              }}
              onClick={() => setView("users")}
            >
              {t("manageUsers.page.users")}
            </Button>
            <div className="choice">|</div>
            <Button
              sx={{
                color: view === "userGroups" ? "primary" : "white",
              }}
              onClick={() => setView("userGroups")}
            >
              {t("manageUsers.page.userGroups")}
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 1,
                ml: "auto",
              }}
            >
              {view === "users" ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenModalNewUser(true)}
                >
                  {t("manageUsers.page.inviteUser")}
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenModalNewUserGroup(true)}
                >
                  {t("manageUsers.page.createUserGroup")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {view === "users" ? renderUsersView() : renderUserGroupsView()}
      </Box>
      {view === "users" ? (
        <>
          <InviteUserModal
            handleNewAlert={handleNewAlert}
            openModalNewUser={openModalNewUser}
            setOpenModalNewUser={setOpenModalNewUser}
            refreshInvitedUsersList={refreshInvitedUsersList}
            setUserView={setUserView}
          />
        </>
      ) : (
        <>
          <CreateUserGroupModal
            handleNewAlert={handleNewAlert}
            openModalNewUserGroup={openModalNewUserGroup}
            setOpenModalNewUserGroup={setOpenModalNewUserGroup}
            refreshUserGroupsList={refreshUserGroupsList}
            setUserView={setUserView}
          />
        </>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => handleCloseAlert()}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => handleCloseAlert()}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ManageUsersScreen;
