import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";

const ManageLocationTypesDataGrid = ({
  setSelectedLocationType,
  setOpenLocationTypeDrawer,
  locationTypes,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let columns = [
    {
      field: "location_type_name",
      headerName: t("manageLocations.locationTypes.dataGrid.locationType"),
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.row.name}`;
      },
      flex: 1,
    },
    {
      field: "location_count",
      headerName: t("manageLocations.locationTypes.dataGrid.locationCount"),
      minWidth: 125,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row.location_count}
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: t("Edit"),
      minWidth: 75,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <EditIcon sx={{ width: "20px", height: "20px" }} />
        </Box>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  let rows = [];
  if (locationTypes) rows = locationTypes;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      onRowClick={(params, event) => {
        setSelectedLocationType(params.row);
        setOpenLocationTypeDrawer(true);
      }}
      sx={{
        width: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        // my: 5,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(76, 76, 76) !important",
          cursor: "pointer",
        },
        " & .MuiDataGrid-row:hover .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default ManageLocationTypesDataGrid;
