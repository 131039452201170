import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";
import { DateTime, Interval } from "luxon";

import TimeSince from "../other/Time";

const LocationDetailAlertsDataGrid = ({ locationAlerts }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderDelta = (start, end) => {
    let startDateTime = DateTime.fromISO(start);
    let endDateTime = DateTime.fromISO(end);
    let duration = Interval.fromDateTimes(startDateTime, endDateTime)
      .toDuration(["hours", "minutes", "seconds"])
      .toFormat("hh:mm:ss");
    return duration;
  };

  let columns = [
    {
      field: "message",
      headerName: t("dashboard.locationDetail.dataGrid.message"),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.message}`;
      },
    },
    {
      field: "assignee",
      headerName: t("dashboard.locationDetail.dataGrid.assignedTo"),
      minWidth: 300,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {params.row.assignee ? (
            <>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  mx: 1,
                }}
                alt={params.row.assignee ? params.row.assignee.full_name : ""}
                src={
                  params.row && params.row.assignee && params.row.assignee.photo
                    ? `${params.row.assignee.photo}`
                    : ""
                }
              />
              <Typography sx={{ whiteSpace: "initial" }}>
                {params.row.assignee ? params.row.assignee.full_name : null}
              </Typography>
            </>
          ) : null}
        </Box>
      ),
    },
    {
      field: "assigned",
      headerName: t("dashboard.locationDetail.dataGrid.assignedOn"),
      minWidth: 125,
      renderCell: (params) => {
        return <TimeSince date={params.row.assigned} fontSize={13} />;
      },
    },
    {
      field: "resolved",
      headerName: t("dashboard.locationDetail.dataGrid.resolvedOn"),
      minWidth: 125,
      renderCell: (params) => {
        return <TimeSince date={params.row.resolved} fontSize={13} />;
      },
    },
    {
      field: "delta_time",
      headerName: t("dashboard.locationDetail.dataGrid.deltaTime"),
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row.resolved && params.row.assigned
            ? renderDelta(params.row.assigned, params.row.resolved)
            : null}
        </Box>
      ),
      // renderCell: (params) => (
      //   <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      //     {params.row.resolved && params.row.assigned
      //       ? moment(params.row.resolved)
      //           .subtract(moment(params.row.assigned))
      //           .format("HH:mm:ss")
      //       : null}
      //   </Box>
      // ),
    },
  ];
  let rows = [];
  if (locationAlerts) rows = locationAlerts;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      sx={{
        width: "100%",
        minHeight: 500,
        height: "100%",
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        border: 0,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(76, 76, 76) !important",
        },
        " & .MuiDataGrid-row:hover .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default LocationDetailAlertsDataGrid;
