import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from "@mui/material/Modal";

export function DeleteLocationModal({
  openDeleteLocationModal,
  setOpenDeleteLocationModal,
  selectedLocation,
  handleDeleteLocation,
}) {
  const { t } = useTranslation();
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={openDeleteLocationModal}
        onClose={() => setOpenDeleteLocationModal(false)}
      >
        <Box sx={style}>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {t("manageLocations.locations.delete.warningText")}
          </Typography>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {selectedLocation.type_name}: {selectedLocation.name}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteLocation()}
          >
            {t("manageLocations.locations.detail.Delete Location")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function ManageLocationDrawer({
  selectedLocation,
  setSelectedLocation,
  openDrawer,
  setOpenLocationDrawer,
  handleNewAlert,
  setLocations,
  locationTypes,
  locations,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  let context = useContext(ServiceContext);
  let history = useHistory();

  let [locationDetail, setLocationDetail] = useState(false);
  let [locationDevices, setLocationDevices] = useState(false);
  let [locationAlertRules, setLocationAlertRules] = useState(false);
  let [expanded, setExpanded] = useState("locationDetails");
  let [openDeleteLocationModal, setOpenDeleteLocationModal] = useState(false);

  // fetch initial data

  useEffect(() => {
    // fetch location details
    if (selectedLocation) {
      context.locationService
        .retrieveLocation(selectedLocation.id)
        .then((location) => {
          setLocationDetail(location);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedLocation, context.locationService]);

  useEffect(() => {
    // fetch all devices of this location
    if (locationDetail && locationDetail.devices) {
      let devices = [];
      locationDetail.devices.forEach((device) => {
        devices.push(
          <ListItem
            key={device.id}
            onClick={() => {
              history.push({
                pathname: "/manage/devices",
                state: { selectedDevice: device },
              });
            }}
          >
            <ListItemText
              primary={`${device.device_type_name} - ${device.identifier}`}
            />
            <VisibilityIcon sx={{ width: 20, height: 20, mx: 2 }} />
          </ListItem>
        );
      });
      setLocationDevices(devices);
    }
  }, [locationDetail, selectedLocation, history]);

  useEffect(() => {
    // fetch all alert rules of this location
    if (locationDetail && locationDetail.devices) {
      let alertRules = [];
      locationDetail.devices.forEach((device) => {
        device.alert_rules.forEach((alertRule) => {
          alertRules.push(
            <ListItem
              key={alertRule.id}
              onClick={() => {
                history.push({
                  pathname: "/manage/alerts",
                  state: { selectedAlertRule: alertRule },
                });
              }}
            >
              <ListItemText
                primary={`${alertRule.id}: ${
                  alertRule.get_priority_display
                } priority - ${
                  alertRule.event_types
                    ? `${alertRule.event_types.toString().substring(0, 20)}...`
                    : "All Event Types"
                }`}
              />
              <VisibilityIcon sx={{ width: 20, height: 20, mx: 2 }} />
            </ListItem>
          );
        });
      });
      setLocationAlertRules(alertRules);
    }
  }, [locationDetail, history]);

  const findLocationObject = useCallback(
    (id) => {
      // returns a location object based on a location id
      if (locations) {
        let found = locations.filter((location) => {
          return location.id === id;
        });
        return found[0];
      }
      return null;
    },
    [locations]
  );

  // form selects for Location form
  let [selectedLocationName, setSelectedLocationName] = useState(
    selectedLocation.name
  );
  let [selectedLocationType, setSelectedLocationType] = useState(
    selectedLocation.type
  );
  let [selectedLocationClient, setSelectedLocationClient] = useState(
    selectedLocation.client_name
  );
  let [selectedParent, setSelectedParent] = useState(
    findLocationObject(selectedLocation.parent)
  );


  useEffect(() => {
    // reset form selects when new location is selected
    if (selectedLocation) {
      setSelectedLocationName(selectedLocation.name);
      setSelectedLocationType(selectedLocation.type);
      setSelectedLocationClient(selectedLocation.client_name);
      setSelectedParent(findLocationObject(selectedLocation.parent));
      setLocationFormChanged(false);
    }
  }, [selectedLocation, findLocationObject]);

  // form errors
  let [locationTypeError, setLocationTypeError] = useState(false);
  let [locationNameError, setLocationNameError] = useState(false);
  let [locationClientError, setLocationClientError] = useState(false);
  let [locationParentError, setLocationParentError] = useState(false);
  // let [locationStreamError, setLocationStreamError] = useState(false);

  const resetErrors = () => {
    setLocationTypeError("");
    setLocationNameError("");
    setLocationClientError("");
    setLocationParentError("");
    // setLocationStreamError("");
  };

  // form choices
  let [locationFormChanged, setLocationFormChanged] = useState(false);
  let [locationTypeChoices, setLocationTypeChoices] = useState(null);

  let [streamChoices, setStreamChoices] = useState(null);

  useEffect(() => {
    // location type choices
    if (locationTypes) {
      let elements = locationTypes.map((type) => (
        <MenuItem key={type.id} value={type.id} sx={{ justifyContent: "end" }}>
          {type.name}
        </MenuItem>
      ));
      setLocationTypeChoices(elements);
    } else {
      setLocationTypeChoices("");
    }
  }, [locationTypes]);

  useEffect(() => {
    if (!streamChoices) {
      console.log("fetching streams for streamChoices");
      context.alertService.retrieveOrganizationStreams().then((response) => {
        if (response.success) {
          let streams = response.data.map((stream) => (
            <MenuItem
              key={stream.id}
              value={stream.stream}
              sx={{ justifyContent: "end", textAlign: "end" }}
            >
              {stream.stream}
            </MenuItem>
          ));
          setStreamChoices(streams);
        }
      });
    }
  }, [selectedLocation, context.alertService, streamChoices]);

  useEffect(() => {
    // set Location Form Changed if any form element has been updated by the user
    if (
      selectedLocationName !== selectedLocation.name ||
      selectedLocationType !== selectedLocation.type ||
      selectedLocationClient !== selectedLocation.client_name ||
      (selectedParent && selectedParent.id !== selectedLocation.parent)
    ) {
      setLocationFormChanged(true);
      resetErrors();
    } else setLocationFormChanged(false);
  }, [
    selectedLocationName,
    selectedLocationType,
    selectedLocationClient,
    selectedParent,
    selectedLocation,
  ]);


  // handlers
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDeleteLocation = () => {
    context.locationService
      .deleteLocation(selectedLocation.id)
      .then((response) => {
        context.locationService.retrieveLocations().then((locations) => {
          setLocations(locations);
        });
        handleNewAlert(
          `Successfully deleted Location "${selectedLocation.name}"`,
          "success"
        );
        setOpenDeleteLocationModal(false);
        setOpenLocationDrawer(false);
      })
      .catch((err) => {
        handleNewAlert(`Error: ${JSON.stringify(err)}`, "error");
        setOpenDeleteLocationModal(false);
        setOpenLocationDrawer(false);
      });
  };
  const handleUpdateLocation = (id) => {
    let data = {
      name: selectedLocationName,
      parent: selectedParent ? selectedParent.id : null,
      type: selectedLocationType,
      client_name: selectedLocationClient,
    };
    context.locationService
      .updateLocation(id, data)
      .then((response) => {
        context.locationService.retrieveLocations().then((locations) => {
          setLocations(locations);
        });
        handleNewAlert(
          `Successfully updated Location "${selectedLocationName}"`,
          "success"
        );
        setSelectedLocation(response);
      })
      .catch((err) => {
        if (err.name) {
          setLocationNameError(err.name[0]);
        }
        if (err.type) {
          setLocationTypeError(err.type[0]);
        }
        if (err.client_name) {
          setLocationClientError(err.client_name[0]);
        }
        if (err.parent) {
          setLocationParentError(err.parent[0]);
        }
        err = JSON.stringify(err);
        handleNewAlert(`Error: ${err}`, "error");
      });
  };

  // renders
  const renderUpdateButton = () => {
    if (locationFormChanged) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <Button
            color="warning"
            variant="contained"
            onClick={() => handleUpdateLocation(selectedLocation.id)}
          >
            {t("manageLocations.locations.detail.Update Location")}
          </Button>
        </Box>
      );
    } else return <></>;
  };

  const renderDrawer = () => (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      onClose={() => setOpenLocationDrawer(false)}
      transitionDuration={300}
      sx={{
        width: openDrawer ? "500px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: openDrawer ? "500px" : 0,
          px: 0,
          py: 1,
          maxWidth: "90vw",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          <Avatar
            alt={selectedLocation.name}
            src={selectedLocation.photo ? selectedLocation.photo : null}
            sx={{ width: 100, height: 100, m: 4 }}
          >
            <LocationOnIcon sx={{ width: "70%", height: "70%" }} />
          </Avatar>
        </Box>
        <Box
          sx={{
            "& .MuiPaper-root": {
              py: 0,
            },
            "& .Mui-expanded": {
              my: 0,
            },
            "& .MuiAccordionDetails-root": {
              py: 0,
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiListItemText-root": {
              minWidth: "30%",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "rgb(76, 76, 76) !important",
              color: theme.palette.primary.main,
            },
            "& .MuiListItem-root:hover .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiInputLabel-formControl": { color: "white" },
            "& .MuiInput-input": {
              backgroundColor: "transparent",
              color: "white",
              textAlign: "end",
              px: 0,
            },
            "& .MuiSelect-select": { px: 0, pr: "30px !important" },
            "& .MuiFormControl-root": { m: 0 },
            "& .MuiAutocomplete-root": { width: "100%" },
            "& .MuiFormHelperText-root": { color: "red" },
          }}
        >
          <Accordion
            expanded={expanded === "locationDetails"}
            onChange={handleAccordionChange("locationDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h6">
                {t("manageLocations.locations.detail.Location")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("manageLocations.locations.detail.Organization")}
                  />
                  <ListItemText
                    sx={{ textAlign: "end", mr: 2, pr: "16px" }}
                    primary={selectedLocation.organization_name}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageLocations.locations.detail.Name")}
                  />
                  <FormControl sx={{ flexGrow: 1 }}>
                    <TextField
                      variant="standard"
                      value={selectedLocationName ? selectedLocationName : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon sx={{ width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) =>
                        setSelectedLocationName(event.target.value)
                      }
                    />
                    <FormHelperText>
                      {locationNameError ? locationNameError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t(
                      "manageLocations.locations.detail.Location Group"
                    )}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <Select
                      required
                      id="form-input-locationType"
                      value={
                        selectedLocationType
                          ? selectedLocationType.toString()
                          : ""
                      }
                      onChange={(event) =>
                        setSelectedLocationType(event.target.value)
                      }
                    >
                      {locationTypeChoices}
                    </Select>
                    <FormHelperText>
                      {locationTypeError ? locationTypeError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageLocations.locations.detail.Client")}
                  />
                  <FormControl sx={{ flexGrow: 1 }}>
                    <TextField
                      variant="standard"
                      value={
                        selectedLocationClient ? selectedLocationClient : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EditIcon sx={{ width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) =>
                        setSelectedLocationClient(event.target.value)
                      }
                    />
                    <FormHelperText>
                      {locationClientError ? locationClientError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem
                  sx={{
                    "& .MuiInputBase-root": { pr: "36px !important" },
                  }}
                >
                  <ListItemText
                    primary={t("manageLocations.locations.detail.Child of")}
                  />
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                      flexDirection: "column",
                    }}
                  >
                    <Autocomplete
                      id="form-input-parent"
                      options={locations ? locations : []}
                      // disablePortal={true}
                      value={selectedParent ? selectedParent : ""}
                      isOptionEqualToValue={(option, value) => {
                        return value && value.id
                          ? value.id === option.id
                          : true;
                      }}
                      onChange={(event, value) => {
                        if (value) setSelectedParent(value);
                        else {
                          setSelectedParent(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{ pr: "0px !important" }}
                        />
                      )}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      groupBy={(option) => option.type_name}
                    />
                    <FormHelperText>
                      {locationParentError ? locationParentError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageLocations.locations.detail.Children")}
                  />
                  <ListItemText
                    sx={{ textAlign: "end" }}
                    primary={selectedLocation.children_count}
                  />
                  <VisibilityIcon sx={{ width: 20, height: 20, pl: 2 }} />
                </ListItem>
              </List>
              {renderUpdateButton()}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "devicesDetails"}
            onChange={handleAccordionChange("devicesDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ py: 0, my: 0 }}
            >
              <Typography variant="h6">
                {t("manageLocations.locations.detail.devices")} (
                {locationDevices.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {locationDevices && locationDevices.length ? (
                  locationDevices
                ) : (
                  <ListItem sx={{ justifyContent: "center" }}>
                    <Typography textAlign="center">
                      {t("manageLocations.locations.detail.noDevices")}
                    </Typography>
                  </ListItem>
                )}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "alertDetails"}
            onChange={handleAccordionChange("alertDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ py: 0, my: 0 }}
            >
              <Typography variant="h6">
                {t("manageLocations.locations.detail.alertRules")} (
                {locationAlertRules.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {locationAlertRules && locationAlertRules.length ? (
                  locationAlertRules
                ) : (
                  <Typography
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {t("manageLocations.locations.detail.noAlertRules")}
                  </Typography>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "userDetails"}
            onChange={handleAccordionChange("userDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ py: 0, my: 0 }}
            >
              <Typography variant="h6">
                {t("manageLocations.locations.detail.Users - Placeholder")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText primary="# Accessible Users" />
                  <ListItemText sx={{ textAlign: "end" }} primary="20" />
                  <EditIcon sx={{ width: 20, height: 20, mx: 2 }} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="User Groups" />
                  <ListItemText
                    sx={{ textAlign: "end" }}
                    primary="[Nachtdienst Team 1, OchtendDienst Team 3]"
                  />
                  <EditIcon sx={{ width: 20, height: 20, mx: 2 }} />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Button
          variation="outlined"
          color="error"
          onClick={() => setOpenDeleteLocationModal(true)}
          sx={{ my: 1 }}
        >
          {t("manageLocations.locations.detail.Delete Location")}
        </Button>
      </Box>
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: openDrawer ? "-20px" : "",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          openDrawer
            ? setOpenLocationDrawer(false)
            : setOpenLocationDrawer(true);
        }}
      >
        {openDrawer ? (
          <Tooltip title="Hide Location Detail">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : null}
      </Button>
      <DeleteLocationModal
        openDeleteLocationModal={openDeleteLocationModal}
        setOpenDeleteLocationModal={setOpenDeleteLocationModal}
        selectedLocation={selectedLocation}
        handleDeleteLocation={handleDeleteLocation}
      />
    </Drawer>
  );
  return selectedLocation ? renderDrawer() : null;
}
