import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./activenurses.css";
import { ServiceContext } from "../../context/ServiceContext";
import WebSocketClient from "../../websocket/WebSocketClient";

function ActiveNurses(props) {
  const { nurseService } = useContext(ServiceContext);
  const [nurses, setNurses] = useState([]);

  const onNurseUpdate = useCallback(
    (update) => {
      let cur_idx = nurses.findIndex((nurse) => nurse.id === update.id);
      if (cur_idx !== -1) {
        let nurses_cpy = [...nurses];
        nurses_cpy[cur_idx].notifications = update.notifications;
        setNurses(nurses_cpy);
      }
    },
    [nurses]
  );

  useLayoutEffect(() => {
    WebSocketClient.registerMessageCallbacks({
      user: { id: "ActiveNurses", callback: onNurseUpdate },
    });
    return () => WebSocketClient.deregisterMessageCallbacks("ActiveNurses");
  }, [onNurseUpdate]);

  const renderNurse = (nurse) => {
    return (
      <div className="nurse-entry" key={`nurse${nurse.id}`}>
        <div
          className={"active"}
          style={{
            backgroundColor: nurse.notifications ? "#0ADE0A" : "#DE0A0A",
          }}
        ></div>
        <div className="name unselectable">{nurse.full_name}</div>
      </div>
    );
  };

  useEffect(() => {
    nurseService
      .retrieveUsers()
      .then((nurseList) => setNurses(nurseList))
      .catch();
  }, [nurseService]);

  let nurseList = nurses;
  nurseList = nurseList.sort((a, b) => b.notifications - a.notifications);
  nurseList = nurseList.map((nurse) => renderNurse(nurse));

  return (
    <div className="nurse-container">
      <div className="icon">
        <div className="nurse-icon"></div>
      </div>
      {nurseList}
    </div>
  );
}

export default ActiveNurses;
