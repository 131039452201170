import React, { useState, useContext } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import SelectLanguage from "../internationalization/SelectLanguage";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PeopleIcon from "@mui/icons-material/People";
import CameraOutdoorIcon from "@mui/icons-material/CameraOutdoor";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddAlertIcon from "@mui/icons-material/AddAlert";

import "./nav.css";
import "../../index.css";

function XSAlertAppBar() {
  const context = useContext(ServiceContext);
  const authService = context.authService;
  const organizationService = context.organizationService;
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();

  const [state, setState] = useState({
    drawer: false,
    showDropdown: false,
    refresh: false,
    anchorElUser: null,
    anchorElNav: null,
  });

  let [manageCollapseOpen, setManageCollapseOpen] = useState(false);

  const user_name = authService.getUserName();
  const user_mail = authService.getUserEmail();
  const selected_org = organizationService.getChosenOrganizationDetails();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const logout = () => {
    /* Called when the user clicks on logout */
    authService.logout().then().catch();
  };

  const list = (anchor) => {
    return (
      <Box
        sx={{ width: "auto" }}
        role="presentation"
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {/* <ListItemButton onClick={() => window.location.href="#dashboard"}>
          <Typography>Current Location: .</Typography>
          <ListItemText primary={location.pathname}/>
        </ListItemButton> */}

          <Toolbar />
          <ListItemButton onClick={() => history.push("/dashboard")}>
            <ListItemIcon>
              <BarChartIcon
                color={location.pathname === "/dashboard" ? "primary" : "text"}
              />
            </ListItemIcon>
            <ListItemText
              onClick={toggleDrawer(anchor, false)}
              primary={t("menuDrawer.Dashboard")}
              sx={{
                color:
                  location.pathname === "/dashboard" ? "primary.main" : "text",
              }}
            />
          </ListItemButton>

          <Divider sx={{ margin: "10px", borderColor: "white" }} />

          <ListItem sx={{ cursor: "pointer" }}>
            <ListItemIcon>
              <AccountBalanceIcon
                color={location.pathname === "/manage" ? "primary" : "text"}
              />
            </ListItemIcon>
            <ListItemText
              primary={t("menuDrawer.Management")}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setManageCollapseOpen(manageCollapseOpen ? false : true);
                setState({ drawer: true });
              }}
            />
            {manageCollapseOpen ? (
              <Button sx={{ minWidth: "auto", borderRadius: "50%" }}>
                <KeyboardArrowDownIcon
                  onClick={() => setManageCollapseOpen(false)}
                />
              </Button>
            ) : (
              <Button
                sx={{ minWidth: "auto", borderRadius: "50%", color: "white" }}
              >
                <KeyboardArrowRightIcon
                  onClick={() => setManageCollapseOpen(true)}
                />
              </Button>
            )}
          </ListItem>

          <Collapse in={manageCollapseOpen} timeout="auto" unmountOnExit>
            <List sx={{ marginLeft: 5 }} component="div" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <LocationOnIcon
                    color={
                      location.pathname === "/manage/locations"
                        ? "primary"
                        : "text"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    history.push("/manage/locations");
                    setState({ drawer: false });
                  }}
                  sx={{
                    color:
                      location.pathname === "/manage/locations"
                        ? "primary.main"
                        : "text",
                  }}
                >
                  {t("menuDrawer.Locations")}
                </ListItemText>
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <CameraOutdoorIcon
                    color={
                      location.pathname === "/manage/devices"
                        ? "primary"
                        : "text"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    history.push("/manage/devices");
                    setState({ drawer: false });
                  }}
                  sx={{
                    color:
                      location.pathname === "/manage/devices"
                        ? "primary.main"
                        : "text",
                  }}
                >
                  {t("menuDrawer.Devices")}
                </ListItemText>
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <AddAlertIcon
                    color={
                      location.pathname === "/manage/alerts"
                        ? "primary"
                        : "text"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    history.push("/manage/alerts");
                    setState({ drawer: false });
                  }}
                  sx={{
                    color:
                      location.pathname === "/manage/alerts"
                        ? "primary.main"
                        : "text",
                  }}
                >
                  {t("menuDrawer.Alerts")}
                </ListItemText>
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <PeopleIcon
                    color={
                      location.pathname === "/manage/users" ? "primary" : "text"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    history.push("/manage/users");
                    setState({ drawer: false });
                  }}
                  sx={{
                    color:
                      location.pathname === "/manage/users"
                        ? "primary.main"
                        : "text",
                  }}
                >
                  {t("menuDrawer.Users")}
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      <AppBar
        position="fixed"
        color="background"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            onClick={toggleDrawer("drawer", true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/" className="logo" style={{ flexGrow: 1 }}>
            &nbsp;
          </Link>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Account Settings">
              <IconButton
                onClick={(event) =>
                  setState({ anchorElUser: event.currentTarget })
                }
                sx={{ p: 0 }}
              >
                <Avatar alt={user_name} src={authService.getProfileIcon()} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={state.anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(state.anchorElUser)}
              onClose={(event) => setState({ anchorElUser: null })}
            >
              <MenuItem>
                <SelectLanguage />
              </MenuItem>

              <Divider />

              <MenuItem sx={{ display: "flex", justifyContent: "center" }}>
                <Box className="info">
                  <Typography className="name">{user_name}</Typography>
                  <Typography variant="caption" className="mail">
                    {user_mail}
                  </Typography>
                </Box>
              </MenuItem>

              <Divider />

              <MenuItem>
                <Box
                  className="organization"
                  onClick={() =>
                    context.organizationService.setChosenOrganization(-1)
                  }
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    alt={
                      selected_org && selected_org.name
                        ? selected_org.name
                        : null
                    }
                    src={
                      selected_org && selected_org.logo
                        ? selected_org.logo
                        : null
                    }
                  />
                  <Typography className="name">{selected_org.name}</Typography>
                </Box>
              </MenuItem>

              <Divider />

              <MenuItem sx={{ display: "flex", justifyContent: "center" }}>
                <Box className="sign-out clickable" onClick={() => logout()}>
                  <Typography>{t("profileDrawer.Log Out")}</Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={state["drawer"]}
        onClose={toggleDrawer("drawer", false)}
        variant="temporary"
        sx={{
          width: 250,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 250, boxSizing: "border-box" },
        }}
      >
        {list("drawer")}
      </Drawer>
    </Box>
  );
}

export default XSAlertAppBar;
