import React, { useEffect, useState } from "react";
import LocationDetails from "./LocationDetails";
import "./popup.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  display: "flex",
  flexDirection: "column",
  minHeight: "80%",
  width: "90%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Popup = ({ locationId, onExit, visible }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    onExit();
  };

  useEffect(() => {
    if (visible) {
      setOpen(true);
    }
  }, [visible]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="location-detail-modal"
    >
      <Box sx={style}>
        <Box
          className="top"
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CloseIcon onClick={handleClose} />
        </Box>
        <Box
          className="middle"
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <LocationDetails locationId={locationId} />
        </Box>
      </Box>
    </Modal>
  );
};

export default Popup;
