import { createTheme } from "@mui/material/styles";

export const primaryTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "rgb(10, 222, 10)",
    },
    secondary: {
      main: "#f50057",
    },
    text: {
      main: "#fff",
      primary: "#fff",
    },
    background: {
      default: "#181818",
      paper: "rgba(39, 40, 41, 1.0)",
      appbar: "#181818",
      border: "rgba(255, 255, 255, 0.12)",
      hover: "rgb(76, 76, 76)",
      grey: "rgba(39, 40, 41)",
    },
  },
  typography: {
    type: "dark",
    fontFamily: [
      "Open Sans",
      "sans - serif",
      "-apple - system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans - serif",
    ].join(","),
    body1: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: 14,
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
  components: {
    DataGrid: {
      defaultProps: {
        backgroundColor: "#000000",
      },
    },
    MuiSelect: {},
  },
});
