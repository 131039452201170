import { useState, useEffect, useCallback } from "react";
import { DateTime, Interval } from "luxon";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function TimeSince({ date, fontSize }) {
  const { t } = useTranslation();
  let [timeSince, setTimeSince] = useState(false);
  let dateTime = DateTime.fromISO(date);

  const calcTimeSince = useCallback(
    (now) => {
      let interval = Interval.fromDateTimes(dateTime, now);
      let seconds = interval.toDuration("seconds").toObject().seconds;
      let minutes = interval.toDuration("minutes").toObject().minutes;
      let hours = interval.toDuration("hours").toObject().hours;
      let days = interval.toDuration("days").toObject().days;
      if (minutes < 1) {
        if (seconds < 30) {
          setTimeSince(t(`time.timeSince.justNow`));
        } else {
          setTimeSince(t(`time.timeSince.underMinute`));
        }
      } else if (hours < 1) {
        setTimeSince(
          `${Math.floor(minutes)} ${
            minutes < 2
              ? t("time.timeSince.minute")
              : t("time.timeSince.minutes")
          } ${t("time.timeSince.ago")} `
        );
      } else if (days < 1) {
        setTimeSince(
          `${Math.floor(hours)} ${
            hours < 2 ? t("time.timeSince.hour") : t("time.timeSince.hours")
          } ${t("time.timeSince.ago")} `
        );
      } else {
        setTimeSince(
          `${Math.floor(days)} ${
            days < 2 ? t("time.timeSince.day") : t("time.timeSince.days")
          } ${t("time.timeSince.ago")} `
        );
      }
      return;
    },
    [dateTime, t]
  );

  useEffect(() => {
    // runs every 30 seconds
    if (!timeSince) {
      calcTimeSince(DateTime.now());
    }
    const interval2 = setInterval(() => {
      calcTimeSince(DateTime.now());
    }, 30000);
    return () => clearInterval(interval2); // cleanup
  }, [calcTimeSince, timeSince]);

  if (!date) return null;
  return (
    <Typography fontSize={fontSize ? fontSize : 12}>{timeSince}</Typography>
  );
}

export default TimeSince;
