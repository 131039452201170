import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

const ManageDeviceTypesDataGrid = ({ deviceTypes }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let columns = [
    {
      field: "brand",
      headerName: t("manageDevices.devices.dataGrid.brand"),
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>{params.row.brand}</Box>
      ),
      valueGetter: (params) => {
        return `${params.row.brand}`;
      },
    },
    {
      field: "device_type",
      headerName: t("manageDevices.devices.dataGrid.device"),
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>{params.row.name}</Box>
      ),
      valueGetter: (params) => {
        return `${params.row.name}`;
      },
      flex: 1,
    },
  ];
  let rows = [];
  if (deviceTypes) rows = deviceTypes;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      sx={{
        flexGrow: 1,
        width: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          // backgroundColor: "rgb(76, 76, 76) !important",
          // cursor: "pointer",
        },
        " & .MuiDataGrid-row:hover .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default ManageDeviceTypesDataGrid;
