import React from 'react';
import { Redirect } from 'react-router-dom';

/* This page can be used as a detour to refresh all data.
   When the user changes the selected organization, they will be redirected here.
   They then get automatically redirected back to the view that triggered the redirect.
*/

function Refresh(props) {
    return (
        <Redirect to={props.location.state.origin}/>
    );
}

export default Refresh;