class DeviceService {
  constructor(apiService, organizationService) {
    this.apiService = apiService;
    this.organizationService = organizationService;
    this.devices = [];
  }

  async retrieveOrganizationDevices() {
    /* Passes a reference to the list of logs, tries to retrieve logs if list is empty */
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationDevices();
      if (response.success) {
        this.devices = response.data.results;
        return this.devices;
      }
    }
    return this.devices;
  }

  async retrieveLocationDevices(location_id, limit, offset) {
    /* Returns the alerts for a specific location or an empty list.*/
    if (this.apiService) {
      const response = await this.apiService.retrieveLocationDevices(
        location_id,
        limit,
        offset
      );
      if (response.success) {
        return response.data.results;
      }
    }
    return [];
  }

  async retrieveDeviceTypes() {
    if (this.apiService) {
      const response = await this.apiService.retrieveDeviceTypes();
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async retrieveDevice(id) {
    if (this.apiService) {
      const response = await this.apiService.retrieveDevice(id);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async createDevice(data) {
    if (this.apiService) {
      const response = await this.apiService.createDevice(data);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async updateDevice(id, data) {
    if (this.apiService) {
      const response = await this.apiService.updateDevice(id, data);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async deleteDevice(id) {
    if (this.apiService) {
      const response = await this.apiService.deleteDevice(id);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }
}
export default DeviceService;
