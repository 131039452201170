import React, { useContext, useState } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  color: "text.main",
  boxShadow: 24,
  p: 4,
};

export default function CreateNewLocationTypeModal({
  setLocationTypes,
  setSelectedLocationType,
  setOpenLocationTypeDrawer,
  handleNewAlert,
  openPopupNewLocationType,
  setOpenPopupNewLocationType,
}) {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // form states
  const [selectedLocationTypeName, setSelectedLocationTypeName] = useState("");

  // form errors
  const [locationTypeNameError, setlocationTypeNameError] = useState(false);

  const resetFormValues = () => {
    setSelectedLocationTypeName("");
  };

  const resetErrors = () => {
    setlocationTypeNameError("");
  };

  const handleCloseModal = () => {
    setOpenPopupNewLocationType(false);
    resetErrors();
    resetFormValues();
  };

  const sendRequestNewLocationType = () => {
    resetErrors();
    setLoading(true);
    let data = {
      name: selectedLocationTypeName,
      organization: context.organizationService.selectedOrg,
    };
    context.locationService
      .createLocationType(data)
      .then((response) => {
        setTimeout(() => {
          context.locationService
            .retrieveLocationTypes()
            .then((locationTypes) => {
              setLocationTypes(locationTypes);
              setSelectedLocationType(response);
              setOpenLocationTypeDrawer(true);
            });
          setLoading(false);
          handleCloseModal();
          handleNewAlert(
            `Added a new Location Group: ${response.name}`,
            "success"
          );
        }, 500);
      })
      .catch((err) => {
        if (err.name) setlocationTypeNameError(err.name[0]);
        setLoading(false);
        handleNewAlert(
          `Failed to add a new Location Group: ${
            err.detail ? err.detail : null
          }`,
          "error"
        );
      });
  };

  return (
    <div>
      <Modal open={openPopupNewLocationType} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              width: "500px",
              my: 2,
              maxWidth: "100%",
              "& .MuiInputLabel-formControl": { color: "white" },
              "& .MuiInput-input": {
                backgroundColor: "transparent",
                color: "white",
              },
              "& .MuiSelect-select": { px: 1 },
              "& 	.MuiAutocomplete-groupLabel": {
                backgroundColor: "red !important",
              },
            }}
          >
            <FormControl
              error={locationTypeNameError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                required
                error={locationTypeNameError ? true : false}
                id="form-input-locationName"
                label={t("manageLocations.locations.create.Location Name")}
                variant="standard"
                // value={selectedLocationTypeName}
                onChange={(event) =>
                  setSelectedLocationTypeName(event.target.value)
                }
              />
              <FormHelperText>
                {locationTypeNameError ? locationTypeNameError : false}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => sendRequestNewLocationType()}
              >
                Create Location Group
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
