import { getUTCDayStart, getUTCDayEnd } from "../util/util";

class AlertService {
  constructor(apiService, organizationService) {
    this.apiService = apiService;
    this.organizationService = organizationService;
    this.alerts = [];
    this.alertLogs = [];
  }

  async retrieveOrganizationAlerts() {
    /* Passes a reference to the list of logs, tries to retrieve logs if list is empty */
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationAlerts();
      if (response.success) {
        this.alerts = response.data.results;
        return this.alerts;
      }
    }
    return this.alerts;
  }

  async retrieveOrganizationAlertLogs() {
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationAlertLogs();
      if (response.success) {
        this.alertLogs = response.data.results;
      }
    }
    return this.alertLogs;
  }

  async retrieveLocationAlerts(location_id, limit, offset) {
    /* Returns the alerts for a specific location or an empty list.*/
    if (this.apiService) {
      const response = await this.apiService.retrieveLocationAlerts(
        location_id,
        limit,
        offset
      );
      if (response.success) {
        return response.data.results;
      }
    }
    return [];
  }

  async retrieveOrganizationAlertRules() {
    // Returns all alert rules of an organization or an empty list
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationAlertRules();
      if (response.success) {
        return { success: true, data: response.data.results };
      }
      return { success: false, data: [] };
    }
  }

  async createAlertRule(data) {
    if (this.apiService) {
      const response = await this.apiService.createAlertRule(data);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async updateAlertRule(id, data) {
    if (this.apiService) {
      const response = await this.apiService.updateAlertRule(id, data);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async deleteAlertRule(id) {
    if (this.apiService) {
      const response = await this.apiService.deleteAlertRule(id);
      if (response.success) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async retrieveOrganizationStreams() {
    // retrieves all streams as part of an organization or an empty list
    const response = await this.apiService.retrieveOrganizationStreams();
    if (response.success) {
      return { success: true, data: response.data.results };
    }
    return { success: false, data: [] };
  }

  async retrieveStream(streamName) {
    const response = await this.apiService.retrieveStream(streamName);
    if (response.success) {
      return { success: true, data: response.data.results[0] };
    }
    return { success: false, data: [] };
  }

  async retrievePriorityChoices() {
    const response = await this.apiService.retrieveAlertPriorityChoices();
    if (response.success) {
      return { success: true, data: JSON.parse(response.data) };
    }
    return { success: false, data: [] };
  }

  async retrieveEventTypeChoices() {
    const response = await this.apiService.retrieveEventTypeChoices();
    if (response.success) {
      return { success: true, data: JSON.parse(response.data) };
    }
    return { success: false, data: [] };
  }

  async retrieveLatestLogs() {
    /* Retrieves only the 5 latest alerts */
    const response = await this.apiService.retrieveLatestLogs();
    if (response.success) {
      return { success: true, alerts: response.data.results };
    }
    return { success: false, alerts: [] };
  }

  async retrieveLogsByDate(date, limit, offset) {
    const dt_from = getUTCDayStart(date);
    const dt_to = getUTCDayEnd(date);
    return await this.apiService.retrieveLogsByDate(
      dt_from,
      dt_to,
      limit,
      offset
    );
  }

  async poll() {
    /* Retrieves alerts from the API */
    const response = await this.apiService.retrieveLogs();
    if (response.success) {
      this.alerts = response.data.results;
    }
  }
}
export default AlertService;
