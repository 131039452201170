class UserService {
  constructor(apiService) {
    this.apiService = apiService;
    this.users = [];
    this.userGroups = [];
    this.userInvites = [];
  }

  async retrieveUsers() {
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationUsers();
      if (response.success) {
        this.users = response.data.results;
        return this.users;
      }
    }
    return;
  }

  async retrieveUser(user_id) {
    if (this.apiService) {
      const response = await this.apiService.retrieveUser(user_id);
      if (response.success) {
        return response.data;
      }
      throw await response.data.json();
    }
  }

  async retrieveOrganizationUsers() {
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationUsersAll();
      if (response.success) {
        this.users = response.data.results;
        return this.users;
      }
      throw await response.data.json();
    }
    return;
  }

  async retrieveOrganizationUserGroups() {
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationUserGroups();
      if (response.success) {
        this.userGroups = response.data.results;
        return this.userGroups;
      }
      throw await response.data.json();
    }
  }

  async retrieveUserGroup(id) {
    if (this.apiService) {
      const response = await this.apiService.retrieveUserGroup(id);
      if (response.success) return response.data;
      throw await response.data.json();
    }
  }

  async updateUserGroup(id, data) {
    if (this.apiService) {
      const response = await this.apiService.updateUserGroup(id, data);
      if (response.success) return response.data;
      throw await response.data.json();
    }
  }

  async removeUserGroup(id) {
    if (this.apiService) {
      const response = await this.apiService.removeUserGroup(id);
      if (response.success) return response.data;
      throw await response.data.json();
    }
  }

  async createUserGroup(data) {
    if (this.apiService) {
      const response = await this.apiService.createUserGroup(data);
      if (response.success) return response.data;
      throw await response.data.json();
    }
  }

  async addUserToUserGroup(user_id, userGroup_id) {
    if (this.apiService) {
      const response = await this.apiService.addUserToUserGroup(
        user_id,
        userGroup_id
      );
      if (response.success) return response.data;
      throw await response.data.json();
    }
  }

  async removeUserFromUserGroup(user_id, userGroup_id) {
    const response = await this.apiService.removeUserFromUserGroup(
      user_id,
      userGroup_id
    );
    if (response.success) return response.data;
    throw await response.data.json();
  }

  async retrieveOrganizationUserInvites() {
    if (this.apiService) {
      const response = await this.apiService.retrieveOrganizationUserInvites();
      if (response.success) {
        this.userInvites = response.data.results;
        return this.userInvites;
      }
      throw await response.data.json();
    }
    return;
  }

  async checkUserInOrganization(email) {
    if (this.apiService) {
      const response = await this.apiService.checkUserInOrganization(email);
      if (response.success) {
        return response.data.results;
      } else {
        throw await response.data.json();
      }
    }
  }

  async inviteUserToOrganization(data) {
    if (this.apiService) {
      const response = await this.apiService.inviteUserToOrganization(data);
      if (response) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  async removeUserFromOrganization(user_id) {
    if (this.apiService) {
      const response = await this.apiService.removeUserFromOrganization(
        user_id
      );
      if (response) {
        return response.data;
      } else {
        throw await response.data.json();
      }
    }
  }

  hasPicture(user) {
    /* Returns if the user has a picture */
    return user.avatar ? true : false;
  }

  getFullName(user) {
    /* Constructs the full name of the supplied user */
    return `${user.first_name} ${user.last_name}`;
  }

  getInitials(user) {
    /* Constructs the user initials */
    var last_name = user.last_name.replace(/\s/g, "");
    /* Find first capital letter in last name */
    for (var i = 0; i < last_name.length; i++) {
      if (last_name[i] === last_name[i].toUpperCase()) {
        return `${user.first_name[0]}${last_name[i]}`;
      }
    }
    /* If there is no capital letter in the last name, just use the first letter */
    return `${user.first_name[0]}${last_name[0].toUpperCase()}`;
  }

  getUserId(user) {
    /* Constructs an "ID" out of user properties*/
    return user.first_name + user.middle_name + user.last_name + user.email;
  }
}
export default UserService;
