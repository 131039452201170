class NurseService {

    constructor(apiService, organizationService) {
        this.apiService = apiService;
        this.chosenOrganization = organizationService.selectedOrg;
        this.nurses = [];

    }

    async retrieveUsers() {
        /* Passes a reference to the list of logs, tries to retrieve logs if list is empty */
        if (this.apiService) {
            const response = await this.apiService.retrieveOrganizationUsersAll(this.chosenOrganization);
            if (response.success) {
                this.nurses = response.data.results;
                return this.nurses;
            }
        }
        return this.nurses;
    }

}
export default NurseService;