import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Typography, CircularProgress } from "@mui/material";
import { primaryTheme } from "../../themes/primaryTheme";
import logo from "../../assets/images/xs_alert_logo_2.png";

const LoadingScreen = () => {
  return (
    <ThemeProvider theme={primaryTheme}>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          style={{ height: "3rem", margin: 10, position: "absolute", top: 0 }}
          alt="xsalert logo"
        />
        <CircularProgress color="primary" size="3rem" />
        <Typography
          color="primary"
          variant="button"
          display="block"
          my={2}
          gutterBottom
        >
          Loading
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default LoadingScreen;
