import React, { useState, useContext, useEffect, useCallback } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
  Avatar,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  FormControl,
  TextField,
  Input,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
} from "@mui/material";

import { Visibility, Close, ArrowForward } from "@mui/icons-material";

import Modal from "@mui/material/Modal";

export function RemoveUserGroupOrganizationModal({
  openRemoveUserGroupOrganizationModal,
  setOpenRemoveUserGroupOrganizationModal,
  handleRemoveUserGroupFromOrganization,
}) {
  const { t } = useTranslation();
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={openRemoveUserGroupOrganizationModal}
        onClose={() => setOpenRemoveUserGroupOrganizationModal(false)}
      >
        <Box sx={style}>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {t("manageUsers.userGroups.removeUserGroupModal.warningText")}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveUserGroupFromOrganization()}
          >
            {t("manageUsers.userGroups.removeUserGroupModal.removeUserGroup")}
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export function UsersInUserGroupsModal({
  openUsersInUserGroupsModal,
  setOpenUsersInUserGroupsModal,
  userGroupDetail,
  selectedUserGroup,
  setSelectedUserGroup,
  users,
  handleNewAlert,
  refreshUserGroupsList,
  refreshUsersList,
}) {
  const { t } = useTranslation();
  const context = useContext(ServiceContext);
  let members = selectedUserGroup.members;
  const style = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "75%",
    color: "white",
    background: "black",
    border: "2px solid #000",
    boxShadow: 24,
  };

  // handlers

  const addUserToUserGroup = (user, userGroup) => {
    context.userService
      .addUserToUserGroup(user.id, userGroup.id)
      .then((response) => {
        setSelectedUserGroup(response);
        handleNewAlert(`Added user ${user.email} to "${userGroup.name}"`);
        refreshUserGroupsList();
        refreshUsersList();
      })
      .catch((err) =>
        handleNewAlert(`error adding user "${user.email}" to group: ${err}`)
      );
  };

  const removeUserFromUserGroup = (user, userGroup) => {
    context.userService
      .removeUserFromUserGroup(user.id, userGroup.id)
      .then((response) => {
        setSelectedUserGroup(response);
        handleNewAlert(`Removed user "${user.email}" from "${userGroup.name}"`);
        refreshUserGroupsList();
        refreshUsersList();
      })
      .catch((err) => handleNewAlert(`error adding user to group: ${err}`));
  };

  // render stuff
  const renderUserGroups = (groups) => {
    let userGroups = [];
    groups.slice(0, 3).forEach((group) =>
      userGroups.push(
        <Button
          key={group}
          sx={{ color: "white !important", fontSize: "0.6rem", p: 1, mx: 1 }}
          variant="contained"
          disabled
        >
          {group}
        </Button>
      )
    );
    if (groups.length > 3)
      userGroups.push(
        <Button
          key={-1}
          sx={{ color: "white !important", p: 1, mx: 1 }}
          variant="contained"
          disabled
        >
          .. and more
        </Button>
      );
    return userGroups;
  };
  const renderAllUsers = () => {
    let memberIDs = [];
    members.forEach((member) => memberIDs.push(member.id));

    let allUsers = [];
    users.forEach((user) => {
      if (!memberIDs.includes(user.id))
        allUsers.push(
          <Box key={user.id}>
            <ListItem alignItems="center" sx={{ justifyContent: "center" }}>
              <ListItemAvatar>
                <Avatar alt={user.full_name} src={user.photo_url} />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.full_name} (${user.email})`}
                secondary={renderUserGroups(user.groups)}
              />
              <Button>
                <ArrowForward
                  onClick={() => addUserToUserGroup(user, selectedUserGroup)}
                />
              </Button>
            </ListItem>
            <Divider
              variant="inset"
              component="li"
              sx={{
                borderWidth: 1,
                borderColor: "background.border",
              }}
            />
          </Box>
        );
    });

    return (
      <Box>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {allUsers}
        </List>
      </Box>
    );
  };

  const renderMembers = () => {
    let memberListItems = [];
    members.forEach((member) => {
      memberListItems.push(
        <Box key={member.id}>
          <ListItem alignItems="center" sx={{ justifyContent: "center" }}>
            <ListItemAvatar>
              <Avatar alt={member.full_name} src={member.photo_url} />
            </ListItemAvatar>
            <ListItemText
              primary={`${member.full_name} (${member.email})`}
              secondary={renderUserGroups(member.groups)}
            />
            <Button sx={{ color: "white" }}>
              <Close
                onClick={() =>
                  removeUserFromUserGroup(member, selectedUserGroup)
                }
              />
            </Button>
          </ListItem>
          <Divider
            variant="inset"
            component="li"
            sx={{
              borderWidth: 1,
              borderColor: "background.border",
            }}
          />
        </Box>
      );
    });
    return (
      <Box sx={{ width: "100%" }}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {memberListItems}
        </List>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={openUsersInUserGroupsModal}
        onClose={() => setOpenUsersInUserGroupsModal(false)}
      >
        <Box sx={style}>
          <Box sx={{ width: "100%", my: 2 }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {userGroupDetail.name}
            </Typography>
          </Box>
          <Divider
            sx={{
              borderWidth: 1,
              borderColor: "background.border",
            }}
          />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    backgroundColor: "background.hover",
                    p: 2,
                  }}
                >
                  {t("All Users")}
                </Typography>
                {renderAllUsers()}
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              sx={{ borderColor: "black", borderWidth: "1px" }}
            />
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  backgroundColor: "background.hover",
                  p: 2,
                }}
              >
                {t("Members")}
              </Typography>
              {renderMembers()}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default function ManageUserGroupDrawer({
  openUserGroupDrawer,
  setOpenUserGroupDrawer,
  selectedUserGroup,
  setSelectedUserGroup,
  handleNewAlert,
  refreshUserGroupsList,
  refreshUsersList,
  users,
}) {
  const { t } = useTranslation();
  let context = useContext(ServiceContext);
  let [userGroupDetail, setUserGroupDetail] = useState(false);
  let [
    openRemoveUserGroupOrganizationModal,
    setOpenRemoveUserGroupOrganizationModal,
  ] = useState(false);
  let [openUsersInUserGroupsModal, setOpenUsersInUserGroupsModal] =
    useState(false);

  useEffect(() => {
    // fetch user group details
    if (selectedUserGroup) {
      context.userService
        .retrieveUserGroup(selectedUserGroup.id)
        .then((userGroup) => {
          setUserGroupDetail(userGroup);
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    }
  }, [selectedUserGroup, context.userService]);

  // form states
  let [selectedUserGroupName, setSelectedUserGroupName] = useState(false);

  const resetFormValues = useCallback(() => {
    setSelectedUserGroupName(userGroupDetail.name);
  }, [userGroupDetail]);

  useEffect(() => {
    // reset form selects when new device is selected
    if (userGroupDetail) {
      resetFormValues();
    }
  }, [userGroupDetail, resetFormValues]);

  // form errors
  const [userGroupNameError, setUserGroupNameError] = useState(false);

  const resetErrors = () => {
    setUserGroupNameError(false);
  };

  // form changes
  let [userGroupFormChanged, setUserGroupFormChanged] = useState(false);

  // // handlers
  // const handleAccordionChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  useEffect(() => {
    // set User Group Form Changed if any form element has been updated by the user
    if (selectedUserGroupName !== userGroupDetail.name) {
      setUserGroupFormChanged(true);
      resetErrors();
    } else setUserGroupFormChanged(false);
  }, [userGroupDetail, selectedUserGroupName]);

  const handleRemoveUserGroupFromOrganization = () => {
    context.userService
      .removeUserGroup(userGroupDetail.id)
      .then((response) => {
        handleNewAlert(
          `Successfully removed user group "${userGroupDetail.name}"`,
          "success"
        );
        setTimeout(() => {
          refreshUserGroupsList();
          setOpenRemoveUserGroupOrganizationModal(false);
          setOpenUserGroupDrawer(false);
        }, 500);
      })
      .catch((err) => {
        handleNewAlert(`Error: ${err}`, "error");
        setOpenRemoveUserGroupOrganizationModal(false);
        setOpenUserGroupDrawer(false);
      });
  };

  const handleUpdateUserGroup = (id) => {
    let data = {
      organization: context.organizationService.selectedOrg,
      name: selectedUserGroupName,
    };
    context.userService
      .updateUserGroup(id, data)
      .then((response) => {
        refreshUserGroupsList();
        handleNewAlert(
          `Successfully updated User Group ${selectedUserGroupName}`,
          "success"
        );
        setSelectedUserGroup(response);
      })
      .catch((err) => {
        // form errors
        // if (err.location) setDeviceLocationError(err.location[0]);
        handleNewAlert(`Error Updating User: ${JSON.stringify(err)}`, "error");
      });
  };

  const renderDrawer = () => (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openUserGroupDrawer}
      onClose={() => setOpenUserGroupDrawer(false)}
      transitionDuration={300}
      sx={{
        width: openUserGroupDrawer ? "500px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: openUserGroupDrawer ? "500px" : 0,
          px: 0,
          py: 1,
          maxWidth: "90vw",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></Box>
        <Box
          sx={{
            width: "90%",
            // "& .MuiPaper-root": {
            //   py: 0,
            // },
            // "& .Mui-expanded": {
            //   my: 0,
            // },
            // "& .MuiAccordionDetails-root": {
            //   py: 0,
            // },
            // "& .MuiButtonBase-root .MuiSvgIcon-root": {
            //   color: "white",
            // },
            // "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            //   color: "white",
            // },
            // "& .MuiSelect-icon": {
            //   color: "white",
            // },
            // "& .MuiListItemText-root": {
            //   minWidth: "30%",
            // },
            // "& .MuiListItem-root:hover": {
            //   backgroundColor: "rgb(76, 76, 76) !important",
            //   color: theme.palette.primary.main,
            //   borderRadius: "5px",
            // },
            // "& .MuiListItem-root:hover .MuiSvgIcon-root": {
            //   color: theme.palette.primary.main,
            // },
            "& .MuiInputLabel-formControl": { color: "white" },
            "& .MuiInput-input": {
              backgroundColor: "transparent",
              color: "white",
              textAlign: "end",
              px: 0,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
            },
            "& .Mui-disabled": {
              color: "rgba(0, 0, 0, 0.87)",
            },
            // "& .MuiSelect-select": { px: 0, pr: "30px !important" },
            // "& .MuiFormControl-root": { m: 0 },
            // "& .MuiAutocomplete-root": { width: "100%" },
            "& .MuiFormHelperText-root": { color: "red" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              my: 2,
            }}
          >
            <Avatar
              alt={selectedUserGroup.name}
              src={selectedUserGroup.photo}
              sx={{
                width: "150px",
                height: "150px",
              }}
            />
          </Box>
          <Divider sx={{ borderWidth: 1, borderColor: "background.border" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              my: 1,
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <TextField
                required
                id="userGroupName"
                label="Group name"
                value={selectedUserGroupName ? selectedUserGroupName : ""}
                sx={{ color: "white" }}
                onChange={(event) =>
                  setSelectedUserGroupName(event.target.value)
                }
              />
              <FormHelperText>
                {userGroupNameError ? userGroupNameError : false}
              </FormHelperText>
            </FormControl>

            <Button
              disabled={userGroupFormChanged ? false : true}
              variant="contained"
              sx={{ my: 1 }}
              onClick={() => handleUpdateUserGroup(selectedUserGroup.id)}
            >
              Save
            </Button>
          </Box>
          <Divider sx={{ borderWidth: 1, borderColor: "background.border" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              m: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                p: 2,
                "&:hover": {
                  backgroundColor: "background.hover",
                  borderRadius: "5px",
                  "&.MuiSvgIcon-root": {
                    color: "primary",
                  },
                },
                cursor: "pointer",
              }}
              onClick={() => setOpenUsersInUserGroupsModal(true)}
            >
              <Typography sx={{ textAlign: "start", flexGrow: 1 }}>
                {t("manageUsers.userGroups.detail.members")}
              </Typography>
              <Typography>{selectedUserGroup.members.length}</Typography>
              <Visibility sx={{ width: 20, height: 20, pl: 2 }} />
            </Box>
          </Box>
        </Box>

        <Button
          variation="outlined"
          color="error"
          onClick={() => setOpenRemoveUserGroupOrganizationModal(true)}
          sx={{ my: 1 }}
        >
          {t("manageUsers.userGroups.detail.removeUserGroupFromOrganization")}
        </Button>
      </Box>
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: openUserGroupDrawer ? "-20px" : "",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          openUserGroupDrawer
            ? setOpenUserGroupDrawer(false)
            : setOpenUserGroupDrawer(true);
        }}
      >
        {openUserGroupDrawer ? (
          <Tooltip title="Hide User Group Details">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : null}
      </Button>
      {userGroupDetail ? (
        <>
          <RemoveUserGroupOrganizationModal
            openRemoveUserGroupOrganizationModal={
              openRemoveUserGroupOrganizationModal
            }
            setOpenRemoveUserGroupOrganizationModal={
              setOpenRemoveUserGroupOrganizationModal
            }
            handleRemoveUserGroupFromOrganization={
              handleRemoveUserGroupFromOrganization
            }
          />
          <UsersInUserGroupsModal
            openUsersInUserGroupsModal={openUsersInUserGroupsModal}
            setOpenUsersInUserGroupsModal={setOpenUsersInUserGroupsModal}
            userGroupDetail={selectedUserGroup}
            selectedUserGroup={selectedUserGroup}
            setSelectedUserGroup={setSelectedUserGroup}
            users={users}
            handleNewAlert={handleNewAlert}
            refreshUserGroupsList={refreshUserGroupsList}
            refreshUsersList={refreshUsersList}
          />
        </>
      ) : null}
    </Drawer>
  );
  return selectedUserGroup ? renderDrawer() : null;
}
