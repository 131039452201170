import React, { useState } from 'react';
import '../../index.css';
import './sign_in.css';

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMesssage] = useState(null);

    const login = async (event) => {
        /* Called when the login button is clicked */

        /* Prevent default HTML button action */
        event.preventDefault();

        try {
            /* Stop when we got no email or password. */
            if (!email || !password) {
                return;
            }
            const response = await props.authService.login(email, password);
            if (!response.success) {
                throw Error(response.error);
            }
        } catch (error) {
            /* Show error message and hide it after 2 seconds. */
            setErrorMesssage(error.message);
            setTimeout(() => setErrorMesssage(null), 3000);
        }
    }

    if (props.loading) {
        /* The JS application is still loading, don't show form yet. */
        return null;
    }

    return (
        <div className="sign-in page">
            <div className="inner">
                <form id="login-form">
                    <div className="logo"></div>
                    <div className="container">
                        <div className="row">
                            <input
                                type="text"
                                placeholder="E-mailadres"
                                name="uname"
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                                autoComplete="off"
                                required
                            />
                        </div>
                        <div className="row">
                            <input
                                type="password"
                                placeholder="Wachtwoord"
                                name="psw"
                                value={password}
                                onChange={(event) => { setPassword(event.target.value) }}
                                required
                            />
                        </div>
                        <p className="small" onClick={() => window.location.href = "https://msg.nextxs.nl/accounts/password_reset/"}> Wachtwoord vergeten?</p>
                        <p id="login-error" style={{ textAlign: 'center', color: "red" }}>
                            {errorMessage}
                        </p>
                        <div className="row submit">
                            <div>
                                <button type="submit" className="button" id="login-button" onClick={login}>
                                    Inloggen
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignIn;
