import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

function dfs(node, term, foundIDS) {
  // Implement your search functionality
  let isMatching = node.name && node.name.toLowerCase().indexOf(term.toLowerCase()) > -1;

  if (Array.isArray(node.children)) {
    node.children.forEach((child) => {
      const hasMatchingChild = dfs(child, term, foundIDS);
      isMatching = isMatching || hasMatchingChild;
    });
  }
  // We will add any item if it matches our search term or if it has a children that matches our term
  if (isMatching && node.id) {
    foundIDS.push(node.id);
  }

  return isMatching;
}

function filter(locations, matchedIDS) {
  let filtered = locations
    .filter((item) => matchedIDS.indexOf(item.id) > -1)
    .map((item) => ({
      ...item,
      children: item.children ? filter(item.children, matchedIDS) : [],
    }));
  return filtered;
}

function addParentToList(location, filteredWithParents, locations) {
    if (location.parent && locations) {
        let parent = locations.filter((e) => e.id === location.parent )[0]
        if (!filteredWithParents.some(el => el.id === parent.id)) filteredWithParents.push(parent);
        addParentToList(parent, filteredWithParents, locations);
    }
}

function addChildrenToList(location, filteredWithParents, locations) {
    if (location.children_count > 0 && locations) {
        let children = locations.filter((e) => e.parent === location.id)
        children.forEach((location, index) =>{
            if (!filteredWithParents.some(el => el.id === location.id)) filteredWithParents.push(location);
        })
    }
}

function search(term, locations, setFilteredLocations) {
  // 1. We wrap data in an object to match the node shape
  const dataNode = { children: locations};
  // 2. find matched locations
  const matchedIDS = []; 
  dfs(dataNode, term, matchedIDS); 
  // 3. filter the original data so that only matching items (and their fathers if they have) are returned
  const filtered = filter(locations, matchedIDS);
  // 4. Add the parents of all locations in filtered list  
  const filteredWithParents = []
  filtered.forEach(function (location, index) {  // TODO: Add all children as well.
    if(!filteredWithParents.some(el => el.id === location.id)) filteredWithParents.push(location);
    addChildrenToList(location, filteredWithParents, locations);
    addParentToList(location, filteredWithParents, locations);
  });
  // 5. Set state of FilteredLocations in ListLocationGroupMUI
  if (filteredWithParents) {
    setFilteredLocations(filteredWithParents);
  } else {
    setFilteredLocations(locations);
  }
  
  return
}

export default function SearchLocationGroup({ locations, setFilteredLocations, filterON, setFilterON }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        my: 1,
        backgroundColor: "transparent",
        color: "white",
      }}
    >
      <TextField
        id="searchbar"
        fullWidth
        label="Search"
        placeholder={t("dashboard.groupView.Search by Location Name")}
        variant="outlined"
        sx={{
          color: "white",
          backgroundColor: `${theme.palette.background.paper} !important;`,
        }}
        color="primary"
        InputLabelProps={{
          min: 0,
          style: { top: "-5px", left: "10px", alignSelf: "center" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: "white",
              }}
              position="start"
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: { color: "white", backgroundColor: "inherit" },
        }}
        onChange={(event) => {
            search(event.target.value, locations, setFilteredLocations);
            if (filterON && !event.target.value) setFilterON(false);
            if (!filterON && event.target.value) setFilterON(true);
        }}
      />
    </Box>
  );
}
