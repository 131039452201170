import React, { useContext } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import ReactFlagsSelect from "react-flags-select";
import "./internationalization.css";
import { withTranslation } from 'react-i18next';

function SelectLanguage({ t, i18n })  {
  const {selectedLanguage, setSelectedLanguage} = useContext(ServiceContext);
  
  const changeLanguage = (code) => {
    setSelectedLanguage(code);
    i18n.changeLanguage(code, (err, t) => {
      console.log(`Changing Language to ${code}`);
      localStorage.setItem("language", code);
      if (err) return console.log('something went wrong loading language settings', err);
    });
  }

  return (
    <ReactFlagsSelect
      selected={selectedLanguage}
      onSelect={(code) => changeLanguage(code)}
      countries={["GB", "NL", "NO"]}
      customLabels={{"GB": "English", "NL": "Nederlands", "NO": "Norsk"}}
      placeholder="Select Language"
      className='language'
    />
  );
};
export default withTranslation() (SelectLanguage);
