import React, { useContext, useState, useEffect } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { InputLabel } from "@mui/material";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import Cancel from "@mui/icons-material/Cancel";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  color: "text.main",
  boxShadow: 24,
  p: 4,
};

export default function CreateNewDeviceModal({
  setDevices,
  setSelectedDevice,
  setOpenDeviceDrawer,
  handleNewAlert,
  openPopupNewDevice,
  setOpenPopupNewDevice,
}) {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // form states
  const [deviceLocation, setDeviceLocation] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceIdentifier, setDeviceIdentifier] = useState("");
  const [deviceNotes, setDeviceNotes] = useState("");
  const [deviceActive, setDeviceActive] = useState(true);
  const [deviceExtraData, setDeviceExtraData] = useState("");

  const resetFormValues = () => {
    setDeviceLocation("");
    setDeviceType("");
    setDeviceIdentifier("");
    setDeviceNotes("");
    setDeviceActive(true);
    setDeviceExtraData("");
  };

  // form errors
  const [deviceLocationError, setDeviceLocationError] = useState(false);
  const [deviceTypeError, setDeviceTypeError] = useState(false);
  const [deviceIdentifierError, setDeviceIdentifierError] = useState(false);
  const [deviceNotesError, setDeviceNotesError] = useState(false);
  const [deviceActiveError, setDeviceActiveError] = useState(false);
  const [deviceExtraDataError, setDeviceExtraDataError] = useState(false);

  const resetErrors = () => {
    setDeviceLocationError(false);
    setDeviceTypeError(false);
    setDeviceIdentifierError(false);
    setDeviceNotesError(false);
    setDeviceActiveError(false);
    setDeviceExtraDataError(false);
  };

  // form choices
  const [locationChoices, setLocationChoices] = useState(false);
  const [deviceTypeChoices, setDeviceTypeChoices] = useState(false);

  useEffect(() => {
    // fetch location choices
    if (!locationChoices) {
      context.locationService.retrieveLocations().then((response) => {
        setLocationChoices(response);
      });
    }
  }, [locationChoices, context.locationService]);

  useEffect(() => {
    // fetch device type choices
    if (!deviceTypeChoices) {
      context.deviceService.retrieveDeviceTypes().then((response) => {
        setDeviceTypeChoices(response.results);
      });
    }
  }, [deviceTypeChoices, context.deviceService]);

  // handlers
  const handleCloseModal = () => {
    setOpenPopupNewDevice(false);
    resetErrors();
    resetFormValues();
  };

  const sendRequestNewDevice = () => {
    resetErrors();
    setLoading(true);
    let data = {
      organization: context.organizationService.selectedOrg,
      location: deviceLocation,
      device_type: deviceType,
      identifier: deviceIdentifier,
      notes: deviceNotes,
      active: deviceActive,
      extra_data: deviceExtraData,
    };
    context.deviceService
      .createDevice(data)
      .then((response) => {
        setTimeout(() => {
          setDevices(false); // this should trigger the useEffect in ManageDevicesScreen and re-fetch data.
          setSelectedDevice(response);
          setOpenDeviceDrawer(true);
          setLoading(false);
          handleCloseModal();
          handleNewAlert(
            `Added a new Device: ${response.identifier}`,
            "success"
          );
        }, 500);
      })
      .catch((err) => {
        // form errors
        if (err.location) setDeviceLocationError(err.location[0]);
        if (err.device_type) setDeviceTypeError(err.device_type[0]);
        if (err.identifier) setDeviceIdentifierError(err.identifier[0]);
        if (err.notes) setDeviceNotesError(err.notes[0]);
        if (err.active) setDeviceActiveError(err.active[0]);
        if (err.extra_data) setDeviceExtraDataError(err.extra_data[0]);
        setLoading(false);
        handleNewAlert(
          `Failed to add a new Device: ${err ? JSON.stringify(err) : null}`,
          "error"
        );
      });
  };

  return (
    <div>
      <Modal open={openPopupNewDevice} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              width: "500px",
              my: 2,
              maxWidth: "100%",
              "& .MuiInputLabel-formControl": { color: "white" },
              "& .MuiInput-input": {
                backgroundColor: "transparent",
                color: "white",
              },
              "& .MuiSelect-select": { px: 1 },
              "& 	.MuiAutocomplete-groupLabel": {
                backgroundColor: "red !important",
              },
              // "& .MuiSvgIcon-root": { color: "white" },
              "& .MuiFormHelperText-root": { color: "red" },
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <Typography>
                {context.organizationService.selectedOrgDetails.name}
              </Typography>
            </FormControl>

            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <Autocomplete
                id="form-input-devicelocation"
                options={locationChoices ? locationChoices : []}
                onChange={(event, value) => {
                  if (value) setDeviceLocation(value.id);
                  else {
                    setDeviceLocation(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("manageDevices.devices.addDevice.location")}
                  />
                )}
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(props, option) => (
                  <Box
                    {...props}
                    sx={{ display: "flex", px: 4, py: 1 }}
                    key={option.id}
                  >
                    <Typography sx={{ flex: 1 }}>{option.name}</Typography>
                    <Typography fontSize={12}>
                      {option.parent_name ? `(${option.parent_name})` : null}
                    </Typography>
                  </Box>
                )}
                groupBy={(option) => option.type_name}
              />
              <FormHelperText>
                {deviceLocationError ? deviceLocationError : null}
              </FormHelperText>
            </FormControl>

            <FormControl
              required
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <Autocomplete
                required
                id="form-input-devicetype"
                options={deviceTypeChoices ? deviceTypeChoices : []}
                onChange={(event, value) => {
                  if (value) setDeviceType(value.id);
                  else {
                    setDeviceType(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label={t("manageDevices.devices.addDevice.deviceType")}
                  />
                )}
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(props, option) => (
                  <Box
                    {...props}
                    sx={{ display: "flex", px: 4, py: 1 }}
                    key={option.id}
                  >
                    <Typography>{option.name}</Typography>
                  </Box>
                )}
                groupBy={(option) => option.brand}
              />
              <FormHelperText>
                {deviceTypeError ? deviceTypeError : null}
              </FormHelperText>
            </FormControl>

            <FormControl
              error={deviceIdentifierError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                required
                error={deviceIdentifierError ? true : false}
                id="form-input-identifier"
                label={t("manageDevices.devices.addDevice.identifier")}
                variant="standard"
                onChange={(event) => setDeviceIdentifier(event.target.value)}
              />
              <FormHelperText>
                {deviceIdentifierError ? deviceIdentifierError : null}
              </FormHelperText>
            </FormControl>

            <FormControl
              error={deviceNotesError ? true : false}
              variant="standard"
              sx={{
                mx: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                multiline
                maxRows={5}
                error={deviceNotesError ? true : false}
                id="form-input-identifier"
                label={t("manageDevices.devices.addDevice.notes")}
                variant="standard"
                onChange={(event) => setDeviceNotes(event.target.value)}
              />
              <FormHelperText>
                {deviceNotesError ? deviceNotesError : null}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <InputLabel required>
                {t("manageDevices.devices.addDevice.isActive")}
              </InputLabel>
              <Select
                required
                value={deviceActive}
                onChange={(event) => {
                  setDeviceActive(event.target.value);
                }}
              >
                <MenuItem
                  key={1}
                  value={true}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CheckCircleOutlineOutlined color="primary" />
                </MenuItem>
                <MenuItem
                  key={0}
                  value={false}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <Cancel color="error" />
                </MenuItem>
              </Select>
              <FormHelperText>
                {deviceActiveError ? deviceActiveError : false}
              </FormHelperText>
            </FormControl>

            {/* extra data to be added later */}
            <FormControl>
              <FormHelperText>
                {deviceExtraDataError ? deviceExtraDataError : null}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => sendRequestNewDevice()}
              >
                {t("manageDevices.devices.addDevice.add")}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
