import React, { useContext, useState, useEffect } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  color: "text.main",
  boxShadow: 24,
  p: 4,
};

export default function CreateNewAlertRuleModal({
  setSelectedAlertRule,
  setOpenAlertRuleDrawer,
  handleNewAlert,
  setAlertRules,
  openPopupNewAlertRule,
  setOpenPopupNewAlertRule,
}) {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // form selects for Alert Rule form
  const [alertRuleDevice, setAlertRuleDevice] = useState(false);
  const [alertRulePriority, setAlertRulePriority] = useState(false);
  const [alertRuleEventTypes, setAlertRuleEventTypes] = useState(false);
  const [alertRuleIsActive, setAlertRuleIsActive] = useState(false);

  // form errors
  const [alertRuleDeviceError, setAlertRuleDeviceError] = useState(false);
  const [alertRulePriorityError, setAlertRulePriorityError] = useState(false);
  const [alertRuleEventTypesError, setAlertRuleEventTypesError] =
    useState(false);
  const [alertRuleIsActiveError, setAlertRuleIsActiveError] = useState(false);

  // form choices for Alert Rule form
  let [deviceChoices, setDeviceChoices] = useState(false);
  let [priorityChoices, setPriorityChoices] = useState(false);
  let [fetchedPriorityChoices, setFetchedPriorityChoices] = useState(false);
  let [eventTypeChoices, setEventTypeChoices] = useState(false);
  let [fetchedEventTypeChoices, setFetchedEventTypeChoices] = useState(false);

  useEffect(() => {
    if (!fetchedPriorityChoices && !priorityChoices) {
      context.alertService.retrievePriorityChoices().then((response) => {
        setPriorityChoices(response.data);
        setFetchedPriorityChoices(true);
      });
    }
  }, [fetchedPriorityChoices, priorityChoices, context.alertService]);

  useEffect(() => {
    if (!fetchedEventTypeChoices && !eventTypeChoices) {
      context.alertService.retrieveEventTypeChoices().then((response) => {
        setEventTypeChoices(response.data);
        setFetchedEventTypeChoices(true);
      });
    }
  }, [fetchedEventTypeChoices, eventTypeChoices, context.alertService]);

  useEffect(() => {
    if (!deviceChoices) {
      let devices = [];
      context.deviceService
        .retrieveOrganizationDevices()
        .then((response) => {
          response.forEach((device) => {
            devices.push(
              <MenuItem key={device.id} value={device.id}>
                {`${device.device_type_name} - ${device.identifier}`}
              </MenuItem>
            );
          });
          setDeviceChoices(devices);
        })
        .catch((err) => console.log(JSON.stringify(err)));
    }
  }, [deviceChoices, context.deviceService]);

  const resetFormValues = () => {
    setAlertRuleDevice("");
    setAlertRulePriority("");
    setAlertRuleEventTypes(null);
    setAlertRuleIsActive(false);
  };

  const resetErrors = () => {
    setAlertRuleDeviceError(false);
    setAlertRulePriorityError(false);
    setAlertRuleEventTypesError(false);
    setAlertRuleIsActiveError(false);
  };

  const handleCloseModal = () => {
    setOpenPopupNewAlertRule(false);
    resetErrors();
    resetFormValues();
  };

  const sendRequestNewAlertRule = () => {
    resetErrors();
    setLoading(true);
    let data = {
      device: alertRuleDevice,
      organization: context.organizationService.selectedOrg,
      event_types:
        alertRuleEventTypes && alertRuleEventTypes.length
          ? alertRuleEventTypes
          : null,
      priority: alertRulePriority,
      is_active: alertRuleIsActive,
    };
    context.alertService
      .createAlertRule(data)
      .then((response1) => {
        setTimeout(() => {
          context.alertService
            .retrieveOrganizationAlertRules()
            .then((response2) => {
              setAlertRules(response2.data);
              let newAlertRule = response2.data.filter(
                (el) => el.id === response1.id
              )[0];
              setSelectedAlertRule(newAlertRule);
              setOpenAlertRuleDrawer(true);
            });
          setLoading(false);
          handleCloseModal();
          handleNewAlert(
            `Added a new Alert Rule: ${response1.device}`,
            "success"
          );
        }, 500);
      })
      .catch((err) => {
        if (err.stream) setAlertRuleDeviceError(err.stream[0]);
        if (err.event_types) setAlertRuleEventTypesError(err.event_types[0]);
        if (err.priority) setAlertRulePriorityError(err.priority[0]);
        if (err.is_active) setAlertRuleIsActiveError(err.is_active[0]);
        setLoading(false);
        console.log(err);
        handleNewAlert(
          `Failed to add a new alert rule: ${
            err.detail ? JSON.stringify(err.detail) : JSON.stringify(err)
          }`,
          "error"
        );
      });
  };

  const renderPriorityChoices = () => {
    let arr = [];
    if (priorityChoices && priorityChoices.length)
      priorityChoices.forEach((choice) => {
        arr.push(
          <MenuItem key={choice[0]} value={choice[0] + ""}>
            {choice[1]}
          </MenuItem>
        );
      });
    return arr;
  };

  const renderEventTypeChoices = () => {
    let arr = [];
    if (eventTypeChoices && eventTypeChoices.length)
      eventTypeChoices.forEach((choice) => {
        arr.push(
          <MenuItem key={choice} value={choice + ""}>
            {choice}
          </MenuItem>
        );
      });
    return arr;
  };

  return (
    <div>
      <Modal open={openPopupNewAlertRule} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              width: "500px",
              my: 2,
              maxWidth: "100%",
              "& .MuiInputLabel-formControl": { color: "white" },
              "& .MuiInput-input": {
                backgroundColor: "transparent",
                color: "white",
              },
              "& .MuiSelect-select": { px: 1 },
              "& .MuiFormHelperText-root": { color: "red" },
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <InputLabel required>
                {t("manageAlerts.alertRules.create.device")}
              </InputLabel>
              <Select
                required
                id="form-input-device"
                value={alertRuleDevice ? alertRuleDevice : ""}
                onChange={(event) => setAlertRuleDevice(event.target.value)}
                MenuProps={{
                  elevation: 10,
                  MenuListProps: {
                    sx: { backgroundColor: "#323332" },
                  },
                }}
              >
                {deviceChoices}
              </Select>
              <FormHelperText>
                {alertRuleDeviceError ? alertRuleDeviceError : false}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <InputLabel required>
                {t("manageAlerts.alertRules.create.priority")}
              </InputLabel>
              <Select
                required
                id="form-input-priority"
                defaultValue=""
                value={alertRulePriority ? alertRulePriority + "" : ""}
                onChange={(event) => {
                  setAlertRulePriority(event.target.value);
                }}
              >
                {renderPriorityChoices()}
              </Select>
              <FormHelperText>
                {alertRulePriorityError ? alertRulePriorityError : false}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <InputLabel>
                {t("manageAlerts.alertRules.create.eventTypes")}
              </InputLabel>
              <Select
                multiple
                id="form-input-eventTypes"
                value={alertRuleEventTypes ? alertRuleEventTypes : []}
                onChange={(event) => {
                  setAlertRuleEventTypes(event.target.value);
                }}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {renderEventTypeChoices()}
              </Select>
              <FormHelperText>
                {alertRuleEventTypesError ? alertRuleEventTypesError : false}
              </FormHelperText>
              <FormHelperText
                sx={{ color: "white !important", textAlign: "end", pr: "40px" }}
              >
                Leave Empty for 'All' Event Types
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <InputLabel required>
                {t("manageAlerts.alertRules.create.isActive")}
              </InputLabel>
              <Select
                required
                id="form-input-is_active"
                defaultValue=""
                label={t("manageAlerts.alertRules.create.isActive")}
                value={alertRuleIsActive}
                onChange={(event) => {
                  setAlertRuleIsActive(event.target.value);
                }}
              >
                <MenuItem
                  key={1}
                  value={true}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CheckCircleOutlineIcon color="primary" />
                </MenuItem>
                <MenuItem
                  key={0}
                  value={false}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <CancelIcon color="error" />
                </MenuItem>
              </Select>
              <FormHelperText>
                {alertRuleIsActiveError ? alertRuleIsActiveError : false}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => sendRequestNewAlertRule()}
              >
                Create Alert Rule
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
