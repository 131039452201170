import React, { useState, useContext, useEffect, useCallback } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Autocomplete } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { InputAdornment } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";

import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import Cancel from "@mui/icons-material/Cancel";
import { Edit } from "@mui/icons-material";

import Modal from "@mui/material/Modal";

export function DeleteDeviceModal({
  openDeleteDeviceModal,
  setOpenDeleteDeviceModal,
  deviceDetail,
  handleDeleteDevice,
}) {
  const { t } = useTranslation();
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={openDeleteDeviceModal}
        onClose={() => setOpenDeleteDeviceModal(false)}
      >
        <Box sx={style}>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {t("manageDevices.devices.delete.warningText")}
          </Typography>
          <Typography sx={{ color: "text.main", m: 2, textAlign: "center" }}>
            {deviceDetail.device_type.name}, {deviceDetail.identifier}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteDevice()}
          >
            {t("manageDevices.devices.detail.deleteDevice")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function ManageDeviceDrawer({
  openDeviceDrawer,
  setOpenDeviceDrawer,
  setDevices,
  selectedDevice,
  setSelectedDevice,
  handleNewAlert,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  let context = useContext(ServiceContext);
  let [deviceDetail, setDeviceDetail] = useState(false);
  let [expanded, setExpanded] = useState("deviceDetails");
  let [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);

  useEffect(() => {
    // fetch device details
    if (selectedDevice) {
      context.deviceService
        .retrieveDevice(selectedDevice.id)
        .then((device) => {
          setDeviceDetail(device);
        })
        .catch((err) => console.log(JSON.stringify(err)));
    }
  }, [selectedDevice, context.deviceService]);

  // form states
  let [deviceLocation, setDeviceLocation] = useState(deviceDetail.location);
  let [deviceType, setDeviceType] = useState(deviceDetail.device_type);
  let [deviceIdentifier, setDeviceIdentifier] = useState(
    deviceDetail.identifier
  );
  let [deviceNotes, setDeviceNotes] = useState(deviceDetail.notes);
  let [deviceActive, setDeviceActive] = useState(deviceDetail.active);
  let [deviceExtraData, setDeviceExtraData] = useState(deviceDetail.extra_data);

  const resetFormValues = useCallback(() => {
    setDeviceLocation(deviceDetail.location);
    setDeviceType(deviceDetail.device_type);
    setDeviceIdentifier(deviceDetail.identifier);
    setDeviceNotes(deviceDetail.notes);
    setDeviceActive(deviceDetail.active);
    setDeviceExtraData(deviceDetail.extra_data);
  }, [deviceDetail]);

  useEffect(() => {
    // reset form selects when new device is selected
    if (deviceDetail) {
      resetFormValues();
    }
  }, [deviceDetail, resetFormValues]);

  // form errors
  const [deviceLocationError, setDeviceLocationError] = useState(false);
  const [deviceTypeError, setDeviceTypeError] = useState(false);
  const [deviceIdentifierError, setDeviceIdentifierError] = useState(false);
  const [deviceNotesError, setDeviceNotesError] = useState(false);
  const [deviceActiveError, setDeviceActiveError] = useState(false);
  const [deviceExtraDataError, setDeviceExtraDataError] = useState(false);

  const resetErrors = () => {
    setDeviceLocationError(false);
    setDeviceTypeError(false);
    setDeviceIdentifierError(false);
    setDeviceNotesError(false);
    setDeviceActiveError(false);
    setDeviceExtraDataError(false);
  };

  // form changes
  let [deviceFormChanged, setDeviceFormChanged] = useState(false);

  useEffect(() => {
    // set Device Form Changed if any form element has been updated by the user
    if (
      deviceDetail &&
      (deviceLocation !== deviceDetail.location ||
        deviceType !== deviceDetail.device_type ||
        deviceIdentifier !== deviceDetail.identifier ||
        deviceNotes !== deviceDetail.notes ||
        deviceActive !== deviceDetail.active ||
        deviceExtraData !== deviceDetail.extra_data)
    ) {
      setDeviceFormChanged(true);
      resetErrors();
    } else setDeviceFormChanged(false);
  }, [
    deviceDetail,
    deviceLocation,
    deviceType,
    deviceIdentifier,
    deviceNotes,
    deviceActive,
    deviceExtraData,
  ]);

  // form choices
  const [locationChoices, setLocationChoices] = useState(false);
  const [deviceTypeChoices, setDeviceTypeChoices] = useState(false);

  useEffect(() => {
    // fetch location choices
    if (!locationChoices) {
      context.locationService.retrieveLocations().then((response) => {
        setLocationChoices(response);
      });
    }
  }, [locationChoices, context.locationService]);

  useEffect(() => {
    // fetch device type choices
    if (!deviceTypeChoices) {
      context.deviceService.retrieveDeviceTypes().then((response) => {
        setDeviceTypeChoices(response.results);
      });
    }
  }, [deviceTypeChoices, context.deviceService]);

  // handlers
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDeleteDevice = () => {
    context.deviceService
      .deleteDevice(deviceDetail.id)
      .then((response) => {
        setDevices(false);
        handleNewAlert(
          `Successfully deleted Device "${selectedDevice.identifier}"`,
          "success"
        );
        setOpenDeleteDeviceModal(false);
        setOpenDeviceDrawer(false);
      })
      .catch((err) => {
        handleNewAlert(`Error: ${err}`, "error");
        setOpenDeleteDeviceModal(false);
        setOpenDeviceDrawer(false);
      });
  };

  const handleUpdateDevice = (id) => {
    let data = {
      organization: context.organizationService.selectedOrg,
      location: deviceLocation ? deviceLocation.id : null,
      device_type: deviceType ? deviceType.id : null,
      identifier: deviceIdentifier,
      notes: deviceNotes,
      active: deviceActive,
      extra_data: deviceExtraData,
    };
    context.deviceService
      .updateDevice(id, data)
      .then((response) => {
        setDevices(false);
        handleNewAlert(`Successfully updated Device`, "success");
        setSelectedDevice(response);
      })
      .catch((err) => {
        // form errors
        if (err.location) setDeviceLocationError(err.location[0]);
        if (err.device_type) setDeviceTypeError(err.device_type[0]);
        if (err.identifier) setDeviceIdentifierError(err.identifier[0]);
        if (err.notes) setDeviceNotesError(err.notes[0]);
        if (err.active) setDeviceActiveError(err.active[0]);
        if (err.extra_data) setDeviceExtraDataError(err.extra_data[0]);
        handleNewAlert(
          `Error Updating Device: ${JSON.stringify(err)}`,
          "error"
        );
      });
  };

  const renderUpdateButton = () => {
    if (deviceFormChanged) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <Button
            color="warning"
            variant="contained"
            onClick={() => handleUpdateDevice(deviceDetail.id)}
          >
            {t("manageDevices.devices.updateDevice.updateDevice")}
          </Button>
        </Box>
      );
    } else return <></>;
  };

  const renderDrawer = () => (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDeviceDrawer}
      onClose={() => setOpenDeviceDrawer(false)}
      transitionDuration={300}
      sx={{
        width: openDeviceDrawer ? "500px" : 0,
        maxWidth: "100%",
        flexShrink: 0,
        [`& .MuiPaper-root`]: {
          overflow: "visible",
          width: openDeviceDrawer ? "500px" : 0,
          px: 0,
          py: 1,
          maxWidth: "90vw",
          zIndex: 1,
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        ></Box>
        <Box
          sx={{
            "& .MuiPaper-root": {
              py: 0,
            },
            "& .Mui-expanded": {
              my: 0,
            },
            "& .MuiAccordionDetails-root": {
              py: 0,
            },
            "& .MuiButtonBase-root .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiInputAdornment-root .MuiSvgIcon-root": {
              color: "white",
            },
            "& .MuiSelect-icon": {
              color: "white",
            },
            "& .MuiListItemText-root": {
              minWidth: "30%",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "rgb(76, 76, 76) !important",
              color: theme.palette.primary.main,
            },
            "& .MuiListItem-root:hover .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiInputLabel-formControl": { color: "white" },
            "& .MuiInput-input": {
              backgroundColor: "transparent",
              color: "white",
              textAlign: "end",
              px: 0,
            },
            "& .MuiSelect-select": { px: 0, pr: "30px !important" },
            "& .MuiFormControl-root": { m: 0 },
            "& .MuiAutocomplete-root": { width: "100%" },
            "& .MuiFormHelperText-root": { color: "red" },
          }}
        >
          <Accordion
            expanded={expanded === "deviceDetails"}
            onChange={handleAccordionChange("deviceDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h6">
                {t("manageDevices.devices.detail.device")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem
                  sx={{
                    "& .MuiInputBase-root": { pr: "24px !important" },
                  }}
                >
                  <ListItemText
                    primary={t("manageDevices.devices.updateDevice.location")}
                  />

                  <FormControl
                    required
                    variant="standard"
                    sx={{
                      width: "100%",
                      color: "text.main",
                      flexGrow: 1,
                    }}
                  >
                    <Autocomplete
                      id="form-input-devicelocation"
                      options={locationChoices ? locationChoices : []}
                      value={deviceLocation ? deviceLocation : ""}
                      isOptionEqualToValue={(option, value) => {
                        return value && value.id
                          ? value.id === option.id
                          : true;
                      }}
                      onChange={(event, value) => {
                        if (value) setDeviceLocation(value);
                        else {
                          setDeviceLocation(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" />
                      )}
                      getOptionLabel={(option) => `${option.name}`}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          sx={{ display: "flex", px: 4, py: 1 }}
                          key={option.id}
                        >
                          <Typography sx={{ flex: 1 }}>
                            {option.name}
                          </Typography>
                          <Typography fontSize={12}>
                            {option.parent_name
                              ? `(${option.parent_name})`
                              : null}
                          </Typography>
                        </Box>
                      )}
                      groupBy={(option) => option.type_name}
                    />
                    <FormHelperText>
                      {deviceLocationError ? deviceLocationError : null}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem
                  sx={{
                    "& .MuiInputBase-root": { pr: "24px !important" },
                  }}
                >
                  <ListItemText
                    primary={t("manageDevices.devices.updateDevice.deviceType")}
                  />
                  <FormControl
                    required
                    variant="standard"
                    sx={{
                      width: "100%",
                      color: "text.main",
                      flexGrow: 1,
                    }}
                  >
                    <Autocomplete
                      required
                      id="form-input-devicetype"
                      value={deviceType ? deviceType : ""}
                      isOptionEqualToValue={(option, value) => {
                        return value && value.id
                          ? value.id === option.id
                          : true;
                      }}
                      options={deviceTypeChoices ? deviceTypeChoices : []}
                      onChange={(event, value) => {
                        if (value) setDeviceType(value);
                        else {
                          setDeviceType(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" />
                      )}
                      getOptionLabel={(option) => `${option.name}`}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          sx={{ display: "flex", px: 4, py: 1 }}
                          key={option.id}
                        >
                          <Typography>{option.name}</Typography>
                        </Box>
                      )}
                      groupBy={(option) => option.brand}
                    />
                    <FormHelperText>
                      {deviceTypeError ? deviceTypeError : null}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageDevices.devices.updateDevice.identifier")}
                  />

                  <FormControl
                    error={deviceIdentifierError ? true : false}
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <TextField
                      required
                      value={deviceIdentifier ? deviceIdentifier : ""}
                      error={deviceIdentifierError ? true : false}
                      id="form-input-identifier"
                      variant="standard"
                      onChange={(event) =>
                        setDeviceIdentifier(event.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Edit sx={{ width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {deviceIdentifierError ? deviceIdentifierError : null}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageDevices.devices.updateDevice.isActive")}
                  />

                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <Select
                      required
                      value={deviceActive ? deviceActive : false}
                      onChange={(event) => {
                        setDeviceActive(event.target.value);
                      }}
                    >
                      <MenuItem
                        key={1}
                        value={true}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <CheckCircleOutlineOutlined color="primary" />
                      </MenuItem>
                      <MenuItem
                        key={0}
                        value={false}
                        sx={{ justifyContent: "flex-end" }}
                      >
                        <Cancel color="error" />
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {deviceActiveError ? deviceActiveError : false}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={t("manageDevices.devices.updateDevice.notes")}
                  />
                  <FormControl
                    error={deviceNotesError ? true : false}
                    variant="standard"
                    sx={{
                      mx: 1,
                      width: "100%",
                      color: "text.main",
                    }}
                  >
                    <TextField
                      multiline
                      value={deviceNotes ? deviceNotes : ""}
                      maxRows={5}
                      error={deviceNotesError ? true : false}
                      id="form-input-identifier"
                      variant="standard"
                      onChange={(event) => setDeviceNotes(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Edit sx={{ width: 20, height: 20 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText>
                      {deviceNotesError ? deviceNotesError : null}
                    </FormHelperText>
                  </FormControl>
                </ListItem>

                {/* extra data to be added later */}
                <FormControl>
                  <FormHelperText>
                    {deviceExtraDataError ? deviceExtraDataError : null}
                  </FormHelperText>
                </FormControl>
              </List>

              {renderUpdateButton()}
            </AccordionDetails>
          </Accordion>
        </Box>

        <Button
          variation="outlined"
          color="error"
          onClick={() => setOpenDeleteDeviceModal(true)}
          sx={{ my: 1 }}
        >
          {t("manageDevices.devices.detail.deleteDevice")}
        </Button>
      </Box>
      <Button
        sx={{
          position: "absolute",
          top: "50%",
          left: openDeviceDrawer ? "-20px" : "",
          minWidth: "40px",
          width: "40px",
          height: "40px",
          zIndex: 1,
          borderRadius: "50%",
          backgroundColor: "rgb(68, 69, 70)",
          visibility: "initial",
        }}
        onClick={() => {
          openDeviceDrawer
            ? setOpenDeviceDrawer(false)
            : setOpenDeviceDrawer(true);
        }}
      >
        {openDeviceDrawer ? (
          <Tooltip title="Hide Location Detail">
            <KeyboardArrowRightIcon
              sx={{ height: "40px", width: "40px", color: "white" }}
            />
          </Tooltip>
        ) : null}
      </Button>
      {deviceDetail ? (
        <DeleteDeviceModal
          openDeleteDeviceModal={openDeleteDeviceModal}
          setOpenDeleteDeviceModal={setOpenDeleteDeviceModal}
          deviceDetail={deviceDetail}
          handleDeleteDevice={handleDeleteDevice}
        />
      ) : null}
    </Drawer>
  );
  return selectedDevice ? renderDrawer() : null;
}
