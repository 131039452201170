class AuthService {

    constructor(apiService, setAuthenticated, setLoading) {

        this.user = { 
            user_id: -1, 
            email: 'user@xsalert.nl', 
            name: 'test user', 
            first_name: 'test', 
            last_name: 'user', 
            photo: null ,
            organizations: [],
        };
        this.isChecking = false;
        this.authenticated = false;
        this.apiService = apiService;
        this.setAuthenticated = setAuthenticated;
        this.setLoading = setLoading;
    }

    getUserEmail() {
        return this.user.email;
    }

    getUserName() {
        return this.user.first_name + ' ' + this.user.last_name;
    }

    getProfileIcon() {
        return this.user.photo;
    }

    async login(email, password) {
        const response = await this.apiService.getCookie(email, password);
        if (response.success) {
            if (response.data) {
                this.user = { 
                    user_id: response.data['id'],
                    email: response.data['email'], 
                    name: response.data['full_name'], 
                    first_name: response.data['first_name'],
                    last_name: response.data['last_name'],
                    photo: response.data['photo_url'],
                    notifications: response.data['notifications'],
                }
            }
            this.authenticated = true;
            this.setAuthenticated(true);
            
            return ({ success: true });
        }
        /* If the API call failed we try to extract the error message */
        try {
            const api_error = await response.data.json();
            return ({ success: false, error: api_error.errors.__all__ });
        } catch {
            return ({ success: false, error: "Unknown error" });
        }
    }

    async logout() {
        const response = await this.apiService.logOut();
        if (response.success) {
            localStorage.removeItem('org_id');
            this.authenticated = false;
            this.isChecking = false;
            this.setAuthenticated(false);
            window.location.reload();
        }
    }

    async isAuthenticated() {
        /* Checks if the user is still authenticated */
        if (!this.isChecking && !this.authenticated){
            this.isChecking = true;
            this.setLoading(true);;
            const response = await this.apiService.isLoggedIn();
            if (response.success) {
                if (response.data) {
                    this.user = { 
                        user_id: response.data['id'],
                        email: response.data['email'], 
                        name: response.data['full_name'], 
                        first_name: response.data['first_name'],
                        last_name: response.data['last_name'],
                        photo: response.data['photo_url'],
                        notifications: response.data['notifications'],
                    }
                    
                    this.authenticated = true;
                    this.setAuthenticated(true);
                    this.isChecking = false;
                    this.setLoading(false);
                    return ({ success: true });
                }
            }
            this.isChecking = false;
            this.setLoading(false);
            return { success: false };
        }
    }

}
export default AuthService;