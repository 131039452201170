import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ServiceContext } from "../../context/ServiceContext";
import ManageLocationsDataGrid from "../../components/data_grids/ManageLocationsDataGrid";
import ManageLocationTypesDataGrid from "../../components/data_grids/ManageLocationTypesDataGrid";
import { useTranslation } from "react-i18next";
import { Button, Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ManageLocationDrawer from "../../components/drawers/ManageLocationDrawer";
import ManageLocationsCreateNewModal from "../../components/popups/ManageLocationsCreateNew";

import ManageLocationTypeDrawer from "../../components/drawers/ManageLocationTypeDrawer";
import CreateNewLocationTypeModal from "../../components/popups/CreateNewLocationType";

const ManageLocationsScreen = () => {
  const context = useContext(ServiceContext);
  const location = useLocation();
  const { t } = useTranslation();
  let [view, setView] = useState("locations");
  let [fetchedLocations, setFetchedLocations] = useState(false);
  let [locations, setLocations] = useState(false);
  let [selectedLocation, setSelectedLocation] = useState(false);
  let [selectedLocationType, setSelectedLocationType] = useState(false);
  let [openLocationDrawer, setOpenLocationDrawer] = useState(false);
  let [openLocationTypeDrawer, setOpenLocationTypeDrawer] = useState(false);
  let [openPopupNewLocation, setOpenPopupNewLocation] = useState(false);
  let [openPopupNewLocationType, setOpenPopupNewLocationType] = useState(false);

  // alert handling
  let [openSnackbar, setOpenSnackbar] = useState(false);
  let [alertMessage, setAlertMessage] = useState("");
  let [alertSeverity, setAlertSeverity] = useState("success");

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
    setAlertMessage("");
  };

  const handleNewAlert = (message, severity) => {
    setOpenSnackbar(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  // for forms
  let [fetchedLocationTypes, SetFetchedLocationTypes] = useState(false);
  let [locationTypes, setLocationTypes] = useState([]);

  useEffect(() => {
    // fetch/reload locations if no locations
    if ((!locations || locations.length === 0) && !fetchedLocations) {
      context.locationService.retrieveLocations().then((locations) => {
        setLocations(locations);
        setFetchedLocations(true);
      });
    }
  }, [locations, fetchedLocations, context.locationService]);

  useEffect(() => {
    // fetch/reload location types
    if (
      (!locationTypes || locationTypes.length === 0) &&
      !fetchedLocationTypes
    ) {
      context.locationService.retrieveLocationTypes().then((response) => {
        setLocationTypes(response);
        SetFetchedLocationTypes(true);
      });
    }
  }, [locationTypes, fetchedLocationTypes, context.locationService]);

  useEffect(() => {
    // Check if a "selectedLocation" was provided in the useLocation state (this is a clickthrough from another screen)
    if (
      locations &&
      locations.length &&
      location.state &&
      location.state.selectedLocation
    ) {
      let found = locations.filter((el) => {
        return el.id === location.state.selectedLocation.id;
      });
      if (found.length) {
        setSelectedLocation(found[0]);
        setOpenLocationDrawer(true);
      }
    }
  }, [locations, location]);

  const renderLocationsView = () => {
    return (
      <ManageLocationsDataGrid
        locations={locations}
        setSelectedLocation={setSelectedLocation}
        setOpenLocationDrawer={setOpenLocationDrawer}
      />
    );
  };

  const renderLocationTypesView = () => {
    return (
      <ManageLocationTypesDataGrid
        locationTypes={locationTypes}
        setSelectedLocationType={setSelectedLocationType}
        setOpenLocationTypeDrawer={setOpenLocationTypeDrawer}
      />
    );
  };

  return (
    <Box className="container">
      <Box
        className="page-inner"
        sx={{
          height: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width="calc(100% - 64px)">
          <Box
            m={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mx: 0,
            }}
          >
            <Button
              className="choice"
              sx={{
                color: view === "locations" ? "primary" : "white",
              }}
              onClick={() => setView("locations")}
            >
              {t("manageLocations.page.Locations")}
            </Button>
            <div className="choice">|</div>
            <Button
              className="choice"
              sx={{
                color: view === "locationTypes" ? "primary" : "white",
              }}
              onClick={() => setView("locationTypes")}
            >
              {t("manageLocations.page.Location Groups")}
            </Button>
            {view === "locations" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 1,
                  ml: "auto",
                }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenPopupNewLocation(true)}
                >
                  {t("manageLocations.page.Add New Location")}
                </Button>
              </Box>
            ) : null}

            {view === "locationTypes" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 1,
                  ml: "auto",
                }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenPopupNewLocationType(true)}
                >
                  {t("manageLocations.page.addLocationType")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>

        {view === "locations"
          ? renderLocationsView()
          : renderLocationTypesView()}
      </Box>
      {view === "locations" ? (
        <>
          <ManageLocationDrawer
            openDrawer={openLocationDrawer}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            setOpenLocationDrawer={setOpenLocationDrawer}
            handleNewAlert={handleNewAlert}
            locationTypes={locationTypes}
            setLocations={setLocations}
            locations={locations}
          />
          <ManageLocationsCreateNewModal
            openPopupNewLocation={openPopupNewLocation}
            setOpenPopupNewLocation={setOpenPopupNewLocation}
            handleNewAlert={handleNewAlert}
            locations={locations}
            setLocations={setLocations}
            locationTypes={locationTypes}
            setSelectedLocation={setSelectedLocation}
            setOpenLocationDrawer={setOpenLocationDrawer}
          />
        </>
      ) : null}

      {view === "locationTypes" ? (
        <>
          <ManageLocationTypeDrawer
            openLocationTypeDrawer={openLocationTypeDrawer}
            selectedLocationType={selectedLocationType}
            setSelectedLocationType={setSelectedLocationType}
            setOpenLocationTypeDrawer={setOpenLocationTypeDrawer}
            handleNewAlert={handleNewAlert}
            locationTypes={locationTypes}
            setLocationTypes={setLocationTypes}
            setSelectedLocation={setSelectedLocation}
            setOpenLocationDrawer={setOpenLocationDrawer}
            setView={setView}
          />
          <CreateNewLocationTypeModal
            openPopupNewLocationType={openPopupNewLocationType}
            setOpenPopupNewLocationType={setOpenPopupNewLocationType}
            handleNewAlert={handleNewAlert}
            locationTypes={locationTypes}
            setLocationTypes={setLocationTypes}
            setSelectedLocationType={setSelectedLocationType}
            setOpenLocationTypeDrawer={setOpenLocationTypeDrawer}
          />
        </>
      ) : null}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => handleCloseAlert()}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => handleCloseAlert()}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ManageLocationsScreen;
