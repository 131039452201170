class LocationService {

    constructor(apiService, organizationService) {
        this.apiService = apiService;
        this.organizationService = organizationService;
        this.location = [];
        this.locations = [];
    }

    async retrieveLocations() {
        if (this.apiService) {
            const response = await this.apiService.retrieveOrganizationLocations();
            if (response.success) {
                this.locations = response.data.results;
                return this.locations;
            }
        }
        return this.locations;
    }

    async retrieveLocation(id) {
        if (this.apiService) {
            const response = await this.apiService.retrieveLocation(id);
            if (response.success) {
                return response.data;
            }
        }
        return null;
    }

    async retrieveTopLevelLocations() {
        if (this.apiService) {
            // adding top_level = true to fetch only top level locations
            const response = await this.apiService.retrieveOrganizationLocations(true);
            if (response.success) {
                this.locations = response.data.results;
                return this.locations;
            }
        }
    }

    async retrieveLocationTypes() {
        // fetch all location types for
        if (this.apiService) {
            const response = await this.apiService.retrieveOrganizationLocationTypes();
            if (response.success) {
                return response.data.results;
            }
        }
    }

    async createLocation(data) {
        if (this.apiService) {
            const response = await this.apiService.createLocation(data);
            if (response.success) {
                return response.data;
            } else {
                throw await response.data.json()
            };
        }
    }

    async createLocationType(data) {
        if (this.apiService) {
            const response = await this.apiService.createLocationType(data);
            if (response.success) {
                return response.data;
            } else {
                throw await response.data.json()
            };
        }
    }

    async updateLocation(id, data) {
        if (this.apiService) {
            const response = await this.apiService.updateLocation(id, data);
            if (response.success) {
                return response.data
            } else {
                throw await response.data.json();
            };
        }
    }

    async updateLocationType(id, data) {
        if (this.apiService) {
            const response = await this.apiService.updateLocationType(id, data);
            if (response.success) {
                return response.data
            } else {
                throw await response.data.json();
            };
        }
    }

    async deleteLocation(id) {
        if (this.apiService) {
            const response = await this.apiService.deleteLocation(id);
            if (response.success){
                return response
            } else {
                throw await response;
            }
        }
    }

    async deleteLocationType(id) {
        if (this.apiService) {
            const response = await this.apiService.deleteLocationType(id);
            if (response.success){
                return response
            } else {
                throw await response;
            }
        }
    }

}
export default LocationService;