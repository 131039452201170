import { readCookie } from "../util/util";

/*
    The API Service is responsible for handing all interactions
    with the XSMSG API on the endpoint given by env_api_url.
*/

export default class ApiService {
  constructor() {
    /* Use staging API if env var does not exist */
    this.api_url = "https://test-api.xsalert.nl/";
    const env_api_url = process.env.REACT_APP_API_URL;
    /* If it does exist, use the URL specified in the env var instead */
    if (env_api_url) this.api_url = env_api_url;
    this.organization_id = -1;
    this.csrf_token = this.getCSRFToken();
    this.debug = true;
    console.log("Created APIService.");

    this.setOrganizationId = this.setOrganizationId.bind(this);
  }

  setOrganizationId(id) {
    this.organization_id = id;
  }

  async get(endpoint, paginated) {
    /* 
      Performs a GET request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    const full_url = paginated ? endpoint : this.api_url + endpoint;
    if (this.debug) console.log("HTTP GET to : " + full_url);
    try {
      let response = await fetch(full_url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        try {
          response = await response.json();
        } catch (error) {
          // if no response.body
          return { success: true, reason: response.status, data: null };
        }
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: null };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error ? error : null, data: null };
    }
  }

  async delete(endpoint) {
    /* 
    Performs a DELETE request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log("HTTP DELETE to : " + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": this.csrf_token,
          },
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: "DELETE",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        });
      }
      if (response.status === 200 || response.status === 204) {
        return { success: true, reason: response.status, data: "" };
      } else {
        return { success: false, reason: response.status, data: null };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async post(endpoint, body) {
    /* 
      Performs a POST request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log("HTTP POST to : " + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": this.csrf_token,
          },
          body: body,
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: body,
        });
      }
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: response };
      }
    } catch (error) {
      // console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async postFormData(endpoint, body) {
    /* 
      Performs a POST request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log("HTTP POST to : " + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: "POST",
          credentials: "include",
          headers: { "X-CSRFToken": this.csrf_token },
          body: body,
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: "POST",
          credentials: "include",
          body: body,
        });
      }
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: response };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async patch(endpoint, body) {
    /* 
      Performs a PATCH request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log("HTTP PATCH to : " + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: "PATCH",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": this.csrf_token,
          },
          body: body,
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: "PATCH",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: body,
        });
      }
      if (response.status === 200) {
        response = await response.json();
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: response };
      }
    } catch (error) {
      return { success: false, reason: error, data: response };
    }
  }

  async paginated_get(endpoint, limit) {
    /* Does a GET request and handles pagination */
    var response_list = [];
    const response = await this.get(`${endpoint}?limit=${limit}`);
    /* If the first request fails just return the failed HTTP req */
    if (!response.success) return response;
    /* If we got the entire list, just return the list */
    if (!response.data.next)
      return { success: true, data: response.data.results };
    /* If we reach this point we need to get more data */
    var next_url = response.data.next;
    response_list = response.data.results;
    while (next_url) {
      const additional_response = await this.get(next_url, true);
      if (additional_response.data.results)
        response_list = response_list.concat(additional_response.data.results);
      additional_response.data.next
        ? (next_url = additional_response.data.next)
        : (next_url = null);
    }
    return { success: true, data: response_list };
  }

  async isLoggedIn() {
    /* Check if the user is still logged in (allowed to retrieve its profile) 
       Returns: {success: true/false, reason: null/string, data: null/data}
    */
    if (this.debug) console.log("Checking if we are still authenticated");
    let response = await this.get(`auth/me/`);
    if (this.debug)
      response.success
        ? console.log("still authenticated!")
        : console.log("not authenticated");
    return response;
  }

  async getCookie(username, password) {
    /*
      Tries to post a username and password to the API to retrieve
      a session cookie. Returns a promise that resolves to the object
      created by the post() function. 
    */
    console.log("Trying to login.");
    var response = await this.post(
      "auth/cookie/",
      JSON.stringify({ username, password })
    );
    return response;
  }

  getCSRFToken() {
    /* Gets the current CSRF token or an empty string if not set */
    return readCookie("csrftoken");
  }

  async logOut() {
    /* Tell the API to remove the session cookie.
       Returns {success: true/false, reason: null/string, data: null/data}
    */
    if (this.debug) console.log("Telling API to remove session cookie.");
    var response = await this.get(`auth/logout/`);
    if (this.debug)
      response.success
        ? console.log("Logged out!")
        : console.log("Couldn't log out!");
    return response;
  }

  async retrieveOrganization() {
    // retrieve single organization object
    if (this.debug)
      console.log("Retrieving organization with id " + this.organization_id);
    return await this.get(`organizations/${this.organization_id}/`);
  }

  async retrieveOrganizations() {
    // Retrieve all organizations where user is member or admin
    if (this.debug)
      console.log("Retrieving all Organizations where user is member");
    return await this.get("organizations/");
  }

  // probably unused
  async retrieveLocations() {
    /* Retrieve as many locations as the API can send in one GET. */
    if (this.debug) console.log("Retrieving list of locations");
    return await this.get("locations/");
  }

  async retrieveOrganizationLocations(top_level) {
    // Retrieve all locations of a given organization
    if (top_level) {
      // only fetch the top level locations
      if (this.debug)
        console.log(
          `Retrieving all top level locations of organization ${this.organization_id}`
        );
      return await this.get(
        `organizations/${this.organization_id}/locations/?top_level=True`
      );
    } else {
      if (this.debug)
        console.log(
          `Retrieving all locations of organization ${this.organization_id}`
        );
      return await this.get(`organizations/${this.organization_id}/locations/`);
    }
  }

  async retrieveLocation(id) {
    /* Retrieve a single location */
    if (this.debug) console.log(`Retrieving location ${id} details`);
    return await this.get(`locations/${id}/`);
  }

  async retrieveOrganizationLocationTypes() {
    // Retrieve all Location Types of a given Organization
    if (this.debug)
      console.log(
        `Retrieving all location types of organization ${this.organization_id}`
      );
    return await this.get(
      `organizations/${this.organization_id}/locationtypes/`
    );
  }

  async retrieveOrganizationDevices() {
    // Retrieve all devices linked to the organization
    if (this.debug)
      console.log(
        `Retrieving all devices of organization ${this.organization_id}`
      );
    return await this.get(`organizations/${this.organization_id}/devices/`);
  }

  async retrieveLocationDevices(id, limit, offset) {
    // Retrieve all devices linked to a location
    if (this.debug)
      console.log(
        `Retrieving all devices of location id${id}, offset=${offset} limit=${limit}`
      );
    return await this.get(`organizations/${this.organization_id}/devices/`);
  }

  async retrieveDeviceTypes() {
    if (this.debug) {
      console.log(`Retrieving list of all XS Alert device Types`);
    }
    return await this.get("devicetypes/?active=True");
  }

  async retrieveDevice(id) {
    if (this.debug) {
      console.log(`retrieving device with id ${id}`);
    }
    return await this.get(
      `organizations/${this.organization_id}/devices/${id}/`
    );
  }

  async createDevice(data) {
    if (this.debug) {
      console.log(`Creating device for organization ${this.organization_id}`);
    }
    return await this.post(
      `organizations/${this.organization_id}/devices/`,
      JSON.stringify(data)
    );
  }

  async updateDevice(id, data) {
    if (this.debug) {
      console.log(`Updating device for organization ${this.organization_id}`);
    }
    return await this.patch(
      `organizations/${this.organization_id}/devices/${id}/`,
      JSON.stringify(data)
    );
  }

  async deleteDevice(id) {
    if (this.debug) {
      console.log(`Deleting device with id ${id}`);
    }
    return await this.delete(
      `organizations/${this.organization_id}/devices/${id}/`
    );
  }

  async retrieveAlerts() {
    /* Retrieve as many logs as the API can send in one GET. */
    if (this.debug) console.log("Retrieving list of alerts");
    return await this.get("alerts/");
  }

  async retrieveOrganizationAlerts() {
    /* Retrieve as many logs as the API can send in one GET. */
    if (this.debug) console.log("Retrieving list of alerts");
    return await this.get(`organizations/${this.organization_id}/alerts/`);
  }

  async retrieveOrganizationAlertLogs() {
    if (this.debug) console.log("Retrieving alert logs");
    return await this.get(`organizations/${this.organization_id}/alertlog/`);
  }

  async retrieveLocationAlerts(location_id, limit, offset) {
    /* Retrieve (paginated) alerts for a specific location */
    if (this.debug)
      console.log(
        `Retrieving alerts for location ${location_id}, offset=${offset} limit=${limit}`
      );
    return await this.get(
      `locations/${location_id}/alerts/?limit=${limit}&offset=${offset}`
    );
  }

  async retrieveOrganizationAlertRules() {
    if (this.debug) {
      console.log(
        `Retrieving all alert rules (EventAlert) for org ${this.organization_id}`
      );
      return await this.get(
        `organizations/${this.organization_id}/eventrules/`
      );
    }
  }

  async createAlertRule(data) {
    if (this.debug)
      console.log(
        `creating alert Rule for organization id ${this.organization_id}`
      );
    return await this.post(
      `organizations/${this.organization_id}/eventrules/`,
      JSON.stringify(data)
    );
  }

  async updateAlertRule(id, data) {
    if (this.debug)
      console.log(
        `updating alertRule id ${id} for organization id ${this.organization_id}`
      );
    return await this.patch(
      `organizations/${this.organization_id}/eventrules/${id}/`,
      JSON.stringify(data)
    );
  }

  async deleteAlertRule(id) {
    if (this.debug) console.log(`Deleting alert Rule with id ${id}`);
    return await this.delete(
      `organizations/${this.organization_id}/eventrules/${id}/`
    );
  }

  async retrieveOrganizationStreams() {
    if (this.debug)
      console.log(
        `Retrieving list of streams of organization ${this.organization_id}`
      );
    return await this.get(`organizations/${this.organization_id}/streams/`);
  }

  async retrieveStream(streamName) {
    if (this.debug) console.log(`Retrieving stream ${streamName}`);
    return await this.get(
      `organizations/${this.organization_id}/streams/?stream=${streamName}`
    );
  }

  async retrieveAlertPriorityChoices() {
    if (this.debug) console.log(`Retrieving choices for Alert Priorities`);
    return await this.get(`choices/alert-priority/`);
  }

  async retrieveEventTypeChoices() {
    if (this.debug) console.log(`Retrieving choices for Event Types`);
    return await this.get(`choices/event-types/`);
  }

  // probably unused - throw away?
  async retrieveNurses() {
    /* Retrieve as many nurses as the API can send in one GET. */
    if (this.debug) console.log("Retrieving list of nurses");
    return await this.get("users/");
  }

  async retrieveOrganizationUsersAll() {
    /* Retrieve all user-types (f.e. Caretakers & admins) that are part of an organization. */
    if (this.debug)
      console.log(
        `Retrieving list of all users of organization ${this.organization_id}`
      );
    return await this.get(`organizations/${this.organization_id}/users/`);
  }
  async retrieveOrganizationUserGroups() {
    /* Retrieve all user groups that are part of an organization. */
    if (this.debug)
      console.log(
        `Retrieving list of all user groups of organization ${this.organization_id}`
      );
    return await this.get(`organizations/${this.organization_id}/usergroups/`);
  }
  async retrieveOrganizationUserInvites() {
    // Retrieve all users which have a pending Invitation
    if (this.debug)
      console.log(
        `Retrieving list of all pending user invitations of organization ${this.organization_id}`
      );
    return await this.get(
      `organizations/${this.organization_id}/userinvitations/?pending=True`
    );
  }

  async checkUserInOrganization(email) {
    // Check if an email address can be found associated with an organization.
    if (this.debug)
      console.log(
        `Checking if ${email} is a member or admin of org ${this.organization_id}`
      );
    return await this.get(
      `organizations/${this.organization_id}/users?email=${email}`
    );
  }

  async inviteUserToOrganization(data) {
    // sends a post request to the server. possible user creation is done server-side
    if (this.debug)
      console.log(`Attempting User Invitation to org ${this.organization_id}`);
    return await this.post(
      `organizations/${this.organization_id}/userinvitations/`,
      JSON.stringify(data)
    );
  }

  async retrieveUser(user_id) {
    if (this.debug) console.log(`fetching user with id ${user_id}`);
    return await this.get(
      `organizations/${this.organization_id}/users/${user_id}/`
    );
  }

  async removeUserFromOrganization(user_id) {
    if (this.debug)
      console.log(
        `removing user ${user_id} from organization ${this.organization_id}`
      );
    return await this.delete(
      `organizations/${this.organization_id}/users/${user_id}/`
    );
  }

  async retrieveUserGroup(id) {
    if (this.debug) console.log(`fetching user group with id ${id}`);
    return await this.get(
      `organizations/${this.organization_id}/usergroups/${id}/`
    );
  }

  async createUserGroup(data) {
    if (this.debug) console.log(`Creating user group "${data.name}`);
    return await this.post(
      `organizations/${this.organization_id}/usergroups/`,
      JSON.stringify(data)
    );
  }

  async updateUserGroup(id, data) {
    console.log(data);
    if (this.debug) console.log(`updating user group with id ${id}`);
    return await this.patch(
      `organizations/${this.organization_id}/usergroups/${id}/`,
      JSON.stringify(data)
    );
  }

  async removeUserGroup(id) {
    if (this.debug) console.log(`removing user group with id ${id}`);
    return await this.delete(
      `organizations/${this.organization_id}/usergroups/${id}/`
    );
  }

  async addUserToUserGroup(user_id, userGroup_id) {
    let data = {
      pk: userGroup_id,
      user: user_id,
    };
    if (this.debug)
      console.log(
        `adding user with id ${user_id} to userGroup with it ${userGroup_id}`
      );
    return await this.post(
      `organizations/${this.organization_id}/usergroups/${userGroup_id}/new_user/`,
      JSON.stringify(data)
    );
  }

  async removeUserFromUserGroup(user_id, userGroup_id) {
    let data = {
      pk: userGroup_id,
      user: user_id,
    };
    if (this.debug)
      console.log(
        `removing user with id ${user_id} from userGroup with id ${userGroup_id}`
      );
    return await this.post(
      `organizations/${this.organization_id}/usergroups/${userGroup_id}/remove_user/`,
      JSON.stringify(data)
    );
  }

  async createLocation(data) {
    if (this.debug)
      console.log(
        `creating location for organization id ${this.organization_id}`
      );
    return await this.post(
      `organizations/${this.organization_id}/locations/`,
      JSON.stringify(data)
    );
  }

  async createLocationType(data) {
    if (this.debug)
      console.log(
        `creating location type for organization id ${this.organization_id}`
      );
    return await this.post(
      `organizations/${this.organization_id}/locationtypes/`,
      JSON.stringify(data)
    );
  }

  async updateLocation(id, data) {
    console.log(data);
    if (this.debug)
      console.log(
        `updating location id ${id} for organization id ${this.organization_id}`
      );
    return await this.patch(
      `organizations/${this.organization_id}/locations/${id}/`,
      JSON.stringify(data)
    );
  }

  async updateLocationType(id, data) {
    if (this.debug)
      console.log(
        `updating location type id ${id} for organization id ${this.organization_id}`
      );
    return await this.patch(
      `organizations/${this.organization_id}/locationtypes/${id}/`,
      JSON.stringify(data)
    );
  }

  async deleteLocation(id) {
    if (this.debug) console.log(`Deleting location with id ${id}`);
    return await this.delete(
      `organizations/${this.organization_id}/locations/${id}/`
    );
  }

  async deleteLocationType(id) {
    if (this.debug) console.log(`Deleting location type with id ${id}`);
    return await this.delete(
      `organizations/${this.organization_id}/locationtypes/${id}/`
    );
  }
}
