import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard.js';
import ManageDevicesScreen from '../pages/manage/ManageDevicesScreen.js';
import ManageLocationsScreen from '../pages/manage/ManageLocationsScreen.js';
import ManageAlertsScreen from '../pages/manage/ManageAlertsScreen.js';
import ManagementScreen from '../pages/manage/ManagementScreen.js';
import ManageUsersScreen from '../pages/manage/ManageUsersScreen.js';
import Refresh from '../pages/refresh/Refresh.js';

export default function Routes(props) {
    return (
        <Switch>
            <Route exact path="/" component={() => <Dashboard view={props.view}/>} >
                <Redirect to="/dashboard"/>
            </Route>
            <Route path="/dashboard" component={() => <Dashboard view={props.view}/>} />
            <Route exact path="/manage" component={() => <ManagementScreen/>} />
            <Switch>
                <Route path="/manage/users" component={() => <ManageUsersScreen/>} />
                <Route path="/manage/devices" component={() => <ManageDevicesScreen/>} />
                <Route path="/manage/alerts" component={() => <ManageAlertsScreen/>} />
                <Route path="/manage/locations" component={() => <ManageLocationsScreen/>} />
            </Switch>
            <Route path="/refresh" component={Refresh} />
        </Switch>
    )
}
