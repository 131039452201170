import React, { useContext, useState } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  color: "text.main",
  boxShadow: 24,
  p: 4,
};

export default function CreateUserGroupModal({
  handleNewAlert,
  openModalNewUserGroup,
  setOpenModalNewUserGroup,
  refreshUserGroupsList,
}) {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // form states
  const [userGroupName, setUserGroupName] = useState(false);

  const resetFormValues = () => {
    // setUserEmail("");
  };

  // form errors
  const [userGroupNameError, setUserGroupNameError] = useState(false);

  const resetErrors = () => {
    setUserGroupNameError(false);
  };

  // handlers
  const handleCloseModal = () => {
    setOpenModalNewUserGroup(false);
    resetErrors();
    resetFormValues();
  };

  const createUserGroup = () => {
    let data = {
      organization: context.organizationService.selectedOrg,
      name: userGroupName,
      permissions: [],
    };

    setLoading(true);
    context.userService
      .createUserGroup(data)
      .then((response) => {
        handleNewAlert(
          `Successfully created user group "${response.name}"`,
          "success"
        );
        refreshUserGroupsList();
        setOpenModalNewUserGroup(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        handleNewAlert(`Error: ${err}`, "error");
      });
    setLoading(false);
  };

  return (
    <div>
      <Modal open={openModalNewUserGroup} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              width: "500px",
              my: 2,
              maxWidth: "100%",
              "& .MuiInputLabel-formControl": { color: "white" },
              "& .MuiInput-input": {
                backgroundColor: "transparent",
                color: "white",
              },
              "& .MuiFormHelperText-root": { color: "red" },
            }}
          >
            <FormControl
              required
              error={userGroupNameError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                required
                error={userGroupNameError ? true : false}
                id="form-input-email"
                label={t("manageUsers.userGroups.create.name")}
                variant="standard"
                onChange={(event) => setUserGroupName(event.target.value)}
              />
              <FormHelperText>
                {userGroupNameError ? userGroupNameError : null}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={() => createUserGroup()}>
                {t("manageUsers.userGroups.create.create")}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
