import React, { useState } from 'react';
import AlertService from '../services/AlertService';
import NurseService from '../services/NurseService';
import LocationService from '../services/LocationService';
import DeviceService from '../services/DeviceService';
import { withTranslation } from 'react-i18next';
import UserService from '../services/UserService';

const ServiceContext = React.createContext({});

const ServiceContextProvider = (props) => {

  const apiService = props.apiService;
  const authService = props.authService;
  const [organizationService] = useState(props.organizationService);
  const [locationService] = useState(new LocationService(apiService, organizationService));
  const [deviceService] = useState(new DeviceService(apiService, organizationService));
  const [alertService] = useState(new AlertService(apiService, organizationService));
  const [nurseService] = useState(new NurseService(apiService, organizationService));
  const [userService] = useState(new UserService(apiService, organizationService))
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedLock, setSelectedLock] = useState(null);
  const view = props.view;
  const setView = props.setView;

  const context = {
    apiService,
    authService,
    organizationService,
    locationService,
    deviceService,
    alertService,
    nurseService,
    userService,
    selectedLanguage,
    setSelectedLanguage,
    selectedGroup,
    setSelectedGroup,
    selectedUser,
    setSelectedUser,
    selectedLock,
    setSelectedLock,
    view,
    setView,
  };

  let preferredLanguage = localStorage.getItem("language");
  if (!selectedLanguage && !preferredLanguage) {
    setSelectedLanguage("GB");
  } else if (!selectedLanguage && preferredLanguage) {
    props.i18n.changeLanguage(preferredLanguage, (err, t) => {
      console.log(`Changing Language to ${preferredLanguage} from localStorage`);
      setSelectedLanguage(preferredLanguage);
      if (err) return console.log('something went wrong loading language settings', err);
    });
  }

  return (
    <ServiceContext.Provider value={context}>
      {props.children}
    </ServiceContext.Provider>
  );
}

export default withTranslation() (ServiceContextProvider);
export { ServiceContext };